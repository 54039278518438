/* eslint-disable */
import { useMutation } from 'react-query'
import { BASE_API_URL } from './constants'

const removeService = async (serviceId: string | number) => {
  const res = await fetch(
    `${BASE_API_URL}/v1/services/deleteService/${serviceId}`,
    {
      method: 'POST',
    },
  )
  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return res.json()
}

export const useRemoveService = (config = {}) => {
  return useMutation(removeService, { ...config })
}

/* eslint-disable  */

import React from 'react'
import { IonButton, IonSpinner } from '@ionic/react'
import classnames from 'classnames'

import Table from '../../../table/Table'
import { PatientDocumentsProps } from './interfaces'

import styles from './styles.module.scss'

const fileTableColumns = ({
  // onFileDownload,
}: {
  // onFileDownload: (fileId: number) => File 
}) => [
  {
    Header: 'File Name',
    Cell: ({
      row: {
        // @ts-ignore
        original: { fileName },
      },
    }) => (
      <span
        className={styles.fileTableView}
        
      >
        {fileName}
      </span>
    )
  }
]

export function PatientFiles({
  files,
  onUploadFileClick,
  // onFileDownload
}: PatientDocumentsProps) {
  if (!files) {
    return (
      <div className={styles.section}>
        <h5
          className={classnames(
            styles.sectionTitle,
            styles.fileTableTitle,
          )}
        >
          Files
          <IonSpinner />
        </h5>
      </div>
    )
  }

  return (
    <div className={styles.section}>
      <h5
        className={classnames(styles.sectionTitle, styles.fileTableTitle)}
      >
        Files
        <IonButton
          className={styles.uploadFileButton}
          onClick={onUploadFileClick}
        >
          Upload File
        </IonButton>
      </h5>
      <div>
        <Table
          data={files}
          columns={fileTableColumns({  })}
        />
      </div>
    </div>
  )
}

/* eslint-disable  */
const usFormatDateShort = Intl.DateTimeFormat('en-US')
const usFormatDateFull = Intl.DateTimeFormat('en-US', {
  month: 'long',
  year: 'numeric',
  day: 'numeric',
})
const usFormatDateTimeFull = Intl.DateTimeFormat('en-US', {
  month: 'long',
  year: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
})
const canadianDateShort = Intl.DateTimeFormat('en-CA')

// "2021-05-28..." -> "5/25/2021"
export function formatDisplayDate(date: string) {
  if (date == null) {
    return null
  }

  const [year, month, day] = date.split('-')
  return month + '/' + day + '/' + year
}

// "2021-05-28..." -> "2021-05-28"
export function formatSystemDate(date: string) {
  if (date == null) {
    return null
  }

  const [year, month, day] = date.split('-')
  return year + '-' + month + '-' + day
}

// "2021-05-28T01:29:40.138055Z" -> "5/25/2021"
export function formatTimestampToShortDate(timestamp: string) {
  if (timestamp == null) {
    return null
  }
  return usFormatDateShort.format(Date.parse(timestamp))
}

// "2021-05-28T01:29:40.138055Z" -> "2021-05-28"
export function formatTimestampToCanadianShortDate(timestamp: string) {
  if (timestamp == null) {
    return null
  }
  return canadianDateShort.format(Date.parse(timestamp))
}

// "2021-05-28T01:29:40.138055Z" -> "May 25, 2021"
export function formatTimestampToFullDate(timestamp: string) {
  if (timestamp == null) {
    return null
  }
  return usFormatDateFull.format(Date.parse(timestamp))
}

// "2021-05-28T01:29:40.138055Z" -> "May 25, 2021, 10:58 PM"
export function formatTimestampToFullDatetime(timestamp: string) {
  if (timestamp == null) {
    return null
  }
  return usFormatDateTimeFull.format(Date.parse(timestamp))
}

/* eslint-disable  */

import React, { useMemo, useState } from 'react'
import Modal from 'react-modal'
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonTextarea,
} from '@ionic/react'
import styles from './styles.module.scss'

import { PatientMessageServiceRequestProps } from './interfaces'
import { useForm } from 'react-hook-form'

export function PatientMessagingServiceRequest({
  msg,
  onSubmit,
  onCancel,
}: PatientMessageServiceRequestProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ msg: string }>({
    defaultValues: useMemo(
      () => ({
        msg: msg?.selectedMessage ? msg?.selectedMessage?.msg : msg?.defaultMsg,
      }),
      [],
    ),
    mode: 'onChange',
  })

  const [missingFields, setMissingFields] = useState([])

  const msgValidationHandler = (v: string) => {
    const serviceReqPatientRequiredFields = [
      '{member_name}',
      '{assignee_name}',
      '{organization_name}',
    ]
    const serviceReqEmployeeRequiredFields = [
      '{member_name}',
      '{assignee_name}',
      '{organization_name}',
      '{service_name}',
    ]
    const patientConfirmationRequiredFields = [
      '{member_name}',
      '{assignee_name}',
      '{organization_name}',
      '{service_name}',
      '{service_request_id}',
    ]

    let isTextValid = true
    const missingAttributes = []
    switch (msg.msgKey) {
      case 'service_request_patient':
        serviceReqPatientRequiredFields.map((requiredField) => {
          if (!v.includes(requiredField)) {
            isTextValid = false
            missingAttributes.push(requiredField)
          }
        })
        break
      case 'service_request_employee':
        serviceReqEmployeeRequiredFields.map((requiredField) => {
          if (!v.includes(requiredField)) {
            isTextValid = false
            missingAttributes.push(requiredField)
          }
        })
        break
      case 'service_request_confirmation_patient':
        patientConfirmationRequiredFields.map((requiredField) => {
          if (!v.includes(requiredField)) {
            isTextValid = false
            missingAttributes.push(requiredField)
          }
        })
        break
      default:
    }

    setMissingFields(missingAttributes)
    return isTextValid
  }

  const onSubmitHandler = (formData: any) => {
    if (errors.msg && errors.msg.type === 'validate') {
      return
    }

    const { selectedMessage } = msg
    if (selectedMessage) {
      selectedMessage.msg = formData.msg
      onSubmit(selectedMessage)
    } else {
      const newMessage = {
        id: null,
        key: msg.msgKey,
        msg: formData.msg,
        organizationId: null,
      }
      onSubmit(newMessage)
    }
  }

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={onCancel}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Edit Message</h2>
        <div className={styles.info}>
          Please edit the message for <strong>{msg.title}</strong> below.
        </div>
        <div className={styles.patient}>
          <form onSubmit={handleSubmit((data) => onSubmitHandler(data))}>
            <div className={styles.additionalNotesWrapper}>
              <IonItem className={styles.additionalNotes}>
                <IonLabel position='floating'>
                  Make sure to include all required variables.
                </IonLabel>
                <IonTextarea
                  rows={4}
                  placeholder='Enter custom message here...'
                  {...register('msg', {
                    required: 'This is required',
                    validate: (v: any) => msgValidationHandler(v),
                  })}
                />
                <IonIcon name='pencil' slot='end' />
              </IonItem>
            </div>
            <p>{errors.msg?.message}</p>
            {errors.msg && errors.msg.type === 'validate' && (
              <div className='error'>
                You must include the following variables: {missingFields}
              </div>
            )}
            <div className={styles.buttons}>
              <IonButton
                shape='round'
                className={styles.cancelButton}
                onClick={onCancel}
              >
                Cancel
              </IonButton>
              <IonButton
                type='submit'
                shape='round'
                className={styles.submitButton}
              >
                Submit and confirm
              </IonButton>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

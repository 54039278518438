/* eslint-disable */
import { useQuery } from 'react-query'
import { BASE_API_URL } from './constants'
import { ServiceRequestNote } from '../shared/models/ServiceRequestNote'

export const getServiceRequestNotes = async ({ queryKey }: any) => {
  const [_key, { serviceRequestId, userId }] = queryKey

  return fetch(
    `${BASE_API_URL}/v1/service-requests/${serviceRequestId}/notes`,
    {
      headers: {
        userId: userId,
      },
    },
  ).then((response) => {
    if (!response.ok) {
      throw new Error('Something went wrong. Please try again.')
    }
    return response.json()
  })
}

export function useServiceRequestNotes(
  userId: string | number,
  serviceRequestId: number,
  { enabled = true },
) {
  return useQuery<ServiceRequestNote[]>(
    getServiceRequestNotesQueryKey(userId, serviceRequestId),
    getServiceRequestNotes,
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  )
}

export function getServiceRequestNotesQueryKey(
  userId: string | number,
  serviceRequestId: string | number,
): any[] {
  return [
    `service-request-${serviceRequestId}-notes`,
    { serviceRequestId, userId },
  ]
}

export function addServiceRequestNoteInCache(
  cachedData: ServiceRequestNote[] | undefined,
  updated: ServiceRequestNote,
) {
  if (!cachedData) {
    return [updated]
  }

  return [...cachedData, updated]
}

export enum Error {
  UNAUTHORIZE = 'This account is not authorized to perform this action. Please contact your administrator for approval.',
  SERVICE_REQUEST_CREATION_FAILED = 'Service Request creation failed. Please try again.',
}

export const serviceTypes = [
  { label: 'Transportation', value: 'TRANSPORTATION' },
  { label: 'Medical', value: 'MEDICAL' },
  { label: 'Economic', value: 'ECONOMIC' },
  { label: 'Social', value: 'SOCIAL' },
  { label: 'Housing', value: 'HOUSING' },
  { label: 'Civic Engagement', value: 'CIVIC_ENGAGEMENT' },
  { label: 'Mental Health', value: 'MENTAL_HEALTH' },
  { label: 'Legal', value: 'LEGAL' },
  { label: 'Education', value: 'EDUCATION' },
  { label: 'Family', value: 'FAMILY' },
  { label: 'Food', value: 'FOOD' },
  { label: 'Translation', value: 'TRANSLATION' },
  { label: 'Volunteer', value: 'VOLUNTEER' },
  { label: 'Other', value: 'OTHER' },
]

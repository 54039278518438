/* eslint-disable */
export const getPrevMonthLastDate = (): Date => {
  const date = new Date()
  date.setDate(0)

  return date
}

export const getPrevMonthFirstDate = (): Date => {
  const date = new Date()
  const prevMonth = new Date(date.setMonth(date.getMonth() - 1, 1))

  return prevMonth
}

export const formatDateString = () => {
  const prevMonthFirstDate = getPrevMonthFirstDate()
  const prevMonthLastDate = getPrevMonthLastDate()

  const startStr = prevMonthFirstDate.toLocaleString('us-EN', {
    month: 'long',
    day: 'numeric',
  })

  const endStr = prevMonthLastDate.toLocaleString('us-EN', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })

  return startStr + ' - ' + endStr
}

/* eslint-disable */

import { useMemo } from 'react'

export default function useEmployeeColumns() {
  return useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({
          row: {
            original: { id },
          },
        }) => id,
      },
      {
        Header: 'Employee Name',
        accessor: 'firstName',
        Cell: ({
          row: {
            original: { firstName, lastName },
          },
        }) => firstName + ' ' + lastName,
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({
          row: {
            original: { userType },
          },
        }) => userType,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({
          row: {
            original: { email },
          },
        }) => email,
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        Cell: ({
          row: {
            original: { phoneNumber },
          },
        }) => phoneNumber,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({
          row: {
            original: { status },
          },
        }) => (status ? status : '-'),
      },
    ],
    [],
  )
}

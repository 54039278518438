/* eslint-disable  */

import React, { useContext, useState } from 'react'

import Page from '../page/Page'

import styles from './styles.module.scss'
import { AuthContext } from '../contexts/AuthContext'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import TabContent from './components/TabContent/TabContent'
import SERVICE_REQUESTS_TABS from '../shared/utils/tabs'
import { useServiceRequests } from '../api/useServiceRequests'
import { Error } from '../shared/utils/constants'
import { ServiceRequest } from '../shared/models/ServiceRequest'
import { IonBadge } from '@ionic/react'

function ServiceRequests() {
  const [error, setError] = useState('')
  const [tabIndex, setTabIndex] = useState(0)
  const [selectedServiceRequest, setSelectedServiceRequest] = useState()
  const [onCloseSmsModal, setonCloseSmsModal] = useState(false)

  const { userAttributes } = useContext(AuthContext)

  const {
    data: serviceRequests = [],
    isLoading,
    isError,
    refetch: refetchRequests,
  } = useServiceRequests(userAttributes.OrgId, {
    refetchOnWindowFocus: false,
    onError: (err) => {
      if (err.message === '403') {
        setError(Error.UNAUTHORIZE)
      }
    },
  })

  const onSelect = (index: number) => setTabIndex(index)
  const getServiceRequestsByType = (type: string): ServiceRequest[] => {
    const results = (serviceRequests as ServiceRequest[]).filter(
      (request: { status: string }) => request.status === type,
    )
    return results
  }

  const onSelectSMSTranscriptsHandler = (serviceRequest: any) => {
    //   setSelectedServiceRequest(serviceRequest)
    //   showSMSRequest()
  }

  return (
    <Page name='Outgoing Service Requests'>
      <div className={styles.pageWrapper}>
        <div className={styles.tabsContainer}>
          <Tabs selectedIndex={tabIndex} onSelect={onSelect}>
            <TabList className={styles.tabList}>
              {SERVICE_REQUESTS_TABS.map((tab, index) => (
                <Tab className={styles.tabItem} key={index}>
                  {tab.label}
                  <IonBadge color='light'>
                    {getServiceRequestsByType(tab.type).length}
                  </IonBadge>
                </Tab>
              ))}
            </TabList>
            {SERVICE_REQUESTS_TABS.map((item) => (
              <TabPanel key={item.type} className={styles.tabsContainer}>
                <TabContent
                  serviceRequests={getServiceRequestsByType(item.type)}
                  error={error}
                  setError={setError}
                  isLoading={isLoading}
                  isError={isError}
                  refetchRequests={refetchRequests}
                  onSelectSMSTranscripts={onSelectSMSTranscriptsHandler}
                  onSmsModalClose={onCloseSmsModal}
                />
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </div>
    </Page>
  )
}

export default ServiceRequests

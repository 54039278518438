/* eslint-disable  */

import React, { useContext, useMemo, useState } from 'react'
import { IonIcon, IonImg } from '@ionic/react'
import EmployeeProfile from '../employee/EmployeeProfile'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { appRoutes, RouteProps } from '../main/SideMenuRoutes'
import HealthOpXLogo from '../public/HealthOpXLogo.svg'
import './styles/sideMenu.scss'
import { signOut } from '../authentication/auth'
import accountIcon from '../public/assets/icons/account.svg'
import {
  closeOutline,
  menuOutline,
  powerOutline,
  settingsOutline,
} from 'ionicons/icons'

import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from 'react-pro-sidebar'
import styles from '../custom-forms/styles.module.scss'
import useUser from '../api/useUser'
import { UserType } from '../shared/models/UserType'
import { AuthContext } from '../contexts/AuthContext'

function SideMenu({ history }: RouteComponentProps): JSX.Element {
  const [isShow, setShow] = useState(false)
  const [activePage, setActivePage] = useState<string | null>(null)
  const { userAttributes } = useContext(AuthContext)

  const {
    data: currUser,
    isSuccess,
    isError,
  } = useUser(userAttributes.UserId, {
    enabled: !!userAttributes.UserId,
  })

  const signOutHandler = () => {
    signOut()
  }

  const navigateToPage = (route: RouteProps): void => {
    if (!Array.isArray(route.path)) history.push(route.path)
    else {
      route.path.forEach((pth) => {
        history.push(pth)
      })
    }
    setActivePage(route.title)
  }

  const navigateToSettings = () => {
    navigateToPage({ path: '/account-management', title: 'Account' })
  }

  const updatedRoutes = useMemo(() => {
    return [
      {
        title: 'Profile',
        path: '/employees/' + userAttributes.UserId,
        icon: accountIcon,
        component: EmployeeProfile,
      },
      ...appRoutes,
    ]
  }, [userAttributes.UserId, appRoutes])

  const toggleSidebar = () => setShow(!isShow)

  const renderAppRoutes = (): JSX.Element[] => {
    return updatedRoutes.map((route: RouteProps, idx: number) => {
      return (
        <MenuItem
          className={route.title === activePage ? 'activeMenuItem' : 'menuItem'}
          icon={
            <span title={route.title}>
              <IonImg className={styles.addFormIcon} src={route.icon} />
            </span>
          }
          title={route.title}
          key={'appRoute' + idx}
          onClick={(): void => navigateToPage(route)}
        >
          {route.title}
        </MenuItem>
      )
    })
  }

  const renderLogoutLink = (): JSX.Element => {
    return (
      <Menu>
        {currUser && currUser.userType == UserType.ADMIN && (
          <MenuItem
            title='Account'
            className={'Account' === activePage ? 'activeMenuItem' : 'menuItem'}
            onClick={navigateToSettings}
            icon={
              <span title='Settings'>
                <IonIcon icon={settingsOutline} color='dark' />
              </span>
            }
          >
            Account
          </MenuItem>
        )}
        <MenuItem
          title='Logout'
          icon={
            <span title='Logout'>
              <IonIcon
                icon={powerOutline}
                color='dark'
                style={{ pointerEvents: 'none' }}
              />
            </span>
          }
          onClick={signOutHandler}
          key='logout'
        >
          Logout
        </MenuItem>
      </Menu>
    )
  }

  return (
    <ProSidebar collapsed={!isShow} onToggle={toggleSidebar}>
      <SidebarHeader title='Menu'>
        {!isShow ? (
          <IonIcon
            icon={menuOutline}
            size='large'
            color='dark'
            onClick={toggleSidebar}
          />
        ) : (
          <div>
            <span onClick={toggleSidebar}>
              <IonIcon icon={closeOutline} size='large' color='dark' />
            </span>
            <img src={HealthOpXLogo} alt='HealthOpx' onClick={toggleSidebar} />
          </div>
        )}
      </SidebarHeader>
      <SidebarContent>
        <Menu>{renderAppRoutes()}</Menu>
      </SidebarContent>
      <SidebarFooter>{renderLogoutLink()}</SidebarFooter>
    </ProSidebar>
  )
}

export default withRouter(SideMenu)

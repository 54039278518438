/* eslint-disable  */

import React, { useCallback } from 'react'
import {
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react'
import Select from 'react-select'

import styles from './styles.module.scss'
import { ServiceFormProps } from './interfaces'
import { serviceTypes } from '../utils/constants'
import { OrganizationService as IService } from '../../onboarding/util'
import { customStyles } from './styles'

export function ServiceForm({
  index,
  name,
  isNameError,
  type,
  isTypeError,
  description,
  setServices,
  isEdit,
}: ServiceFormProps) {
  const renderLabel = (isError: boolean, label: string) => {
    if (isError) {
      return (
        <IonLabel position='floating' color='danger'>
          {label} is required.
        </IonLabel>
      )
    }
    return <IonLabel position='floating'>{label}</IonLabel>
  }

  const selectedType = useCallback(
    (serviceType: string) => {
      return serviceTypes.find((item) => item.value === serviceType)
    },
    [serviceTypes],
  )

  const onChange = (field: string, value?: string | null) => {
    setServices((data: IService[]) => {
      return data.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: value }
        }
        return item
      })
    })
  }

  return (
    <IonList className={!!index && !isEdit ? styles.container : undefined}>
      {isEdit && (
        <IonRow>
          <IonText className={styles.title}>
            Update your service information
          </IonText>
          <IonText className={styles.subTitle}>
            Select all of the servies needed from the list provided below,{' '}
            <br />
            or write in any servies.
          </IonText>
        </IonRow>
      )}
      <IonRow style={{ marginTop: 10 }}>
        <IonCol>
          <IonItem>
            {renderLabel(isNameError, 'Service name')}
            <IonInput
              value={name}
              onIonChange={(e) => onChange('name', e.detail.value)}
              name='name'
            />
          </IonItem>
        </IonCol>
        {isEdit ? (
          <IonCol>
            {renderLabel(isTypeError, 'Service type')}
            <Select
              options={serviceTypes}
              defaultValue={selectedType(type)}
              onChange={(value) => onChange('type', value?.value)}
              className={styles.dropdown}
              styles={customStyles}
              placeholder='Service Type'
              menuPortalTarget={document.body}
            />
          </IonCol>
        ) : (
          <IonCol>
            <IonItem>
              {renderLabel(isTypeError, 'Service type')}
              <IonSelect
                onIonChange={(e) => onChange('type', e.detail.value)}
                name='type'
              >
                {serviceTypes.map((serviceType, i) => {
                  return (
                    <IonSelectOption key={i} value={serviceType.value}>
                      {serviceType.label}
                    </IonSelectOption>
                  )
                })}
              </IonSelect>
            </IonItem>
          </IonCol>
        )}
      </IonRow>
      <IonItem>
        {renderLabel(false, 'Service description')}
        <IonInput
          value={description}
          onIonChange={(e) => onChange('description', e.detail.value)}
          name='description'
        />
      </IonItem>
    </IonList>
  )
}

/* eslint-disable */

import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react'
import { IonButton, IonSpinner } from '@ionic/react'
import 'react-tabs/style/react-tabs.css'
import {
  getOrgIncomingServiceRequestsQueryKey,
  updateOrgIncomingServiceRequestsInCache,
} from '../../../api/useOrgIncomingServiceRequests'
import Table from '../../../table/Table'
import styles from '../../styles.module.scss'
import { ServiceRequest } from '../../../shared/models/ServiceRequest'
import {
  addServiceRequestNoteInCache,
  getServiceRequestNotesQueryKey,
} from '../../../api/useServiceRequestNotes'
import { useServiceRequestReviewModal } from '../../../shared/ServiceRequestReviewModal'
import { tableColumns } from '../../utils/tableColumns'

import { useSMSRequestModal } from '../../../shared/NewServiceRequest'
import { AuthContext } from '../../../contexts/AuthContext'
import { adminTableColumns } from 'src/service-requests/utils/adminTableColumns'
interface IProps {
  serviceRequests: ServiceRequest[]
  loggedInUserOrgId: string
  isLoading: boolean
  isSRError: boolean
  isError: boolean
  error: string
  setError: (err: string) => void
  refetchRequests: () => void
  onSelectSMSTranscripts: (serviceRequest: any) => void
  onSmsModalClose: boolean
  showCustomFormServiceRequest: () => void
}

const TabContent = ({
  showCustomFormServiceRequest,
  loggedInUserOrgId,
  serviceRequests,
  isLoading,
  isError,
  error,
  setError,
  refetchRequests,
  onSelectSMSTranscripts,
  onSmsModalClose,
}: IProps): JSX.Element => {
  // Filter
  const [filterInput, setFilterInput] = useState('')

  const [selectedServiceRequest, setSelectedServiceRequest] = useState()
  const [onCloseSmsModal, setonCloseSmsModal] = useState(false)

  const { userId } = useContext(AuthContext)

  const handleFilterChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value || '';
      setFilterInput(value);
    },
    [])

  /// Service Request View
  const [
    serviceRequestChosen,
    setServiceRequestChosen,
  ] = useState<ServiceRequest | null>(null)
  // * Close
  const handleServiceRequestReviewModalClose = useCallback(() => {
    setServiceRequestChosen(null)
    refetchRequests()
  }, [setServiceRequestChosen])
  // * Open
  const handleServiceRequestIdClick = useCallback(
    (serviceRequestId: number) => {
      // @ts-ignore
      const serviceRequest =
        serviceRequests.find((i) => i.id.toString() === serviceRequestId.toString()) || null
      setServiceRequestChosen(serviceRequest)
    },
    [serviceRequests],
  )

  // * getQueryKeyForServiceRequests
  const getBindedQueryKeyForServiceRequests = useMemo(
    () =>
      getOrgIncomingServiceRequestsQueryKey.bind(
        null,
        userId,
        loggedInUserOrgId,
        status,
      ),
    [loggedInUserOrgId, status],
  )
  // * getQueryKeyForNotes
  const getBindedServiceRequestNotesQueryKey = useMemo(
    () =>
      getServiceRequestNotesQueryKey.bind(
        null,
        userId,
        serviceRequestChosen?.id || NaN,
      ),
    [userId, serviceRequestChosen?.id],
  )

  const onSelectSMSTranscriptsHandler = (serviceRequest: any) => {
    handleServiceRequestReviewModalClose()
    onSelectSMSTranscripts(serviceRequest)
    setSelectedServiceRequest(serviceRequest)
    showSMSRequest()
  }

  // SMS Request
  const { SMSRequest, show: showSMSRequest } = useSMSRequestModal({
    serviceRequest: selectedServiceRequest,
    onClose: () => {
      setonCloseSmsModal(true), onShowServiceRequestModal(true)
      const serviceRequest = JSON.parse(localStorage.getItem('serviceRequest'))
      setServiceRequestChosen(serviceRequest)
    },
    onSuccess: () => {},
    onCanceled: () => {},
  })

  const {
    ServiceRequestReviewModal,
    isVisible,
    show: onShowServiceRequestModal,
  } = useServiceRequestReviewModal({
    serviceRequest: serviceRequestChosen,
    onClose: handleServiceRequestReviewModalClose,

    getQueryKeyForNotes: getBindedServiceRequestNotesQueryKey,
    addServiceRequestNoteInCache: addServiceRequestNoteInCache,

    getQueryKeyForServiceRequests: getBindedQueryKeyForServiceRequests,
    updateServiceRequestInCache: updateOrgIncomingServiceRequestsInCache,
    onSelectSMSTranscripts: onSelectSMSTranscriptsHandler,
  })

  /// Table content
  const columns = tableColumns({
    onServiceRequestIdClick: handleServiceRequestIdClick,
  })

  const renderRequests = () => {
    if (isLoading) {
      return (
        <div className={styles.loaderContainer}>
          <IonSpinner className={styles.loader} />
        </div>
      )
    }
    if (isError) {
      return (
        <div className={styles.error}>
          Something went wrong. Please try again later.
        </div>
      )
    }
    return (
      <Table
        data={serviceRequests}
        columns={columns}
        globalFilterInput={filterInput}
        initialState={{ sortBy: [{ id: 'id', desc: true }] }}
      />
    )
  }

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.tableTopRow}>
        <input
          className={styles.searchBar}
          value={filterInput}
          onChange={handleFilterChange}
          placeholder='Search Service Requests...'
        />
        <IonButton
          className={styles.newItemButton}
          onClick={showCustomFormServiceRequest}
        >
          {'+ New Member Referral'}
        </IonButton>
        <SMSRequest />
      </div>
      {renderRequests()}
      {serviceRequestChosen && isVisible && <ServiceRequestReviewModal />}
    </div>
  )
}

export default TabContent

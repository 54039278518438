/* eslint-disable */
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { IonButton, IonImg, IonText } from '@ionic/react'
import { RouteProps, useHistory } from 'react-router-dom'
import AddForm from '../public/assets/form_add.svg'
import Page from '../page/Page'
import styles from './styles.module.scss'
import 'react-tabs/style/react-tabs.css'
import { FormsList } from './components/FormsList'
import useSurveysByOrganizationRequests, {
  getAnswersBySurveyId,
  getSurveyById,
} from '../api/useSurvey'
import { Survey } from '../shared/models/Survey'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { AuthContext } from '../contexts/AuthContext'
import { useSurveyImportModal } from 'src/shared/SurveyListUploadModal/useSurveyImportModal'
import { useSurveyListUploadModal } from '../shared/SurveyListUploadModal'
import { SuccessModal } from 'src/shared/SuccessModal'
import { ErrorModal } from 'src/shared/ErrorModal'
import { Store } from 'react-notifications-component'
import { defaultNotificationOptions } from '../shared/Notification'

export const FormsLibrary: FunctionComponent<RouteProps> = (
  props: RouteProps,
) => {
  const [modalConfirmationMessage, setModalConfirmationMessage] = useState('')
  const [errorModalMessage, setErrorModalMessage] = useState('')
  const [allSurveys, setAllSurveys] = useState([])
  const [tabIndex, setTabIndex] = useState(0)

  const { userAttributes } = useContext(AuthContext)

  const history = useHistory()

  // Success Modal
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false)
  const handleSuccessModalClose = useCallback(() => {
    setSuccessModalVisible(false)
  }, [])

  // Error Modal
  const [isErrorModalVisible, setErrorModalVisible] = useState(false)
  const handleErrorModalClose = useCallback(() => {
    setErrorModalVisible(false)
  }, [])

  const {
    data: surveys,
    refetch,
    isSuccess,
    isError,
  } = useSurveysByOrganizationRequests(
    userAttributes.UserId,
    userAttributes.OrgId,
  )

  useEffect(() => {
    localStorage.removeItem('form-edit')
    localStorage.removeItem('form-pending')
    setAllSurveys(surveys)
  }, [surveys, isSuccess])

  const onFilterForms = (searchInput: string) => {
    if (surveys && surveys.length > 0) {
      const filteredSurveys = surveys.filter((survey: Survey) => {
        const surveyTitle = `${survey.title} V.${survey.version}`
        return surveyTitle.toLowerCase().includes(searchInput.toLowerCase())
      })
      setAllSurveys(filteredSurveys)
    }
  }

  const onEditForm = (id: number) => {
    if (tabIndex === 0) {
      getSurveyById(id)
        .then((data) => {
          localStorage.setItem('form-edit', JSON.stringify(data))
          history.push('/forms-create')
        })
        .catch((err) => console.log('Something went wrong!'))
    } else if (tabIndex === 2) {
      const survey = surveys?.find((survey) => survey.id === id)

      if (survey) {
        getAnswersBySurveyId(id).then((res) => {
          history.push('/forms-submissions', {
            model: survey,
            submissions: res,
          })
        })
      }
    } else if (tabIndex === 3) {
      const survey = surveys?.find((survey) => survey.id === id)

      if (survey) {
        getAnswersBySurveyId(id).then((res) => {
          if (res.length > 0)
            history.push('/forms-analytics', {
              model: survey,
              submissions: res,
            })
          else {
            Store.addNotification({
              ...defaultNotificationOptions,
              type: 'info',
              message: `No submissions found for selected survey.`,
              dismiss: {
                duration: 1000,
              },
            })
          }
        })
      }
    }
  }

  const onSelect = (index: number) => {
    setTabIndex(index)
  }

  const handleSurveyListUploadSuccess = useCallback(() => {
    setSuccessModalVisible(true)
  }, [])

  const handleSurveyImportModalSuccess = useCallback(() => {
    refetch()
    setSuccessModalVisible(true)
  }, [])

  const handleSurveyImportModalError = useCallback(() => {
    setErrorModalMessage('Could not successfully import survey')
    setErrorModalVisible(true)
  }, [])

  const {
    SurveyListUploadModal,
    isVisible: isSurveyListUploadModalVisible,
    show: showUpload,
  } = useSurveyListUploadModal(handleSurveyListUploadSuccess)

  const handleUploadSurveysListButtonClick = useCallback(() => {
    showUpload()
  }, [])

  const {
    SurveyImportModal,
    isVisible: isSurveyImportModalVisible,
    show: showImport,
  } = useSurveyImportModal(
    handleSurveyImportModalSuccess,
    handleSurveyImportModalError,
  )

  const handleImportSurveyButtonClick = useCallback(() => {
    showImport()
  }, [])

  const mainContent = (
    <>
      <div className={styles.uploadBtns}>
        <IonButton
          className={styles.uploadList}
          onClick={handleImportSurveyButtonClick}
        >
          {'Import Survey'}
          <svg className={styles.uploadIcon} width='16' height='20'>
            <image
              xlinkHref='/assets/file-upload.svg'
              src='/assets/file-upload.svg'
              width='16'
              height='20'
            />
          </svg>
        </IonButton>
        <IonButton
          className={styles.uploadList}
          onClick={handleUploadSurveysListButtonClick}
        >
          {'Upload Responses'}
          <svg className={styles.uploadIcon} width='16' height='20'>
            <image
              xlinkHref='/assets/file-upload.svg'
              src='/assets/file-upload.svg'
              width='16'
              height='20'
            />
          </svg>
        </IonButton>
      </div>
      <IonButton
        className={styles.newFormButton}
        color='form-create-button'
        routerLink='/forms-create'
      >
        <div>
          <IonImg className={styles.addFormIcon} src={AddForm} />
          <IonText className={styles.newFormText}>Create New Form</IonText>
        </div>
      </IonButton>
    </>
  )

  return (
    <>
      {isSuccessModalVisible && (
        <SuccessModal
          onClose={handleSuccessModalClose}
          text={modalConfirmationMessage}
        />
      )}
      {isErrorModalVisible && (
        <ErrorModal
          onDismiss={handleErrorModalClose}
          text={errorModalMessage}
        />
      )}
      {isSurveyListUploadModalVisible && <SurveyListUploadModal />}
      {isSurveyImportModalVisible && <SurveyImportModal />}
      <Page name='Forms'>
        <div className={styles.gridContent}>
          <div className={styles.formsLibraryPageWrapper}>
            <Tabs selectedIndex={tabIndex} onSelect={onSelect}>
              <TabList className={styles.tabList}>
                <Tab className={styles.tabItem} key='0'>
                  Manage Forms
                </Tab>
                <Tab className={styles.tabItem} key='1'>
                  Archived Forms
                </Tab>
                <Tab className={styles.tabItem} key='2'>
                  Survey Responses
                </Tab>
                <Tab className={styles.tabItem} key='3'>
                  Survey Analytics
                </Tab>
              </TabList>
              <TabPanel key='0' className={styles.tabsContainer}>
                <div className={styles.formAnalyticsWrapper}>
                  <FormsList
                    forms={allSurveys?.filter(
                      (survey: any) => !survey.archivedAt,
                    )}
                    handleFormFilter={(searchInput: string) =>
                      onFilterForms(searchInput)
                    }
                    handleEditForm={(id: number) => onEditForm(id)}
                    selectedIndex={0}
                    selectTab={(index: any) => setTabIndex(index)}
                  ></FormsList>
                </div>
              </TabPanel>
              <TabPanel key='1' className={styles.tabsContainer}>
                <div className={styles.formAnalyticsWrapper}>
                  <FormsList
                    forms={
                      isSuccess &&
                      allSurveys?.filter((survey: any) => survey.archivedAt)
                    }
                    handleFormFilter={(searchInput: string) =>
                      onFilterForms(searchInput)
                    }
                    handleEditForm={(id: number) => onEditForm(id)}
                    selectedIndex={1}
                    selectTab={(index: any) => setTabIndex(index)}
                  ></FormsList>
                </div>
              </TabPanel>
              <TabPanel key='2' className={styles.tabsContainer}>
                <FormsList
                  forms={allSurveys?.filter(
                    (survey: any) => !survey.archivedAt,
                  )}
                  handleFormFilter={(searchInput: string) =>
                    onFilterForms(searchInput)
                  }
                  handleEditForm={(id: number) => onEditForm(id)}
                  selectedIndex={2}
                  selectTab={(index: any) => setTabIndex(index)}
                ></FormsList>
              </TabPanel>
              <TabPanel key='3' className={styles.tabsContainer}>
                <FormsList
                  forms={allSurveys?.filter(
                    (survey: any) => !survey.archivedAt,
                  )}
                  handleFormFilter={(searchInput: string) =>
                    onFilterForms(searchInput)
                  }
                  handleEditForm={(id: number) => onEditForm(id)}
                  selectedIndex={3}
                  selectTab={(index: any) => setTabIndex(index)}
                ></FormsList>
              </TabPanel>
            </Tabs>
          </div>
          <div className={styles.newFormContainer}>{mainContent}</div>
        </div>
      </Page>
    </>
  )
}

/* eslint-disable */
import { useContext } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { AuthContext } from '../contexts/AuthContext'
import { Organization } from '../shared/models/Organization'
import { ServiceRequest } from '../shared/models/ServiceRequest'
import { BASE_API_URL } from './constants'

interface Metrics {
    TotalReferralAgencies: UseQueryResult<Referral[]>
    TotalRequestComplete: UseQueryResult<ServiceRequest[]>
    InboundRequestsComplete: UseQueryResult<ServiceRequest[]>
    TotalRequestRecieved: UseQueryResult<ServiceRequest[]>
    TotalRequestSent: UseQueryResult<ServiceRequest[]>
    TotalReferredTo: UseQueryResult<Referral[]>
    MembersReferred: UseQueryResult<number>
    MembersServed: UseQueryResult<number>
}

interface Referral {
    organization: Organization
    membersServed: number
    totalCount: number
    completedCount: number
}

export const getTotalRequestReceived = async ({ queryKey }: any) => {
    const [_key, { organizationId, userId }] = queryKey

    return await fetch(`${BASE_API_URL}/v1/metrics/totalRequestReceived?orgId=${organizationId}`, {
        headers: {
            userId: userId,
        },
    }).then((response) => response.json())
}

export const getMembersReferred = async ({ queryKey }: any) => {
    const [_key, { organizationId, userId }] = queryKey

    return await fetch(`${BASE_API_URL}/v1/metrics/membersReferred?orgId=${organizationId}`, {
        headers: {
            userId: userId,
        },
    }).then((response) => response.json())
}

export const getMembersServed = async ({ queryKey }: any) => {
    const [_key, { organizationId, userId }] = queryKey

    return await fetch(`${BASE_API_URL}/v1/metrics/membersServed?orgId=${organizationId}`, {
        headers: {
            userId: userId,
        },
    }).then((response) => response.json())
}

export const getTotalReferredTo = async ({ queryKey }: any) => {
    const [_key, { organizationId, userId }] = queryKey

    return await fetch(`${BASE_API_URL}/v1/metrics/totalReferredTo?orgId=${organizationId}`, {
        headers: {
            userId: userId,
        },
    }).then((response) => response.json())
}

export const getTotalRequestSent = async ({ queryKey }: any) => {
    const [_key, { organizationId, userId }] = queryKey

    return await fetch(`${BASE_API_URL}/v1/metrics/totalRequestSent?orgId=${organizationId}`, {
        headers: {
            userId: userId,
        },
    }).then((response) => response.json())
}

export const getTotalRequestComplete = async ({ queryKey }: any) => {
    const [_key, { organizationId, userId, inbound = false }] = queryKey

    return await fetch(`${BASE_API_URL}/v1/metrics/totalRequestComplete?orgId=${organizationId}&inbound=${inbound}`, {
        headers: {
            userId: userId,
        },
    }).then((response) => response.json())
}

export const getTotalReferralAgencies = async ({ queryKey }: any) => {
    const [_key, { organizationId, userId }] = queryKey

    return await fetch(`${BASE_API_URL}/v1/metrics/totalReferralAgencies?orgId=${organizationId}`, {
        headers: {
            userId: userId,
        },
    }).then((response) => response.json())
}

export function useMetrics(organizationId?: number) {
    const { userId } = useContext(AuthContext)
    let metrics = <Metrics>{}

    metrics.TotalRequestRecieved = useQuery([`totalRequestReceived`, { organizationId, userId }], getTotalRequestReceived, {
        refetchOnWindowFocus: false,
    })

    metrics.TotalReferralAgencies = useQuery([`totalReferralAgencies`, { organizationId, userId }], getTotalReferralAgencies, {
        refetchOnWindowFocus: false,
    })

    metrics.TotalRequestComplete = useQuery<ServiceRequest[]>([`totalRequestComplete`, { organizationId, userId }], getTotalRequestComplete, {
        refetchOnWindowFocus: false,
    })

    const inbound = true
    metrics.InboundRequestsComplete = useQuery<ServiceRequest[]>([`totalRequestComplete`, { organizationId, userId, inbound }], getTotalRequestComplete, {
        refetchOnWindowFocus: false,
    })

    metrics.TotalRequestSent = useQuery<ServiceRequest[]>([`totalRequestSent`, { organizationId, userId }], getTotalRequestSent, {
        refetchOnWindowFocus: false,
    })

    metrics.TotalReferredTo = useQuery([`totalReferredTo`, { organizationId, userId }], getTotalReferredTo, {
        refetchOnWindowFocus: false,
    })

    metrics.MembersReferred = useQuery([`totalMembersReferred`, { organizationId, userId }], getMembersReferred, {
        refetchOnWindowFocus: false,
    })

    metrics.MembersServed = useQuery([`totalMembersServed`, { organizationId, userId }], getMembersServed, {
        refetchOnWindowFocus: false,
    })

    return metrics
}

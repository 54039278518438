/* eslint-disable */
import { BASE_API_URL } from './constants'

export const assignServiceRequest = async ({
  serviceRequestId,
  employeeId,
  userId,
}: {
  serviceRequestId: string
  employeeId: string
  userId: string
}) => {
  return fetch(
    `${BASE_API_URL}/v1/service-requests/${serviceRequestId}/assign/${employeeId}`,
    {
      method: 'POST',
      headers: {
        userId: userId,
      },
    },
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.json()
  })
}

/* eslint-disable */
import React, { FunctionComponent } from 'react'
import 'survey-react/survey.css'
import * as Survey from 'survey-react'
import { RouteProps } from 'react-router-dom'
import styles from '../styles.module.scss'
import 'survey-core/modern.min.css'
import { createSurvey } from '../../api/useSurvey'

Survey.StylesManager.applyTheme('modern')

export const SurveyJSPreview: FunctionComponent<RouteProps> = (
  props: RouteProps,
) => {
  // Sets current form template
  const model = new Survey.Model(localStorage.getItem('form-pending'))
  // Sets form responses.
  model.data = {}

  const submitFormHandler = (survey: any, options: any): any => {
    // console.log(survey);
    console.log('Survey results: ' + JSON.stringify(survey.data))
  }

  return <Survey.Survey model={model} onComplete={submitFormHandler} />
}

export default SurveyJSPreview

/* eslint-disable  */

import { IonButton, IonGrid, IonImg, IonRow, IonSpinner } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { ServiceForm } from '../../../shared/ServiceForm'
import { Service } from '../../interfaces'
import closeIcon from '../../../assets/icon/close_icon.svg'

import styles from './styles.module.scss'
import { useUpdateServices } from '../../../api/useUpdateService'

interface IProps {
  isOpen: boolean
  organizationId: string
  onClose: () => void
  services: Service[]
  refetchServices: () => void
}

interface IEditService extends Service {
  isNameError: boolean
  isTypeError: boolean
}

const EditServiceModal = ({
  isOpen,
  organizationId,
  onClose,
  refetchServices,
  services,
}: IProps): JSX.Element => {
  const [serviceList, setServiceList] = useState<IEditService[]>([])

  const { mutate: mutateUpdateServices, isLoading } = useUpdateServices({
    onSuccess: () => {
      refetchServices()
      onClose()
    },
  })

  useEffect(() => {
    setServiceList(
      services.map((item) => ({
        ...item,
        isNameError: false,
        isTypeError: false,
      })),
    )
  }, [services])

  const onSubmit = () => {
    let isError = false
    setServiceList(
      serviceList.map((item) => {
        if (!item.name) {
          isError = true
          return { ...item, isNameError: true }
        } else if (!item.type) {
          isError = true
          return { ...item, isTypeError: true }
        }
        return item
      }),
    )

    if (!isError) {
      mutateUpdateServices(
        serviceList.map((item) => ({
          id: item.id,
          name: item.name,
          type: item.type,
          description: item.description,
          organizationId: organizationId,
        })),
      )
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modalContent}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.clearButtonContainer}>
        <IonButton
          className={styles.clearButton}
          fill='clear'
          onClick={onClose}
        >
          <IonImg className={styles.closeIcon} src={closeIcon} />
        </IonButton>
      </div>
      <IonGrid>
        {serviceList.map((item, i) => {
          return (
            <ServiceForm
              key={i}
              index={i}
              name={item.name}
              type={item.type}
              description={item.description as string}
              isNameError={item.isNameError}
              isTypeError={item.isTypeError}
              setServices={setServiceList}
              isEdit={true}
            />
          )
        })}
        <IonRow>
          <IonButton
            shape='round'
            size='small'
            className={styles.button}
            onClick={onSubmit}
            disabled={isLoading}
          >
            {isLoading ? <IonSpinner color='light' /> : 'Save Changes'}
          </IonButton>
        </IonRow>
      </IonGrid>
    </Modal>
  )
}

export default EditServiceModal

/* eslint-disable */
import React, { ComponentPropsWithRef, useEffect, useState } from 'react'
import 'survey-react/survey.css'
import { SurveyModel } from 'survey-core'
import 'survey-analytics/survey.analytics.css'
import { Tabulator } from 'survey-analytics/survey.analytics.tabulator.js'
import 'survey-analytics/survey.analytics.tabulator.css'
import 'tabulator-tables/dist/css/tabulator.min.css'

import jsPDF from 'jspdf'
import * as XLSX from 'xlsx'
import 'jspdf-autotable'

window.jsPDF = jsPDF
window.XLSX = XLSX

const FormSubmissionTabulator: React.FC<ComponentPropsWithRef<any>> = (
  props,
) => {
  const [vizPanel, setVizPanel] = useState(null)
  const { selectedForm, submissions } = props

  useEffect(() => {
    if (vizPanel) vizPanel.render('summaryContainer')

    return () => {
      if (document.getElementById('summaryContainer') != null)
        document.getElementById('summaryContainer').innerHTML = ''
    }
  }, [vizPanel])

  if (!vizPanel && selectedForm && selectedForm.data) {
    const chartDiv = document.createElement('div')
    const model = new SurveyModel(selectedForm.data)

    const data =
      submissions?.length > 0
        ? submissions?.map((submission) => {
            return submission.data
          })
        : []

    let tabPanel = new Tabulator(model, data, {
      showLogicTab: false,
      haveCommercialLicense: true,
    })

    setVizPanel(tabPanel)
  }

  return <div id='summaryContainer' />
}

export default FormSubmissionTabulator

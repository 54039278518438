/* eslint-disable */

import React, { FunctionComponent } from 'react'
import 'survey-react/survey.css'
import 'survey-core/modern.min.css'
import * as Survey from 'survey-react'
import { RouteProps } from 'react-router-dom'
import { IonButton, IonCol, IonRow, IonText } from '@ionic/react'
import styles from '../../MemberMessaging.module.scss'
import { getDefaultPatientMessage } from '../../../shared/PatientMessagingRequest/utils/defaultPatientMessaging'

Survey.StylesManager.applyTheme('modern')

export const CustomMessage: FunctionComponent<RouteProps> = (
  props: RouteProps,
) => {
  const selectedMessage = ''

  if (props.messages) {
    const selectedMessage = props?.messages?.find(
      (msg) => msg.key === props.msgKey,
    )
  }
  const message = selectedMessage
    ? selectedMessage?.msg
    : getDefaultPatientMessage(props.msgKey)

  const onEdit = () => {
    props.onEditMsg({
      selectedMessage,
      title: props.msgType,
      msgKey: props.msgKey,
      defaultMsg: message,
    })
  }

  return (
    <IonRow className={styles.card}>
      <IonCol>
        <IonCol className={styles.cardContent}>
          <IonRow className={styles.cardRow}>
            <IonText className={styles.cardText}>{props?.msgType}</IonText>
          </IonRow>
          <IonRow className={styles.cardMargin}>
            <IonButton className={styles.msgButton} onClick={() => onEdit()}>
              <span className={styles.cardButtonText}>EDIT</span>
            </IonButton>
          </IonRow>
        </IonCol>
        <IonCol className={styles.cardColumn}>
          <IonRow>
            <IonText>Current Messaging: </IonText>
          </IonRow>
          <IonRow className={styles.cardInput}>
            <IonText>{message ? message : ''}</IonText>
          </IonRow>
        </IonCol>
        <hr className={styles.customHr} />
      </IonCol>
    </IonRow>
  )
}

export default CustomMessage

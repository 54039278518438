/* eslint-disable */
import React, { FunctionComponent } from 'react'
import { RouteProps } from 'react-router-dom'

import styles from './styles.module.scss'

import 'react-tabs/style/react-tabs.css'
import Page from '../page/Page'
import FormSubmissionTabulator from './components/FormSubmissionTabulator'

export const FormSubmissions: FunctionComponent<RouteProps> = (
  props: RouteProps,
) => {
  return (
    <Page name='Forms'>
      <div className={styles.pageWrapper}>
        <FormSubmissionTabulator
          selectedForm={props.location?.state.model}
          submissions={props.location?.state.submissions}
        />
      </div>
    </Page>
  )
}

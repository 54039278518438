/* eslint-disable  */

import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useMutation } from 'react-query'
import { PatientMessagingServiceRequest } from '.'
import { CustomMessage } from './interfaces'
import {
  createPatientMessaging,
  updatePatientMessaging,
} from '../../api/usePatientMessaging'
import { getCognitoAttribute, getCurrentUser } from '../../authentication/auth'

export function usePatientMessagingRequestModal(props: {
  msg: any
  onSuccess: () => void
  onError?: () => void
  onSettled?: () => void
  onCanceled: () => void
}) {
  const [currentUserAttributes, setCurrentUserAttributes] = useState([])
  useEffect(() => {
    getCurrentUser((attributes: any) => {
      setCurrentUserAttributes(attributes)
    })
  }, [getCurrentUser])

  const loggedInUserOrgId = useMemo(() => {
    return getCognitoAttribute(currentUserAttributes, 'custom:organizationId')
  }, [currentUserAttributes])

  const [isPopupVisible, setPopupVisible] = useState(false)

  const handleShow = useCallback(() => {
    setPopupVisible(true)
  }, [])
  const handleHide = useCallback(() => {
    setPopupVisible(false)
  }, [])

  const { mutate: mutateUpdateCustomMessageRequest } = useMutation(
    updatePatientMessaging,
    {
      onSuccess: (data) => {},
    },
  )

  const { mutate: mutateCreateCustomMessageRequest } = useMutation(
    createPatientMessaging,
    {
      onSuccess: (data) => {},
    },
  )

  const handleSubmit = useCallback((requestData: CustomMessage) => {
    const { id, key, msg } = requestData
    if (id) {
      mutateUpdateCustomMessageRequest(
        {
          id,
          key,
          msg,
          organizationId: loggedInUserOrgId,
        },
        {
          onSuccess: () => {
            props.onSuccess()
          },
          onError: () => {
            props.onError && props.onError()
          },
          onSettled: () => {
            setPopupVisible(false)
            props.onSettled && props.onSettled()
          },
        },
      )
    } else {
      mutateCreateCustomMessageRequest(
        {
          id,
          key,
          msg,
          organizationId: loggedInUserOrgId,
        },
        {
          onSuccess: () => {
            props.onSuccess()
          },
          onError: () => {
            props.onError && props.onError()
          },
          onSettled: () => {
            setPopupVisible(false)
            props.onSettled && props.onSettled()
          },
        },
      )
    }
  }, [])

  const handleCancel = useCallback(() => {
    setPopupVisible(false)
    props.onCanceled()
  }, [])

  const component = useMemo(() => {
    return () => (
      <>
        {isPopupVisible && (
          <PatientMessagingServiceRequest
            onSubmit={(customMessage: CustomMessage) =>
              handleSubmit(customMessage)
            }
            onCancel={handleCancel}
            msg={props.msg}
          />
        )}
      </>
    )
  }, [isPopupVisible, handleSubmit, handleCancel])

  return {
    isVisible: isPopupVisible,
    show: handleShow,
    hide: handleHide,
    PatientMessagingServiceRequest: component,
  }
}

/* eslint-disable */
import React from 'react'
import {
  IonCardHeader,
  IonCard,
  IonCardContent,
  IonText,
  IonIcon,
  IonButton,
} from '@ionic/react'
import { close } from 'ionicons/icons'

interface AlertProps {
  header: string
  content: string
  type: 'ERROR' | 'WARN'
  onClose: () => void
}

export default function Notices(props: AlertProps): JSX.Element {
  const { header, content, onClose } = props
  const getTheme = () => {
    switch (props.type) {
      case 'ERROR':
        return '#fc312f'
    }
  }
  return (
    <IonCard style={{ border: '2px solid ' + getTheme() }}>
      <IonCardHeader style={{ backgroundColor: getTheme(), color: 'white' }}>
        <IonText style={{ margin: '10px' }}>{header}</IonText>
        <IonButton
          size='small'
          fill='clear'
          onClick={() => {
            onClose()
          }}
          style={{
            height: '15px',
            width: '50px',
            position: 'absolute',
            right: '0px',
          }}
        >
          <IonIcon
            icon={close}
            style={{ color: 'white', height: '15px' }}
            slot='start'
          />
        </IonButton>
      </IonCardHeader>
      <IonCardContent style={{ paddingTop: '10px' }}>
        <IonText style={{ margin: '10px' }}>{content}</IonText>
      </IonCardContent>
    </IonCard>
  )
}

/* eslint-disable */
import React, { FunctionComponent, useContext, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import styles from './styles.module.scss'
import 'react-tabs/style/react-tabs.css'
import Page from '../page/Page'
import SurveyJSCreator from './components/SurveyJSCreator'
import { AuthContext } from 'src/contexts/AuthContext'

export const FormsCreator: FunctionComponent<RouteProps> = (
  props: RouteProps,
) => {
  const initialForm = localStorage.getItem('form-edit')
  const { userAttributes: userAttributes } = useContext(AuthContext)

  return (
    <>
      <Page name='Forms'>
        <div className={styles.pageWrapper}>
          <div className={styles.formCreatorHeader}></div>
          <SurveyJSCreator
            initialForm={initialForm}
            formDraft={draft}
            organizationId={userAttributes.OrgId}
          ></SurveyJSCreator>
        </div>
      </Page>
    </>
  )
}

/* eslint-disable */
import { BASE_API_URL } from './constants'

export const addNote = async ({
  serviceRequestId,
  text,
  createdById,
  userId,
  file,
}: {
  serviceRequestId: number
  text: string
  createdById: number
  userId: string
  file: File
}) => {
  const formData = new FormData()
  const data = JSON.stringify({
    serviceRequestId: serviceRequestId,
    content: text,
    createdById: createdById,
  })
  const jsonFile = new File([data], 'file.json', { type: 'application/json' })
  formData.append('note', jsonFile, jsonFile.name)

  if (file) {
    formData.append('file', file, file.name)
  }

  return fetch(`${BASE_API_URL}/v1/notes`, {
    method: 'POST',
    headers: {
      userId: userId,
    },
    body: formData,
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.json()
  })
}

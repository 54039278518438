/* eslint-disable */

import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonText,
} from '@ionic/react'
import { addCircleOutline } from 'ionicons/icons'
import React from 'react'
import styles from './styles.module.scss'
import { OrganizationService } from '../util'
import { ServiceForm } from '../../shared/ServiceForm'

interface IProps {
  next: any
  orgServices: OrganizationService[]
  setOrgServices: (data: OrganizationService[]) => void
  error?: string
}

const AddOrganizationService = ({
  next,
  orgServices,
  setOrgServices,
  error,
}: IProps): JSX.Element => {
  const onSubmit = () => {
    let isError = false
    const services = orgServices.map((item) => {
      if (!item.name) {
        isError = true
        return { ...item, isNameError: true }
      } else if (!item.type) {
        isError = true
        return { ...item, isTypeError: true }
      }
      return item
    })

    setOrgServices(services)

    if (!isError) {
      next(true)
    }
  }

  return (
    <IonGrid className='ion-padding-horizontal'>
      <IonRow>
        <IonCol>
          <IonText className={styles.headerText}>
            What service(s) do your organization provide the public?
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonText className={styles.descText}>
            Select All of the services needed from the list provided below, or
            write in any services
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow className={styles.ionRowFormContainer}>
        <IonCol className='ion-text-center'>
          {orgServices.map((item, index) => {
            return (
              <ServiceForm
                {...item}
                key={index}
                index={index}
                setServices={setOrgServices}
              />
            )
          })}

          <div
            className={styles.addServiceContainer}
            onClick={() =>
              setOrgServices([
                ...orgServices,
                {
                  name: '',
                  type: '',
                  description: '',
                  isNameError: false,
                  isTypeError: false,
                },
              ])
            }
          >
            <IonIcon icon={addCircleOutline} color='dark' size='large' />
            <p>Add a new service</p>
          </div>

          {error && (
            <IonItem>
              <IonText color='danger'>{error}</IonText>
            </IonItem>
          )}

          <IonButton
            shape='round'
            fill='outline'
            expand='full'
            color='dark'
            className='next-button ion-margin-top'
            onClick={onSubmit}
          >
            NEXT
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default AddOrganizationService

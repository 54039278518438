/* eslint-disable */

import React, { useRef, useState } from 'react'
import {
  IonGrid,
  IonCol,
  IonRow,
  IonInput,
  IonItem,
  IonButton,
  IonText,
  IonList,
  IonLabel,
  IonIcon,
} from '@ionic/react'
import { useForm } from 'react-hook-form'
import { person, lockClosed } from 'ionicons/icons'
import {
  validPhoneNumber,
  validEmail,
  validPassword,
} from '../validation/SignUpValidation'
import styles from './styles.module.scss'
import { AdminData } from '../util'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

function AdminSignUp(props: {
  next: any
  userData: AdminData
  setUserData: any
  error?: string
}) {
  const {
    handleSubmit,
    register,
    trigger,
    watch,
    unregister,
    formState: { errors },
  } = useForm()

  const password = useRef({})
  password.current = watch('password', '')
  const [confirmPass, setConfirmPass] = useState('')

  const onSubmit = async (data: AdminData) => {
    props.setUserData(data)
    unregister('confirmPassword')
    const result = await trigger([
      'firstName',
      'lastName',
      'zipcode',
      'phoneNumber',
      'email',
      'password',
    ])
    props.next(result)
  }

  const [phone, setPhone] = useState()

  const setPhoneNumber = (data: any) => {
    setPhone(data)
  }

  return (
    <IonGrid className='ion-padding-horizontal'>
      <IonRow>
        <IonCol>
          <IonText className={styles.headerText}>
            {'Create Your Profile'}
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonText className={styles.descText}>
            {'We will need to set up and secure your account.'}
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <form
          className={styles.form}
          autoComplete='off'
          onSubmit={handleSubmit((data) =>
            onSubmit({ ...props.userData, ...data }),
          )}
        >
          <IonCol>
            <IonList>
              <IonItem>
                {errors.firstName && (
                  <IonLabel position='floating' color='danger'>
                    {errors.firstName.message}
                  </IonLabel>
                )}
                <IonInput
                  {...register('firstName', {
                    required: 'First Name is required.',
                  })}
                  placeholder='First Name'
                  name='firstName'
                />
                <IonIcon icon={person} slot={'end'} />
              </IonItem>
              <IonItem>
                {errors.lastName && (
                  <IonLabel position='floating' color='danger'>
                    {errors.lastName.message}
                  </IonLabel>
                )}
                <IonInput
                  {...register('lastName', {
                    required: 'Last Name is required.',
                  })}
                  placeholder='Last Name'
                  name='lastName'
                />
                <IonIcon icon={person} slot={'end'} />
              </IonItem>
              <IonItem>
                {errors.phoneNumber && (
                  <IonLabel position='floating' color='danger'>
                    {errors.phoneNumber.message}
                  </IonLabel>
                )}
                <PhoneInput
                  country={'US'}
                  defaultCountry='US'
                  international
                  countryCallingCodeEditable={false}
                  displayInitialValueAsLocalNumber={true}
                  autoComplete='tel'
                  type='tel'
                  placeholder='+12223334444'
                  value={phone}
                  {...register('phoneNumber', {
                    minLength: 12,
                    setValueAs: (v) => v.replace(/ /g, ''),
                    required: 'Phone Number is required.',
                    validate: (value) =>
                      validPhoneNumber(value) ||
                      'Must be a valid Phone Number.',
                  })}
                  onChange={setPhoneNumber}
                  limitMaxLength={true}
                  inputExtraProps={{
                    name: 'phoneNumber',
                    required: true,
                    autoFocus: true,
                    country: 'us',
                  }}
                  style={{
                    padding: '10px 0',
                  }}
                />
              </IonItem>
              <IonItem>
                {errors.email && (
                  <IonLabel position='floating' color='danger'>
                    {errors.email.message}
                  </IonLabel>
                )}
                <IonInput
                  {...register('email', {
                    required: 'Email is required.',
                    validate: (value) =>
                      validEmail(value) || 'Must be a valid Email.',
                  })}
                  placeholder='Email'
                  type='email'
                  name='email'
                />
              </IonItem>
              <IonItem>
                {errors.password && (
                  <IonLabel position='floating' color='danger'>
                    {errors.password.message}
                  </IonLabel>
                )}
                <IonInput
                  {...register('password', {
                    required: 'Password is required.',
                    validate: (value) => {
                      if (!validPassword(value).valid)
                        return validPassword(value).error
                      if (value !== confirmPass)
                        return 'Passwords do not match.'
                    },
                    minLength: {
                      value: 8,
                      message: 'Password must have at least 8 characters.',
                    },
                  })}
                  placeholder='Password'
                  type='password'
                  name='password'
                />
                <IonIcon icon={lockClosed} slot={'end'} />
              </IonItem>
              <IonItem>
                {errors.confirmPassword && (
                  <IonLabel position='floating' color='danger'>
                    Please fill out required fields
                  </IonLabel>
                )}
                <IonInput
                  placeholder='Confirm Password'
                  onIonChange={(e) => setConfirmPass(e.detail.value as string)}
                  type='password'
                />
                <IonIcon icon={lockClosed} slot={'end'} />
              </IonItem>
            </IonList>
            {props.error && <IonLabel color='danger'>{props.error}</IonLabel>}
            <IonButton
              shape='round'
              fill='outline'
              expand='full'
              color='dark'
              className='next-button'
              type='submit'
            >
              NEXT
            </IonButton>
          </IonCol>
        </form>
      </IonRow>
    </IonGrid>
  )
}

export default AdminSignUp

/* eslint-disable */
import { useQueryClient } from 'react-query'
import React, { useContext, useState } from 'react'
import { IonInput, IonItem, IonLabel, IonList, IonText } from '@ionic/react'
import styles from '../styles.module.scss'

import { Survey } from '../../shared/models/Survey'
import { useArchiveModal } from '../../shared/ArchiveModal'
import { archiveSurvey } from '../../api/useArchiveForm'
import { AuthContext } from '../../contexts/AuthContext'

export function FormsList(props: any) {
  const queryClient = useQueryClient()
  const activeTab = props.selectedIndex
  const [textToCopy, setTextToCopy] = useState('')
  const [selectedArchiveForm, setSelectedArchiveForm] = useState()

  const { userAttributes } = useContext(AuthContext)

  const archiveFormSurvey = () => {
    archiveSurvey({
      id: selectedArchiveForm?.id,
      archived: activeTab === 0,
    }).then(() => {
      queryClient.invalidateQueries(`${userAttributes.OrgId}-surveys`)
    })
  }

  const { ArchiveModal, show, setItemId } = useArchiveModal({
    onConfirm: archiveFormSurvey,
    text: `${selectedArchiveForm?.title} V.${selectedArchiveForm?.version}`,
    isArchive: activeTab === 0 ? 'archive' : 'unarchive',
  })

  const archiveForm = (id: number) => {
    const selectedForm = props.forms.find((form: any) => form.id == id)
    setSelectedArchiveForm(selectedForm)
    show()
  }

  const handleCopyLink = (formId: string, formTitle: string) => {
    navigator.clipboard
      .writeText(`${window.location.hostname}/form/${formId}`)
      .then(() => {
        alert(`${formTitle} link copied to clipboard.`)
      })
  }

  return (
    <div className={styles.formsListWrapper}>
      <IonText className={styles.formsListTitle}>Current Forms</IonText>
      <IonText className={styles.formsListDescription}>
        {activeTab === 0 || activeTab === 1
          ? 'Select a form below to edit or review'
          : 'Select a form below to review collected responses &\n' +
            'create analytic reports'}
      </IonText>
      <IonInput
        className={styles.formsSearch}
        placeholder='Search forms...'
        onIonInput={(e) => props.handleFormFilter(e.target.value)}
      ></IonInput>
      <IonList className={styles.formsList}>
        {props.forms?.map((form: Survey) => {
          return (
            <div className={styles.formContainer}>
              <div className={styles.formItemShare}>
                <IonItem
                  key={form.id}
                  className={styles.formItem}
                  id={styles.formTitle}
                  onClick={() => props.handleEditForm(form.id)}
                  lines='none'
                >
                  <IonLabel className={styles.formLink}>
                    {form.title} V.{form.version}
                  </IonLabel>
                </IonItem>
                <ArchiveModal />
                {activeTab === 0 && (
                  <button
                    id='shareBtn'
                    title='Copy Link'
                    onClick={() => {
                      handleCopyLink(form.uniqueSurveyId, form.title)
                    }}
                  >
                    <svg className={styles.icon} width='20' height='20'>
                      <image
                        xlinkHref='/assets/share.svg'
                        width='20'
                        height='20'
                      />
                    </svg>
                  </button>
                )}
              </div>
              {(activeTab === 0 || activeTab === 1) && (
                <IonItem
                  key={form.title}
                  className={styles.formItem}
                  lines='none'
                >
                  <IonLabel
                    onClick={() => archiveForm(form.id)}
                    className={styles.formLink}
                  >
                    {activeTab === 0 ? 'Archive' : 'Unarchive'}
                  </IonLabel>
                </IonItem>
              )}
            </div>
          )
        })}
      </IonList>
    </div>
  )
}

/* eslint-disable */
import { BASE_API_URL } from './constants'

export const archiveSurvey = async (data: any) => {
  const res = await fetch(`${BASE_API_URL}/v1/surveys/${data.id}/archive`, {
    method: 'POST',
    body: `${data.archived}`,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  if (!res.ok) {
    throw new Error('Something went wrong! Please try again')
  }
  return res.json()
}

/* eslint-disable */

import { IonButton, IonCol, IonGrid, IonRow, IonText } from '@ionic/react'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import styles from './styles.module.scss'
import TermsAndConditions from './TermsAndCond'

interface IProps {
  setAccept: (isAccept: boolean) => void
  isLoading: boolean
}

const TermsOfServices = ({ setAccept, isLoading }: IProps): JSX.Element => {
  const {
    formState: { errors },
  } = useFormContext()

  const onDecline = () => {
    setAccept(false)
  }

  const onAccept = () => {
    setAccept(true)
  }

  return (
    <IonGrid className='ion-padding-horizontal'>
      <IonRow>
        <IonCol>
          <IonText className={styles.headerText}>
            Terms of Service & Privacy Policy
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonText className={styles.descText}>
            Please read the information before continuing through the App.
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className='ion-text-center'>
          <TermsAndConditions />
          <IonRow>
            <IonCol>
              <IonButton
                shape='round'
                fill='outline'
                expand='full'
                color='medium'
                onClick={onDecline}
              >
                Decline
              </IonButton>
            </IonCol>
            <IonCol size='5'>
              <IonButton
                shape='round'
                fill='outline'
                expand='full'
                color='success'
                onClick={onAccept}
                className={styles.button}
                disabled={isLoading}
              >
                {isLoading ? 'Loading' : 'Accept'}
              </IonButton>
            </IonCol>
            <IonCol size='3' />
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default TermsOfServices

/* eslint-disable */
export const validZipcode = (zipcode: string) => {
  const validZipcode = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$')
  return validZipcode.test(zipcode)
}
export const validPhoneNumber = (phoneNumber: string) => {
  const validPhoneNumber = new RegExp(
    '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$',
  )
  return validPhoneNumber.test(phoneNumber)
}
export const validEmail = (email: string) => {
  const validEmail = new RegExp('[^s@]+@[^s@]+.[^s@]+$')
  return validEmail.test(email)
}
export const validPassword = (
  password: string,
): { valid: boolean; error: string } => {
  const validPasswordSpecialChar = new RegExp('(?=.*[!@#$%^&*])')
  const hasSpecialCharacter = validPasswordSpecialChar.test(password)
  if (!hasSpecialCharacter)
    return {
      valid: false,
      error: 'Must contain at least one special character.',
    }

  const validPasswordCapitalLetter = new RegExp('(.*[A-Z].*)')
  const hasCapitalLetter = validPasswordCapitalLetter.test(password)
  if (!hasCapitalLetter)
    return { valid: false, error: 'Must contain at least one capital letter.' }

  const validPasswordNumber = new RegExp('(.*[0-9].*)')
  const hasNumber = validPasswordNumber.test(password)
  if (!hasNumber)
    return { valid: false, error: 'Must contain at least one number.' }

  return { valid: true, error: '' }
}

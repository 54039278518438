/* eslint-disable */
import { BASE_API_URL } from './constants'
import { useQuery } from 'react-query'
import { Survey } from '../shared/models/Survey'

export const createSurvey = async (data: any) => {
  const res = await fetch(`${BASE_API_URL}/v1/surveys`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  if (!res.ok) {
    throw new Error('Something went wrong! Please try again')
  }
  return res.json()
}

export const updateSurvey = async (data: any) => {
  const res = await fetch(`${BASE_API_URL}/v1/surveys/${data.id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  if (!res.ok) {
    throw new Error('Something went wrong! Please try again')
  }
  return res.json()
}

const getSurveysRequests = async ({ queryKey }: any) => {
  const [_key, { organizationId, userId }] = queryKey
  return await fetch(
    `${BASE_API_URL}/v1/organizations/${organizationId}/surveys`,
    {
      method: 'GET',
      headers: {
        userId: userId,
      },
    },
  )
    .then((response) => response.json())
    .catch((err) => [])
}

export default function useSurveysByOrganizationRequests(
  userId: string | number,
  organizationId: string | number,
) {
  return useQuery<Survey[]>(
    getOrganizationSurveysRequestsQueryKey(userId, organizationId),
    getSurveysRequests,
    { refetchOnWindowFocus: false },
  )
}

export function getOrganizationSurveysRequestsQueryKey(
  userId: string | number,
  organizationId: string | number,
): any[] {
  return [`${organizationId}-surveys`, { organizationId, userId }]
}

const getSurveyByIdRequest = async (id: string | number) => {
  return await fetch(`${BASE_API_URL}/v1/surveys/${id}`, {
    method: 'GET',
    headers: {},
  })
    .then((response) => {
      if (response.ok) return response.json()
      else throw new Error('Could not retrieve survey')
    })
    .catch((err) => console.error(err))
}

const getSurveyByUniqueIdRequest = async (id: string) => {
  return await fetch(`${BASE_API_URL}/v1/surveys/${id}/display`, {
    method: 'GET',
    headers: {},
  })
    .then((response) => {
      if (response.ok) return response.json()
      else throw new Error('Could not retrieve survey')
    })
    .catch((err) => console.error(err))
}

export function getSurveyById(id: string | number) {
  return getSurveyByIdRequest(id)
}

export function getSurveyByUniqueId(id: string) {
  return getSurveyByUniqueIdRequest(id)
}

export const saveSurveyResponse = async (data: any) => {
  const res = await fetch(
    `${BASE_API_URL}/v1/surveys/${data.surveyId}/answer`,
    {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error('Something went wrong! Please try again')
      }
      return res.json()
    })
    .catch((err) => console.error(err))
}

const getSurveyAnswersByIdRequest = async (id: string | number) => {
  return await fetch(`${BASE_API_URL}/v1/survey-answers/${id}`, {
    method: 'GET',
    headers: {},
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log(err))
}

export const submitSurvey = async (response: any) => {
  const res = await fetch(`${BASE_API_URL}/v1/survey-answers/submit`, {
    method: 'POST',
    body: JSON.stringify(response),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error('Something went wrong! Please try again')
      }
      return res.json()
    })
    .catch((err) => console.error(err))
}

export function getSurveyAnswersById(id: string | number) {
  return getSurveyAnswersByIdRequest(id)
}

export const getSurveyAnswersBySurveyId = async (id: string | number) => {
  return await fetch(`${BASE_API_URL}/v1/surveys/${id}/answers`, {
    method: 'GET',
    headers: {},
  }).then((response) => response.json())
}

export function getAnswersBySurveyId(id: string | number) {
  return getSurveyAnswersBySurveyId(id)
}

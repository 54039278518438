/* eslint-disable */

import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonProgressBar,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
} from '@ionic/react'
import React, { useContext } from 'react'
import Page from '../page/Page'
import styles from './MetricsDashboard.module.scss'
import { ticketOutline } from 'ionicons/icons'
import { useMetrics } from '../api/useMetrics'
import useUser from '../api/useUser'
import { AuthContext } from '../contexts/AuthContext'
import { formatDateString } from './timeutil'
import MetricsReport from './MetricsReport'

function MetricsDashboard(): JSX.Element {
    const { userId } = useContext(AuthContext)
    const { data: userData } = useUser(userId)
    const metrics = useMetrics(userData?.organizationIds[0])

    const requestsComplete = metrics.TotalRequestComplete.data?.length ? metrics.TotalRequestComplete.data.length : 0
    const inboundRequestsComplete = metrics.InboundRequestsComplete.data?.length ? metrics.InboundRequestsComplete.data.length : 0
    const requestsRecievedCount = metrics.TotalRequestRecieved.data?.length ? metrics.TotalRequestRecieved.data.length : 0
    const referralAgenciesCount = metrics.TotalReferralAgencies.data?.length ? metrics.TotalReferralAgencies.data.length : 0
    const requestsSent = metrics.TotalRequestSent.data?.length ? metrics.TotalRequestSent.data.length : 0

    const membersReferred = metrics.MembersReferred.data ? metrics.MembersReferred.data : 0
    const membersServed = metrics.MembersServed.data ? metrics.MembersServed.data : 0

    const referredToOrganizations = metrics.TotalReferredTo.data ? metrics.TotalReferralAgencies.data : []
    const referredToAgencies = metrics.TotalReferralAgencies.data ? metrics.TotalReferralAgencies.data : []
    const requestsRecieved = metrics.TotalRequestRecieved.data ? metrics.TotalRequestRecieved.data : []

    const average = referredToOrganizations?.reduce(
        function (avg, value, _) {
            avg.completed += value.completedCount
            avg.totalMembers += value.totalCount

            return avg
        },
        { totalAvg: 0, completed: 0, totalMembers: 0 },
    )

    const requestsRecievedByServiceIdObj = requestsRecieved.reduce(function (r, a) {
        r[a.service.name] = r[a.service.name] || { name: a.service.name, count: 0 }
        r[a.service.name].count += 1
        return r
    }, Object.create(null))

    const requestsRecievedByServiceId = Object.entries(requestsRecievedByServiceIdObj)

    const completedRequestMetrics = (): JSX.Element => {
        return (
            <IonCard className={styles.card}>
                <IonCardContent>
                    <IonItem lines='none'>
                        <IonIcon icon={ticketOutline} className={styles.completedIcon} slot='start' />
                        <IonLabel color='black' slot=''>
                            <IonText style={{ marginRight: '25px', position: 'relative', bottom: '10px', fontSize: '20px' }}>
                                Total Service Requests Completed
                            </IonText>
                            <IonText style={{ fontSize: '46px', fontWeight: 'bold' }}>{requestsComplete}</IonText>
                            <IonText style={{ fontSize: '30px', fontWeight: 'bold' }}>/{requestsSent}</IonText>
                        </IonLabel>
                    </IonItem>
                </IonCardContent>
                <IonProgressBar style={{ height: '10px' }} value={requestsComplete / requestsSent}></IonProgressBar>
            </IonCard>
        )
    }

    const inboundCompletedRequestMetrics = (): JSX.Element => {
        return (
            <IonCard className={styles.card}>
                <IonCardContent>
                    <IonItem lines='none'>
                        <IonIcon icon={ticketOutline} className={styles.completedIcon} slot='start' />
                        <IonLabel color='black' slot=''>
                            <IonText style={{ marginRight: '25px', position: 'relative', bottom: '10px', fontSize: '20px' }}>
                                Inbound Service Requests Completed
                            </IonText>
                            <IonText style={{ fontSize: '46px', fontWeight: 'bold' }}>{inboundRequestsComplete}</IonText>
                            <IonText style={{ fontSize: '30px', fontWeight: 'bold' }}>/{requestsRecievedCount}</IonText>
                        </IonLabel>
                    </IonItem>
                </IonCardContent>
                <IonProgressBar style={{ height: '10px' }} value={inboundRequestsComplete / requestsRecievedCount}></IonProgressBar>
            </IonCard>
        )
    }

    const recievedRequestMetrics = (): JSX.Element => {
        return (
            <IonCard className={styles.card}>
                <IonCardContent>
                    <IonItem lines='none'>
                        <IonIcon icon={ticketOutline} className={styles.completedIcon} slot='start' />
                        <IonLabel color='black' slot=''>
                            <IonText style={{ marginRight: '25px', position: 'relative', bottom: '10px', fontSize: '20px' }}>Service Requests Recieved</IonText>
                            <IonText style={{ fontSize: '46px', fontWeight: 'bold' }}>{requestsRecievedCount}</IonText>
                        </IonLabel>
                    </IonItem>
                </IonCardContent>
                <IonProgressBar style={{ height: '10px' }} value={requestsRecievedCount / requestsSent}></IonProgressBar>
            </IonCard>
        )
    }

    const conversionRatesMetrics = (): JSX.Element => {
        return (
            <IonCard className={styles.card} style={{ height: '400px' }}>
                <IonCardContent>
                    <IonCardHeader className={styles.cardHeader}>
                        <IonText>Conversion Rates</IonText>
                    </IonCardHeader>
                    <IonText className={styles.cardSubtitle}>Referral To Completed</IonText>
                    <IonItem lines='none'>
                        <IonLabel slot='start' style={{ color: 'black' }}>
                            <IonText style={{ fontWeight: 'bold', fontSize: '46px' }}>
                                {(((average?.completed ?? 0) / (average?.totalMembers ?? 0)) * 100 ?? 0).toFixed(0)}%
                            </IonText>
                        </IonLabel>
                    </IonItem>
                    <div style={{ overflowY: 'scroll', height: '250px' }}>
                        {referredToOrganizations?.map((data, idx) => {
                            return (
                                <IonItem key={idx}>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol class='ion-text-start'>
                                                <IonText>{data.organization.name}</IonText>
                                            </IonCol>
                                            <IonCol size='2' class='ion-text-right'>
                                                <IonText>{+((data.completedCount / data.totalCount) * 100).toFixed(0)}%</IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                            )
                        })}
                    </div>
                </IonCardContent>
            </IonCard>
        )
    }

    const patientsReferredMetrics = (): JSX.Element => {
        return (
            <IonCard className={styles.card} style={{ height: '400px' }}>
                <IonCardContent>
                    <IonCardHeader className={styles.cardHeader}>
                        <IonText>Members referred</IonText>
                    </IonCardHeader>
                    <IonItem style={{ paddingTop: '15px' }} lines='none'>
                        <IonLabel slot='start' style={{ color: 'black' }}>
                            <IonText style={{ fontWeight: 'bold', fontSize: '46px' }}>{membersReferred}</IonText>
                        </IonLabel>
                    </IonItem>
                    <div style={{ overflowY: 'scroll', height: '250px' }}>
                        {referredToOrganizations?.map((data, idx) => {
                            return (
                                <IonItem key={idx}>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol class='ion-text-start'>
                                                <IonText>{data.organization.name}</IonText>
                                            </IonCol>
                                            <IonCol class='ion-text-end'>
                                                <IonText>{data.membersServed}</IonText>
                                            </IonCol>
                                            <IonCol size='3' class='ion-text-right'>
                                                <IonText style={{ color: '#27AE60' }}>+</IonText>
                                                <IonText>{((data.membersServed / data.totalCount) * 100).toFixed(0)}%</IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                            )
                        })}
                    </div>
                </IonCardContent>
            </IonCard>
        )
    }

    const referralServicesMetrics = (): JSX.Element => {
        return (
            <IonCard className={styles.card} style={{ height: '400px' }}>
                <IonCardContent>
                    <IonCardHeader className={styles.cardHeader}>
                        <IonText>Programming</IonText>
                    </IonCardHeader>
                    <IonItem style={{ paddingTop: '15px' }} lines='none'>
                        <IonLabel slot='start' style={{ color: 'black' }}>
                            <IonText style={{ fontWeight: 'bold', fontSize: '46px' }}>{requestsRecievedCount}</IonText>
                        </IonLabel>
                    </IonItem>
                    <div style={{ overflowY: 'scroll', height: '250px' }}>
                        {requestsRecievedByServiceId?.map((data, idx) => {
                            return (
                                <IonItem key={idx}>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol>
                                                <IonText>{data.at(1)?.name}</IonText>
                                            </IonCol>
                                            <IonCol class='ion-text-end'>
                                                <IonText>{data.at(1)?.count}</IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                            )
                        })}
                    </div>
                </IonCardContent>
            </IonCard>
        )
    }

    return (
        <Page hideTopBar>
            <IonGrid className={styles.pageContainer}>
                <IonRow style={{ marginBottom: '15px' }}>
                    <IonCol>
                        <IonRow>
                            <IonText className={styles.pageTitle}>Service Requests / Dashboard</IonText>
                        </IonRow>
                        <IonRow>
                            <IonText className={styles.pageDesc}>Here's what's happening with your ogranization today.</IonText>
                        </IonRow>
                    </IonCol>
                    <IonCol>
                        <IonRow style={{ marginLeft: '25%' }}>
                            <IonSelect defaultValue={'oneMonth'} placeholder='Last Month' style={{ borderStyle: 'solid' }}>
                                <IonSelectOption value='oneMonth'>Last Month</IonSelectOption>
                            </IonSelect>
                            <IonText style={{ marginLeft: '10px', paddingTop: '15px', fontSize: '12px', color: '#919699' }}>
                                compared to last month reporting {formatDateString()}
                            </IonText>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <MetricsReport />
                <IonRow>
                    <IonCol>{completedRequestMetrics()}</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>{inboundCompletedRequestMetrics()}</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>{recievedRequestMetrics()}</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>{patientsReferredMetrics()}</IonCol>
                    <IonCol>{referralServicesMetrics()}</IonCol>
                    <IonCol>{conversionRatesMetrics()}</IonCol>
                </IonRow>
            </IonGrid>
        </Page>
    )
}

export default MetricsDashboard

/* eslint-disable  */

import React, { useState, useCallback } from 'react'
import { IonButton, IonSpinner } from '@ionic/react'
import 'react-tabs/style/react-tabs.css'
import Table from '../../../table/Table'
import styles from '../../styles.module.scss'
import { ServiceRequest } from '../../../shared/models/ServiceRequest'
import { useServiceRequestReviewModal } from '../../../shared/ServiceRequestReviewModal'
import { adminTableColumns } from '../../utils/adminTableColumns'
import { useNewServiceRequestModal } from '../../../shared/NewServiceRequest'
import { SuccessModal } from '../../../shared/SuccessModal'
import { ErrorModal } from '../../../shared/ErrorModal'
import { useCustomFormRequestModal } from '../../../shared/NewServiceRequest/useCustomFormRequestModal'
import { Error } from '../../../shared/utils/constants'
import { saveSurveyResponse } from '../../../api/useSurvey'
import { useSMSRequestModal } from '../../../shared/NewServiceRequest'

interface IProps {
  serviceRequests: ServiceRequest[]
  isLoading: boolean
  isError: boolean
  error: string
  setError: (err: string) => void
  refetchRequests: () => void
  onSmsModalClose: boolean
  onSelectSMSTranscripts: (serviceRequest: any) => void
}

const TabContent = ({
  serviceRequests,
  isError,
  isLoading,
  error,
  setError,
  refetchRequests,
  onSelectSMSTranscripts,
}: IProps): JSX.Element => {
  const [serviceRequestChosen, setServiceRequestChosen] = useState<
    ServiceRequest | undefined | null
  >(null)
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false)

  // TODO: add search and filter change funcitonality
  const [filterInput, setFilterInput] = useState('')
  const [selectedServiceRequest, setSelectedServiceRequest] = useState()
  const [onCloseSmsModal, setonCloseSmsModal] = useState(false)

  const handleFilterChange = useCallback((e) => {
    const value = e.target.value || undefined
    setFilterInput(value)
  }, [])

  // SMS Request
  const { SMSRequest, show: showSMSRequest } = useSMSRequestModal({
    serviceRequest: selectedServiceRequest,
    onClose: () => {
      setonCloseSmsModal(true), onShowServiceRequestModal(true)
      const serviceRequest = JSON.parse(localStorage.getItem('serviceRequest'))
      setServiceRequestChosen(serviceRequest)
    },
    onSuccess: () => {},
    onCanceled: () => {},
  })

  // New Service Request
  const {
    NewServiceRequest,
    show: showNewServiceRequest,
    isError: isSRError,
  } = useNewServiceRequestModal({
    onSuccess: (patientId) => {
      const survey = JSON.parse(localStorage.getItem('pendingSurvey'))
      if (patientId && survey) {
        survey.userId = patientId
        saveSurveyResponse(survey)
      }
    },
    onError: () => {
      setError(Error.SERVICE_REQUEST_CREATION_FAILED)
    },
  })

  // Custom Form Service Request
  const {
    CustomFormServiceRequest,
    show: showCustomFormServiceRequest,
    hide: hideCustomFormServiceRequest,
  } = useCustomFormRequestModal({
    isPendingModal: true, // if yes, it means we don't have the patient id yet
    onSuccess: () => {
      hideCustomFormServiceRequest()
      setSuccessModalVisible(true)
    },
    onPending: () => {
      hideCustomFormServiceRequest()
      showNewServiceRequest()
    },
    onCanceled: () => setSuccessModalVisible(null),
    onSkip: () => {
      hideCustomFormServiceRequest()
      showNewServiceRequest()
    },
  })

  const onSelectSMSTranscriptsHandler = (serviceRequest: any) => {
    setServiceRequestChosen(null)
    refetchRequests()
    onSelectSMSTranscripts(serviceRequest)
    setSelectedServiceRequest(serviceRequest)
    showSMSRequest()
  }

  const {
    ServiceRequestReviewModal,
    isVisible,
    show: onShowServiceRequestModal,
  } = useServiceRequestReviewModal({
    serviceRequest: serviceRequestChosen,
    onClose: () => {
      setServiceRequestChosen(null)
      refetchRequests()
    },
    onSelectSMSTranscripts: onSelectSMSTranscriptsHandler,
  })

  const handleServiceRequestIdClick = (serviceRequestId: string) => {
    const ticket = serviceRequests?.filter((i) => i.id === serviceRequestId)[0]
    setServiceRequestChosen(ticket)
    console.log(ticket)
  }

  const columns = adminTableColumns({
    onServiceRequestIdClick: handleServiceRequestIdClick,
  })

  const handleDismiss = () => {
    setError('')
    showNewServiceRequest()
  }

  if ((isError || isSRError) && error) {
    return <ErrorModal onDismiss={handleDismiss} text={error} />
  }

  const renderRequests = () => {
    if (isLoading) {
      return (
        <div className={styles.loaderContainer}>
          <IonSpinner className={styles.loader} />
        </div>
      )
    }
    if (isError) {
      return (
        <div className={styles.error}>
          Something went wrong. Please try again later.
        </div>
      )
    }
    return (
      <Table
        data={serviceRequests}
        columns={columns}
        globalFilterInput={filterInput}
        initialState={{ sortBy: [{ id: 'id', desc: true }] }}
      />
    )
  }

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.tableTopRow}>
        <input
          className={styles.searchBar}
          value={filterInput}
          onChange={handleFilterChange}
          placeholder='Search Service Requests...'
        />
        <IonButton
          className={styles.newItemButton}
          onClick={showCustomFormServiceRequest}
        >
          {'+ New Member Referral'}
        </IonButton>
        <SMSRequest />
      </div>
      {renderRequests()}
      <NewServiceRequest />
      <CustomFormServiceRequest />
      {isSuccessModalVisible && (
        <SuccessModal
          onClose={() => setSuccessModalVisible(false)}
          text='The request was created, someone from the CBO or Nonprofit should be in 
          contact with the patient soon.'
        />
      )}
      {serviceRequestChosen && isVisible && <ServiceRequestReviewModal />}
    </div>
  )
}

export default TabContent

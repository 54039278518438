/* eslint-disable  */

import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react'
import Modal from 'react-modal'
import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTextarea,
} from '@ionic/react'
import { mailOutline } from 'ionicons/icons'
import { Controller, FormState, useForm, Validate } from 'react-hook-form'

import styles from './styles.module.scss'
import useOrganizations, {
  useOrganizationsByState,
  useOrganizationsByZip,
} from '../../api/useOrganizations'
import useMultipleOrganizationServices, {
  MappedOrganizationServices,
} from '../../api/useMutlitpleOrganizationServices'
import { getServiceTypeName } from './utils/serviceTypes'

import {
  NewServiceRequestFormValues,
  NewServiceRequestProps,
} from './interfaces'
import classNames from 'classnames'
import { getCurrentUser, getCognitoAttribute } from '../../authentication/auth'
import FileDropzone from '../FileDropzone/FileDropzone'
import { Organization } from '../models/Organization'
import { serviceTypes } from '../utils/constants'
import Accordion from '../../components/accordion/Accordion'
import AccordionListItem from '../../components/accordion/AccordionListItem'
import { SelectEmployee } from '../SelectEmployee'
import { useEmployees } from '../../api/useEmployeesNew'
import useOrganization from '../../api/useOrganization'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { OrganizationServices } from '../models/OrganizationServices'
import { AuthContext } from 'src/contexts/AuthContext'

export function NewServiceRequest({
  onSubmit,
  onCancel,
  patient,
  languages,
  isLoading,
}: NewServiceRequestProps) {
  const [isFileUplaodShow, setIsFileUplaodShow] = useState(false)
  const [file, setFile] = useState<File[]>([])

  const { userAttributes } = useContext(AuthContext)
  const serviceRequestForm = localStorage.getItem('serviceRequestForm')
  const patientForm = serviceRequestForm ? JSON.parse(serviceRequestForm) : null

  const { register, setValue, handleSubmit, formState, control, watch } =
    useForm<NewServiceRequestFormValues>({
      defaultValues: useMemo(
        () => ({
          firstName: patientForm ? patientForm.firstName : patient?.firstName,
          lastName: patientForm ? patientForm.lastName : patient?.lastName,
          email: patientForm ? patientForm.email : patient?.email,
          dateOfBirth: patientForm
            ? (patientForm.dateOfBirth as string)
            : (patient?.dateOfBirth as string),
          phoneNumber: patientForm
            ? patientForm.phoneNumber
            : patient?.phoneNumber,
          zipCode: patientForm ? patientForm.zipCode : patient?.zipCode,
          organizationId: userAttributes.OrgId,
          language: 'English',
          serviceIds: patientForm ? patientForm.serviceIds : [],
          assigneeId: patientForm ? patientForm.assigneeId : '',
          participantId: patientForm ? patientForm.participantId : '',
        }),
        [patient, userAttributes],
      ),
    })
  const [selectedServiceTypes, setSelectedServiceTypes] = useState<any[]>([])
  const [servicesByTypeFilter, setServicesByTypeFilter] = useState<
    OrganizationServices[]
  >([])
  const [chosenServices, setChosenServices] = useState<OrganizationServices[]>(
    [],
  )
  const [chosenOrganizationIds, setChosenOrganizationIds] = useState<number[]>(
    [],
  )

  const compareWith = (o1, o2) => {
    if (!o1 || !o2) {
      return o1 === o2
    }

    if (Array.isArray(o2)) {
      return o2.some((o) => o === o1)
    }

    return o1 === o2
  }

  useEffect(() => {
    register('organizationId', { required: true, value: userAttributes.OrgId })
    register('language', { required: true, value: 'English' })
  }, [userAttributes.OrgId])

  const { data: organization } = useOrganization(userAttributes.OrgId)

  const { data: organizations } = useOrganizationsByZip(
    organization?.zipCode,
    10,
  )
  const orgIds = organizations?.map((x) => x.id)

  const { data: orgServices } = useMultipleOrganizationServices(
    orgIds as number[],
  )

  const { data: employees, isLoading: isEmployeesLoading } = useEmployees(
    Number(userAttributes.OrgId),
    {
      enabled: !!userAttributes.OrgId && !!chosenOrganizationIds.length,
    },
  )

  const dateValidator = useCallback<Validate<string>>((value) => {
    if (!value || value === 'Invalid Date') {
      return 'This is a required field'
    }

    return true
  }, [])

  const languageChoosen = watch('language')
  const handleLanguageChoose = useCallback(
    (e: CustomEvent) => {
      const language = e.detail.value
      setValue('language', language)
    },
    [setValue],
  )

  const handleServiceTypeSelected = (selectedServiceTypes: any[]) => {
    setSelectedServiceTypes(selectedServiceTypes)
    setValue('serviceIds', [])
    if (!!selectedServiceTypes && selectedServiceTypes.length > 0) {
      let chosenServices = orgServices
        ?.flatMap((orgService) => orgService?.services)
        ?.filter((service) =>
          selectedServiceTypes?.map((x) => x?.value).includes(service?.type),
        )
      setServicesByTypeFilter(chosenServices)
      setChosenOrganizationIds(
        chosenServices?.map((x) => x.organizationId) || [],
      )
    }
  }

  const serviceList = serviceTypes.filter((serviceType) =>
    orgServices
      ?.flatMap((orgService) => orgService?.services)
      ?.map((service) => service?.type)
      .includes(serviceType.value),
  )

  const toggleFileUploadShow = () => {
    setIsFileUplaodShow(!isFileUplaodShow)
  }

  const onFileDrop = (files: File[]) => {
    setFile(files)
  }

  const handleSelectEmployee = (selected: any) => {
    setValue('assigneeId', selected)
  }

  const [phone, setPhone] = useState(patient?.phoneNumber)

  const setPhoneNumber = (data: any) => {
    setPhone(data)
  }

  if (!organizations) {
    return null
  }

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={onCancel}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      <div className={styles.wrapper}>
        <h2 className={styles.title}>New Service Request</h2>
        <div className={styles.info}>
          Please fill out the form below to submit the request information.
          Someone from the CBO or Nonprofit should be in contact with the member
          soon. &nbsp;
          <span className={styles.infoHours}>(24 to 48hrs)</span>
        </div>
        <form
          title='form'
          onSubmit={handleSubmit((data) => onSubmit({ ...data, files: file }))}
          noValidate
        >
          <div className={styles.patient}>
            <div className={styles.row}>
              <div className={styles.field}>
                <IonItem
                  disabled={!!patient}
                  className={classNames(
                    { [styles.itemError]: formState.errors.firstName },
                    styles.firstName,
                  )}
                >
                  <IonLabel position='floating'>First Name</IonLabel>
                  <IonInput
                    type='text'
                    title='First Name'
                    autocomplete='off'
                    {...register('firstName', { required: true })}
                  />
                  <IonIcon name='user' slot='end' />
                </IonItem>
                {renderErrorMessage(formState, 'firstName')}
              </div>
              <div className={styles.field}>
                <IonItem
                  disabled={!!patient}
                  className={classNames({
                    [styles.itemError]: formState.errors.lastName,
                  })}
                >
                  <IonLabel position='floating'>Last Name</IonLabel>
                  <IonInput
                    type='text'
                    autocomplete='off'
                    title='Last Name'
                    {...register('lastName', { required: true })}
                  />
                  <IonIcon name='user' slot='end' />
                </IonItem>
                {renderErrorMessage(formState, 'lastName')}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.field}>
                <IonItem
                  disabled={!!patient}
                  className={classNames(
                    { [styles.itemError]: formState.errors.email },
                    styles.email,
                  )}
                >
                  <IonLabel position='floating'>Email</IonLabel>
                  <IonInput
                    type='text'
                    autocomplete='off'
                    {...register('email', { required: false })}
                  />
                  <IonIcon
                    className={styles.standartIcon}
                    icon={mailOutline}
                    slot='end'
                  />
                </IonItem>
                {renderErrorMessage(formState, 'email')}
              </div>
              <div className={classNames(styles.field, styles.language)}>
                <IonItem
                  className={classNames({
                    [styles.itemError]: formState.errors.language,
                  })}
                >
                  <IonLabel position='floating'>Language</IonLabel>
                  <IonSelect
                    value={languageChoosen}
                    defaultValue={languageChoosen}
                    onIonChange={handleLanguageChoose}
                  >
                    {languages.map((language) => (
                      <IonSelectOption
                        key={language.name}
                        value={language.name}
                      >
                        {language.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                {renderErrorMessage(formState, 'language')}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.field}>
                <IonItem
                  disabled={!!patient}
                  className={classNames({
                    [styles.itemError]: formState.errors.dateOfBirth,
                  })}
                >
                  <IonLabel position='stacked'>Member D.O.B.</IonLabel>
                  <IonInput
                    type='date'
                    title='Date of birth'
                    {...register('dateOfBirth', {
                      required: false,
                      validate: dateValidator,
                    })}
                  />
                  <IonIcon name='cake' slot='end' />
                </IonItem>
                {renderErrorMessage(formState, 'dateOfBirth')}
              </div>
              <div className={styles.field}>
                <IonItem
                  disabled={!!patient}
                  className={classNames(
                    { [styles.itemError]: formState.errors.phoneNumber },
                    styles.phone,
                  )}
                >
                  <IonLabel position='stacked'>Phone Number</IonLabel>
                  <PhoneInput
                    country={'US'}
                    defaultCountry='US'
                    international
                    countryCallingCodeEditable={false}
                    displayInitialValueAsLocalNumber={true}
                    autoComplete='tel'
                    title='Phone number'
                    type='tel'
                    placeholder='Enter phone number'
                    value={phone}
                    {...register('phoneNumber', {
                      minLength: 12,
                      setValueAs: (v) => v.replace(/ /g, ''),
                      required: 'Phone Number is required.',
                    })}
                    onChange={setPhoneNumber}
                    limitMaxLength={true}
                    inputExtraProps={{
                      name: 'phoneNumber',
                      required: true,
                      autoFocus: true,
                      country: 'us',
                    }}
                    style={{
                      padding: '10px 0',
                    }}
                  />
                </IonItem>
                {renderPhoneErrorMessage(formState, 'phoneNumber')}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                <IonItem
                  disabled={!!patient}
                  className={classNames({
                    [styles.itemError]: formState.errors.zipCode,
                  })}
                >
                  <IonLabel position='floating'>Member Zip Code</IonLabel>
                  <IonInput
                    type='number'
                    autocomplete='off'
                    {...register('zipCode')}
                  />
                  <IonIcon name='pointOnMap' slot='end' />
                </IonItem>
                {renderErrorMessage(formState, 'zipCode')}
              </div>
            </div>
            <div className={styles.row}>
              <div className={classNames(styles.field, styles.organization)}>
                <IonItem
                  disabled={!!patient}
                  className={classNames({
                    [styles.itemError]: formState.errors.participantId,
                  })}
                >
                  <IonLabel position='stacked'>
                    Participant ID Number (If applicable)
                  </IonLabel>
                  <IonInput
                    type='number'
                    title='Participant Id'
                    autocomplete='off'
                    {...register('participantId', {
                      required: false,
                      minLength: 11,
                      maxLength: 11,
                    })}
                    placeholder='###########'
                  />
                </IonItem>
                {formState.errors.participantId && (
                  <IonText color='danger'>
                    <small>Participant ID number should be 11 digits</small>
                  </IonText>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div className={classNames(styles.field, styles.organization)}>
                <IonItem
                  className={classNames({
                    [styles.itemError]: formState.errors.serviceIds,
                  })}
                >
                  <IonLabel position='floating'>
                    Choose from available Service types.
                  </IonLabel>
                  <IonSelect
                    compareWith={compareWith}
                    onIonChange={(e) =>
                      handleServiceTypeSelected(e.detail.value)
                    }
                    multiple
                  >
                    {serviceList?.map((serviceType: any) => (
                      <IonSelectOption
                        key={serviceType.value}
                        value={serviceType}
                      >
                        {serviceType.label}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                {renderErrorMessage(formState, 'serviceType')}
              </div>
            </div>
            {selectedServiceTypes?.length ? (
              <Accordion>
                {selectedServiceTypes.map((serviceType, idx) => {
                  return (
                    <AccordionListItem
                      idx={idx}
                      header={
                        <>
                          <div className={styles.requiredServicesTitle}>
                            {serviceType.label}
                          </div>
                        </>
                      }
                    >
                      <div className={styles.requiredServices}>
                        <p className={styles.requiredServicesInfo}>
                          Select All of the services needed from the list
                          provided below.
                        </p>
                        <div className={styles.requiredServicesBody}>
                          <div className={styles.requiredServicesColumn}>
                            {servicesByTypeFilter
                              ?.filter(
                                (service) => service.type === serviceType.value,
                              )
                              .map((service, idx) => (
                                <div
                                  key={idx}
                                  className={styles.requiredService}
                                >
                                  <div className={styles.requiredServiceType}>
                                    {service.organization.name}
                                  </div>
                                  <div
                                    key={service.type + service.id + idx}
                                    className={styles.requiredServiceOption}
                                  >
                                    <IonItem
                                      lines='none'
                                      style={{ paddingBottom: '15px' }}
                                    >
                                      <Controller
                                        name='serviceIds'
                                        control={control}
                                        shouldUnregister={true}
                                        rules={{ required: true, minLength: 1 }}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <IonCheckbox
                                            title={String(service.id)}
                                            value={String(service.id)}
                                            checked={(value || []).includes(
                                              service.id,
                                            )}
                                            onIonChange={(e) => {
                                              const isChecked =
                                                e?.detail.checked
                                              setValue(
                                                'serviceIds',
                                                isChecked
                                                  ? [...value, service.id]
                                                  : value.filter(
                                                      (i) => i !== service.id,
                                                    ),
                                              )
                                            }}
                                          />
                                        )}
                                      />
                                      <IonLabel>{service.name}</IonLabel>
                                    </IonItem>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                        {formState?.errors.serviceIds && (
                          <span className='ion-padding-start'>
                            {renderErrorMessage(formState, 'serviceIds')}
                          </span>
                        )}
                      </div>
                    </AccordionListItem>
                  )
                })}
              </Accordion>
            ) : null}
            <div className={styles.additionalNotesWrapper}>
              <IonItem className={styles.additionalNotes}>
                <IonLabel position='floating'>
                  Additional Notes about the Patient (non medical info.)
                </IonLabel>
                <IonTextarea
                  rows={4}
                  placeholder='Enter any important details here...'
                  {...register('description')}
                />
                <IonIcon name='pencil' slot='end' />
              </IonItem>
            </div>
            <div className={styles.fileUplaod}>
              <IonItem>
                <IonCheckbox onIonChange={toggleFileUploadShow} />
                <IonLabel>Upload Member Files:</IonLabel>
              </IonItem>
              {isFileUplaodShow && (
                <FileDropzone
                  acceptedTypes='image/png, image/jpeg, image/jpg, .pdf'
                  onDrop={onFileDrop}
                  maxFiles={1}
                />
              )}
            </div>
            {chosenOrganizationIds.every(
              (id) => id == userAttributes.OrgId,
            ) && (
              <IonRow>
                <IonText className={styles.assignEmployeeTitle}>
                  Assign an employee to this service request:
                </IonText>
                <IonText className={styles.assignEmployeeSubTitle}>
                  From the dropdown below you can select an employee to assign
                  to this new service request or opt to assign later.
                </IonText>

                <IonText className={styles.assignEmployeeSubTitle}>
                  Choose an employee from the dropdown list
                </IonText>
                <SelectEmployee
                  employees={employees}
                  isLoading={isEmployeesLoading}
                  onEmployeeChange={handleSelectEmployee}
                  cssClass={styles.selectEmployee}
                  isAssignLater={true}
                />
              </IonRow>
            )}

            <div className={styles.buttons}>
              <IonButton
                shape='round'
                className={styles.cancelButton}
                onClick={onCancel}
              >
                Cancel
              </IonButton>
              <IonButton
                type='submit'
                shape='round'
                className={styles.submitButton}
                disabled={isLoading}
              >
                {isLoading ? <IonSpinner color='dark' /> : 'Submit and confirm'}
              </IonButton>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

function renderErrorMessage(
  formState: FormState<NewServiceRequestFormValues>,
  name: string,
) {
  if (name in formState?.errors) {
    return (
      <IonText color='danger'>
        <small>This is a required field</small>
      </IonText>
    )
  }

  return null
}

function renderPhoneErrorMessage(
  formState: FormState<NewServiceRequestFormValues>,
  name: string,
) {
  if (name in formState?.errors) {
    return (
      <IonText color='danger'>
        <small>Phone number should be 10 digit</small>
      </IonText>
    )
  }

  return null
}

/* eslint-disable */

import React from 'react'
import {
  IonButton,
  IonIcon,
} from '@ionic/react'
import { CSVLink } from 'react-csv'
import { download } from 'ionicons/icons'
import { ServiceCSVData } from './MetricsReport'
import { OrganizationData } from 'src/shared/models/Organization'

interface MetricsCSVProps {
  csvData: ServiceCSVData[]
  organization: OrganizationData
}

export default function MetricsCSV(props: MetricsCSVProps): JSX.Element {
  const { csvData, organization } = props

  const headers = [
  { label: "Request Type", key: "requestType" },
  { label: "Service Type", key: "serviceType" },
  { label: "Member ID", key: "memberId" },
  { label: "Member Zip Code", key: "memberZipCode" }
]

  return (
    <IonButton>
      <CSVLink data={csvData} headers={headers} filename={`${organization?.name}_metrics_report`}>
      Download CSV
      </CSVLink>
      <IonIcon slot='end' color='white' icon={download} />
    </IonButton>
  )
}
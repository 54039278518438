/* eslint-disable */
export const BASE_API_URL = process.env.REACT_APP_BASE_URL
export const SLACK_API_URL = process.env.REACT_APP_SLACK_URL
export const APP_UNDER_MAINTENANCE = process.env.REACT_APP_UNDER_MAINTENANCE

export const ORGANIZATION = 'ORGANIZATION'
export const PATIENT = 'PATIENT'
export const EMPLOYEE = 'EMPLOYEE'
export const ADMIN = 'ADMIN'

export const ACTIVE = 'ACTIVE'
export const PENDING = 'PENDING'
export const TERMINATED = 'TERMINATED'

/* eslint-disable */

import React from 'react'
import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react'

import { OrganizationData } from '../util'
import { OrganizationForm } from '../../shared/OrganizationForm'

import styles from './styles.module.scss'

interface IProps {
  next: (fields: any) => void
  orgProfile: OrganizationData
  setOrgProfile: any
  error?: string
}

const AddOrganizationProfile = ({
  next,
  orgProfile,
  setOrgProfile,
  error,
}: IProps): JSX.Element => {
  return (
    <IonGrid className='ion-padding-horizontal'>
      <IonRow>
        <IonCol>
          <IonText className={styles.headerText}>
            Tell us about your organization
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonText className={styles.descText}>
            Provide your organization's information below
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow className={styles.ionRowFormContainer}>
        <OrganizationForm
          organization={orgProfile}
          next={next}
          setOrganization={setOrgProfile}
          error={error}
        />
      </IonRow>
    </IonGrid>
  )
}

export default AddOrganizationProfile

/* eslint-disable */
import React from 'react'
import PatientTable from '../patients/PatientTable'
import EmployeeTable from '../employees/EmployeeTable'
import ServiceRequests from '../service-requests'
import MetricsDashboard from '../metrics/MetricsDashboard'
import dashboardIcon from '../public/assets/icons/dashboard.svg'
import employeesIcon from '../public/assets/icons/employees.svg'
import incomingRequestsIcon from '../public/assets/icons/incoming_requests.svg'
import outgoingRequestsIcon from '../public/assets/icons/outgoing_requests.svg'
import messagingIcon from '../public/assets/icons/messaging.svg'
import patientsIcon from '../public/assets/icons/patients.svg'
import formIcon from '../public/assets/icons/forms.svg'
import memberMessagingIcon from '../public/assets/icons/patient_messaging.svg'
import { FormsLibrary } from '../custom-forms'
import MemberMessaging from '../member-messaging/MemberMessaging'
export interface RouteProps {
  title: string
  path: string | string[]
  icon?: string
  component?: React.FunctionComponent
}

export const appRoutes = [
  {
    title: 'Member Management',
    path: '/members',
    icon: patientsIcon,
    component: PatientTable,
  },
  {
    title: 'Employee Management',
    path: '/employees',
    icon: employeesIcon,
    component: EmployeeTable,
  },
  {
    title: 'Outgoing Service Requests',
    path: '/service-requests',
    icon: outgoingRequestsIcon,
    component: ServiceRequests,
  },
  {
    title: 'Incoming Service Requests',
    path: '/incoming-service-requests',
    icon: incomingRequestsIcon,
    component: ServiceRequests,
  },
  {
    title: 'Mass Messaging',
    path: '/mass-messaging',
    icon: messagingIcon,
    component: ServiceRequests,
  },
  {
    title: 'Metrics Dashboard',
    path: '/metrics-dashboard',
    icon: dashboardIcon,
    component: MetricsDashboard,
  },
  {
    title: 'Forms',
    path: '/forms-library',
    icon: formIcon,
    component: FormsLibrary,
  },
  {
    title: 'Member Messaging',
    path: '/member-messaging',
    icon: memberMessagingIcon,
    component: MemberMessaging,
  },
]

export const otherRoutes = [
  // {
  //   title: 'App Preferences',
  //   path: '/preferences',
  //   icon: happyOutline,
  // },
  // {
  //   title: 'Terms of Service',
  //   path: '/terms',
  //   icon: heartOutline,
  // },
]

export default [...appRoutes, ...otherRoutes]

/* eslint-disable  */

import {
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonButton,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonSpinner,
} from '@ionic/react'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { mail } from 'ionicons/icons'
import { useResetPassword } from '../../api/useResetPassword'

type AuthenticationError = {
  code: string
  message: string
  name: string
}

interface IProps {
  email: string
  next: (isSlide: boolean) => void
}
function ResetPassword({ email, next }: IProps): JSX.Element {
  const [error, setError] = useState<null | AuthenticationError>(null)
  const formMethods = useForm({
    defaultValues: { email: email, password: '', token: '' },
  })
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods

  const { mutate: resetPassword, isLoading } = useResetPassword({
    onSuccess: () => {
      // success
      next(true)
    },
    onError: (err) => {
      // error
      setError(err as AuthenticationError)
    },
  })

  const onSubmit = (data: any) => {
    resetPassword({ ...data, email })
  }

  return (
    <IonGrid>
      <IonRow style={{ marginTop: 50 }}>
        <IonCol className='ion-text-start'>
          <IonText id='login-title'>Reset your password</IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className='ion-text-start'>
          <IonText id='login-text'>
            Verification code has been sent. Please check your mailbox.
          </IonText>
        </IonCol>
      </IonRow>
      <FormProvider {...formMethods}>
        <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <IonList>
            <IonItem>
              {errors.email ? (
                <IonLabel position='floating' color='danger'>
                  {errors.email.message}
                </IonLabel>
              ) : (
                <IonLabel position='stacked'>Email</IonLabel>
              )}
              <IonInput
                {...register('email')}
                value={email}
                placeholder='user@email.com'
                type='email'
                name='email'
                disabled={!!email}
              />
              <IonIcon style={{ height: '100%' }} icon={mail} slot='end' />
            </IonItem>
            <IonItem>
              {errors.email ? (
                <IonLabel position='floating' color='danger'>
                  {errors.email.message}
                </IonLabel>
              ) : (
                <IonLabel position='stacked'>Verification Code</IonLabel>
              )}
              <IonInput
                {...register('token', {
                  required:
                    'Verification code is required. Please check your mailbox',
                })}
                placeholder='123456'
                type='text'
                name='token'
              />
            </IonItem>

            <IonItem>
              {errors.email ? (
                <IonLabel position='floating' color='danger'>
                  {errors.email.message}
                </IonLabel>
              ) : (
                <IonLabel position='stacked'>New Password</IonLabel>
              )}
              <IonInput
                {...register('password', {
                  required: 'Password is required.',
                })}
                placeholder='********'
                type='password'
                name='password'
              />
            </IonItem>
          </IonList>

          {error && (
            <IonText color='danger' className='ion-text-center '>
              {error.message}
            </IonText>
          )}

          <IonButton
            shape='round'
            fill='outline'
            expand='full'
            color='dark'
            className='ion-margin-horizontal ion-margin-top'
            type='submit'
          >
            {isLoading ? <IonSpinner name='crescent' color='medium' /> : 'Send'}
          </IonButton>
        </form>
      </FormProvider>
    </IonGrid>
  )
}

export default ResetPassword

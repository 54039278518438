/* eslint-disable  */

import { IonButton, IonIcon } from '@ionic/react'
import { closeSharp } from 'ionicons/icons'
import React from 'react'
import Modal from 'react-modal'

import { ArchiveModalProps } from './interfaces'
import styles from './styles.module.scss'

export function ArchiveModal({
  onDismiss,
  onConfirm,
  text,
  isArchive,
}: ArchiveModalProps) {
  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={onDismiss}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      <div className='display: flex'>
        <h2 className={styles.title}>
          Are you sure you would like to {isArchive} this form?
        </h2>
        <IonIcon
          className={styles.closeCross}
          icon={closeSharp}
          onClick={onDismiss}
        />
      </div>
      <div className={styles.explanatoryText}>{text}</div>
      <div className={styles.buttons}>
        <IonButton
          shape='round'
          className={styles.cancelButton}
          onClick={onDismiss}
        >
          Cancel
        </IonButton>
        <IonButton
          type='submit'
          shape='round'
          className={styles.submitButton}
          onClick={onConfirm}
        >
          Yes, {isArchive} this form
        </IonButton>
      </div>
    </Modal>
  )
}

/* eslint-disable */

import { IonText } from '@ionic/react'
import React from 'react'
import styles from './styles.module.scss'

const terms = [
  {
    details:
      'Health OpX L.L.C. (“OpX”) provides a suite of resources that are designed to aid ' +
      'in improving social determinants of health in providing healthcare. The resources are designed ' +
      'to increase communication capability between an individual and his or her healthcare provider. ' +
      'This Terms of Service Agreement (“Agreement”) will govern your access to and use of the http://www.healthopx.com/ ' +
      'website as well as any other media form, media channel, mobile website or mobile application related, linked or ' +
      'otherwise connected thereto (the “Site”). You agree that when you access or use the Site, you will do so subject ' +
      'to this Agreement. DO NOT ACCESS OR USE THE SITE IF YOU ARE UNWILLING OR UNABLE TO BE BOUND BY THIS AGREEMENT.',
  },
  {
    heading: 'BACKGROUND',
    details:
      'Each individual user of the Site will create a user profile (“User Profile”).' +
      'Each hospital, non-profit, healthcare provider, or entity that uses the Site and has ' +
      'executed a Subscription Agreement (individually a “Partner” and collectively “Partners”) ' +
      'will have a portal within the Site (“Partner Portal”). The Site provides functionality for ' +
      'a Partner via the Partner portal to share and solicit information from patients who have a User Profile. ' +
      'Partners are permitted to upload onto the Site questionnaires, documents, templates, sound recordings, ' +
      'images, videos, and related data (collectively, “Templates”) related to the services that they provide and ' +
      'deliver those Templates to individual users via the Partner Portal. The Templates may be customized and translated ' +
      'for different languages which enable the Partner to gather information to provide healthcare services.  ' +
      'The User Profile is a customizable profile for individuals that aids in the ultimate goal of improving social ' +
      'determinants of health before, during, and after health care services. The User Profile provides a collaborative portal ' +
      'whereby individuals can link to the Partners that they utilize (e.g., their healthcare provider) and transmit data related ' +
      'to the individualized health care services.',
  },
  {
    heading: 'USE OF THE SITE IN GENERAL',
    details:
      'For individual users of the Site, such as individual subscribers to a User Profile, ' +
      'you represent and warrant that you are 18 years old or older, and you recognize and agree that ' +
      'you must be 18 years old or older to be an individual user of the Site. If you are using the User Profile ' +
      'on behalf of a minor child you represent and warrant that you are permitted to do so as the minor’s legal guardian. ' +
      'If you are an entity user of the Site, you acknowledge that you have all necessary approvals and authorizations to ' +
      'enter into this Agreement.',
  },
  {
    heading: 'NO MEDICAL ADVICE',
    details:
      'OpX does not provide any medical advice. Users may be asked information about their health in order to aid ' +
      "the User's applicable Partner in providing medical advice and health care. Users should discuss all medical decisions " +
      'with their physician or health care provider.  OpX is not a health care provider. IF YOU ARE HAVING A MEDICAL EMERGENCY, ' +
      'DIAL 911 IMMEDIATELY.',
  },
  {
    heading: 'ACCOUNT REGISTRATION',
    details:
      'In order to access the services of the Site, you (both individuals and entities) must register to create a User ' +
      'Profile or a Partner Portal (either, an “Account”). You agree to provide accurate, current and complete information during ' +
      'the registration process and to update such information to keep it accurate, current, and complete. OpX reserves the right ' +
      'to suspend or terminate your Account if any information provided during the registration process or thereafter proves to be inaccurate, ' +
      'not current, or incomplete. You will be asked to create a password when you create your Account. OpX does not have access to your ' +
      'passwords and if you forget your password you will be asked to create a new one. You are responsible for safeguarding your password. ' +
      'You agree that you will not disclose your password to any third party and that you will take sole responsibility for any activities ' +
      'or actions under your Account, whether or not you have authorized such activities or actions. You will immediately notify OpX if you ' +
      'become aware of any unauthorized use of your Account.',
  },
  {
    heading: 'PRIVACY',
    details:
      'When you provide information to OpX via the Site or otherwise, you are giving OpX the authority to share anonymized data with third parties.',
  },
  {
    heading: 'HIPPA',
    details:
      'OpX is not a “covered entity” as that term is defined under the Health Insurance Portability and Accountability Act of 1996 and ' +
      'its implementing regulations (“HIPAA”). However, OpX may act as a third party “business associate” on behalf of one or more HIPAA covered entities. ' +
      'Therefore, certain health information that you provide through the Site may be “protected health information” or “PHI” under HIPAA. OpX will ' +
      'protect any PHI as required by HIPAA and applicable state law. OpX will not use or disclose your PHI, except as permitted by HIPAA and applicable state law.',
  },
  {
    heading: 'OWNERSHIP',
    details:
      'The Site, User Profile, and Partner Portal and the content provided by OpX through the same, including trademarks and ' +
      'copyrightable text, are protected by copyright, trademark, and other laws of the United States and foreign countries and are ' +
      'proprietary designations of OpX. You acknowledge and agree that the Site, User Profile, and Partner Portal are the exclusive ' +
      'property of OpX. You will not remove, alter, or obscure any copyright, trademark, service mark, or other proprietary rights ' +
      'incorporated in or accompanying the Site, User Profile, or Partner Portal. As between any user and OpX, all User Profile content ' +
      'containing personally identifiable information is and remains the sole and exclusive property of you as the user; however, personally ' +
      'identification information will be shared with Partners.  OpX will own all data and non-personally identifiable information.',
  },
  {
    heading: 'LICENSE GRANTED BY MEMBER',
    details:
      'By making available any content, including Templates, on or through Site, you hereby grant to OpX a worldwide, irrevocable, ' +
      'perpetual, non-exclusive, transferable, royalty-free, fully-paid up right and license to access, use, share, view, copy, adapt, ' +
      'translate, reformat, and download, such content for the purpose of making such content available to you, other users, and for all ' +
      'other use associated with the Site. OPX CANNOT CONTROL ANY USE OF CONTENT BY ANY PERSON AND YOU ARE SOLELY RESPONSIBLE FOR THE SAME ' +
      'AND ANY LIABILITY FOR SUCH ACCESS.',
  },
  {
    heading: 'LICENSE GRANTED BY OPX',
    details:
      'Subject to your compliance with this Agreement and the separate Subscription Agreement, OpX grants you a limited, non-exclusive, ' +
      'non-transferable license to view, download, and print any content related to Partner Portal or User Profile for your personal and non-commercial ' +
      'purposes. You will not use, adopt, modify, prepare derivative works based upon, distribute, download, license, sell, transfer, publicly display, ' +
      'or otherwise exploit the Site, User Profile, or Partner Portal, except as limited by this Agreement. No licenses or rights are granted to you by ' +
      'implication or otherwise under any intellectual property rights owned or controlled by OpX, except as expressly granted in this Agreement.',
  },
  {
    heading: 'TRANSLATIONS',
    details:
      'OpX makes no representations or warranties related to the language translations provided on the Site, User Profile, or Partner Portal. ' +
      'If a User creates a new Template or submits responses to a Template, that User understands and agrees that the data is transferred to a ' +
      'third party for translation into the appropriate language. Translations of any materials into language other than English are intended to ' +
      'aid in improving social determinants of health. OpX has attempted to provide an accurate translation of the original material in English, ' +
      'but due to the nuances in translating to a foreign language, slight different may exist. OpX is not responsible for any errors in translations. ' +
      'OpX shall own all translations.',
  },
  {
    heading: 'GENERAL PROHIBITIONS',
    details: 'You agree that you will not:',
    list: [
      'Impersonate or misrepresent your affiliation with any person or entity;',
      'Violate any law;',
      "Post, upload, publish, submit, or transmit any content that: (i) infringes, misappropriates, or violates a third party's privacy or any rights of publicity, or any patent, copyright, trademark or other intellectual property rights, (ii) violates, or encourages any conduct that would violate, any law or give rise to civil liability, (iii) is fraudulent, false, misleading or deceptive, (iv) is defamatory, obscene, vulgar or offensive, (v) promotes discrimination, bigotry, racism, hatred, harassment or harm against any person, or (vi) is violent or promotes violence;",
      "Use or display any individual element within the Site, User Profile or Partner Portal without OpX's express written consent;",
      'Attempt to probe, scan or test the vulnerability of any OpX system or network or breach any security or authentication measures;',
      'Use the Site, User Profile or Partner Portal to send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam or other form of solicitation;',
      'Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Site, User Profile or Partner Portal.',
    ],
  },
  {
    heading: 'TERMINATION AND ACCOUNT CANCELLATION',
    details:
      'If you breach this Agreement, OpX will have the right to suspend or disable your Account or terminate this Agreement and disable ' +
      'access to User Profile or Partner Portal, as applicable. Additionally, OpX reserves the right to revoke your access to and use of the Site, ' +
      'User Profile, and Partner Portal at any time, with or without cause.',
  },
  {
    heading: 'DISCLAIMERS',
    details:
      'THE SITE, USER PROFILE, AND PARTNER PORTAL ARE PROVIDED ‘AS IS’ WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. ' +
      'WITHOUT LIMITING THE FOREGOING, OPX EXPLICITLY DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ' +
      'ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.  OPX MAKES NO WARRANTY THAT THE SITE, ' +
      'USER PROFILE, OR PARTNER PORTAL WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. OPX MAKES NO ' +
      'WARRANTY REGARDING THE QUALITY OF ANY SERVICES OR CONTENT. PROFILE CONTENT IS PROVIDED SOLELY BY MEMBERS AND OPX HAS NO RESPONSIBILITY OR ' +
      'LIABILITY FOR THE TRUTHFULNESS OR ACCURACY OF THE SAME. NO ADVICE OR INFORMATION, INCLUDING ANY HEALTH CARE INSIGHTS, WILL CREATE ANY WARRANTY ' +
      'NOT EXPRESSLY MADE IN THIS AGREEMENT.',
  },
  {
    heading: 'INDEMNITY',
    details:
      'YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS OPX, ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS, FROM AND AGAINST ANY CLAIMS, LIABILITIES, ' +
      'DAMAGES, LOSSES, AND EXPENSES, INCLUDING, WITHOUT LIMITATION, REASONABLE LEGAL AND ACCOUNTING FEES, ARISING OUT OF OR IN ANY WAY CONNECTED WITH: (i) ' +
      'YOUR ACCESS TO OR USE OF THE SITE, USER PROFILE, OR PARTNER PORTAL, AS APPLICABLE, (ii) ANY USE OF THE SITE, USER PROFILE, OR PARTNER PORTAL FOR ANY ' +
      'UNLAWFUL OR INAPPROPRIATE CONDUCT, OR (iii) ANY ACT OR OMISSION BY YOU THAT CONSTITUTES AN ACTUAL OR ALLEGED VIOLATION OF THESE TERMS.',
  },
  {
    heading: 'LIMITATION OF LIABILITY',
    details:
      'YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE SITE, USER PROFILE, ' +
      'PARTNER PORTAL OR OTHER SERVICE, AS APPLICABLE, REMAINS WITH YOU.  NEITHER OPX NOR ANY OTHER PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SITE, ' +
      'USER PROFILE, PARTNER PORTAL OR OTHER SERVICE WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR ' +
      'LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL ' +
      'DISTRESS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SITE, USER PROFILE, PARTNER PORTAL OR OTHER SERVICE, WHETHER BASED ' +
      'ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY. IN NO EVENT WILL OpX’S AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT OR FROM THE USE OF ' +
      'OR INABILITY TO USE THE SITE, USER PROFILE, PARTNER PORTAL OR OTHER SERVICE EXCEED THE AMOUNTS YOU HAVE PAID TO OPX FOR USE OF THE SAME, IF YOU HAVE MADE ANY PAYMENTS TO OPX ' +
      'FOR USE OF THE SAME OR $100, WHICHEVER IS GREATER. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THIS AGREEMENT.  ',
  },
  {
    heading: 'CONTROLLING LAW AND JURISDICTION',
    details:
      'This Agreement and any action related thereto will be governed by the laws of the State of Michigan without regard to its conflict of law provisions. ' +
      'Venue for any dispute related to this Agreement, the Site, User Profile or Partner Portal will be in Ingham County, Michigan Circuit Court or the Federal U.S. ' +
      'District Court for the Western District of Michigan.',
  },
  {
    heading: 'ENTIRE AGREEMENT',
    details:
      'This Agreement and the Subscription Agreement (with respect to Partners) constitutes the entire and exclusive understanding and agreement between ' +
      'OpX and you regarding the Site, Partner Portal, and User Profile, and this Agreement supersedes and replaces any and all prior oral or written understandings ' +
      'or agreements between OpX and you regarding the same. There are no third party beneficiaries.',
  },
  {
    heading: 'ASSIGNMENT',
    details:
      "You may not assign or transfer this Agreement, by operation of law or otherwise, without OpX's prior written consent. Any attempt by you to assign or transfer this Agreement, " +
      'without such consent, will be null and void. OpX may transfer or assign this Agreement at its sole discretion.',
  },
  {
    heading: 'NO WAIVER',
    details:
      'The failure of OpX to enforce any right or provision of this Agreement will not constitute a waiver of future enforcement of that right or provision. ' +
      'The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of OpX.',
  },
]

function TermsAndConditions(): JSX.Element {
  const getHeading = (heading?: string) => {
    if (heading) {
      return (
        <div style={{ textDecoration: 'underline' }}>
          <IonText>{heading}</IonText>
        </div>
      )
    }
  }

  const getList = (list?: string[]) => {
    if (list) {
      return (
        <ul>
          {list.map((item, idx) => {
            return (
              <li key={item + idx}>
                <IonText>● {item}</IonText>
              </li>
            )
          })}
        </ul>
      )
    }
  }

  return (
    <div className={styles.termsText}>
      {terms.map(
        (term: { details: string; heading?: string; list?: string[] }) => {
          return (
            <div style={{ marginBottom: '25px' }}>
              {getHeading(term.heading)}
              <div>
                <IonText>{term.details}</IonText>
              </div>
              {getList(term.list)}
            </div>
          )
        },
      )}
    </div>
  )
}

export default TermsAndConditions

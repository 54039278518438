/* eslint-disable */
import React from 'react'
import {
  IonButton,
  IonIcon,
} from '@ionic/react'
import { download } from 'ionicons/icons'
import jsPDF from 'jspdf'
import _ from 'lodash'
import { OrganizationData } from 'src/shared/models/Organization'
import { ServicePDFData } from './MetricsReport'

interface MetricsPDFProps {
  outServiceData: ServicePDFData[]
  inServiceData: ServicePDFData[]
  organization: OrganizationData
}

export default function MetricsPDF(props: MetricsPDFProps): JSX.Element {
  const { outServiceData, inServiceData, organization } = props

  const doc = new jsPDF('p', 'pt')
  const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight()
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth()

  const updateY = (y: number): number => {
    let pageInfo = doc.getCurrentPageInfo()
    const startingPage = pageInfo.pageNumber
    if (startingPage === 1) {
      doc.line(290, 175, 290, pageHeight - 40)
    } else {
      doc.line(290, 20, 290, pageHeight - 40)
    }
    if (y > (pageHeight - 40)) {
        y = 60
        doc.addPage()
      }
    y += 20
    pageInfo = doc.getCurrentPageInfo()
    const nextPage = pageInfo.pageNumber
    if (nextPage - startingPage > 1) {
      doc.setPage(startingPage + 1)
    }
    return y
  }

  const generateText = (data: ServicePDFData[], startX: number, startY: number): void => {
    let x = startX
    let y = startY
    data.map(svc => {
      x = startX
      y = updateY(y)

      doc.text(`${svc.type}`, x, y)
      y = updateY(y)
      x += 10

      doc.text(`${svc.membersServed} members served overall`, x, y)
      y = updateY(y)

      doc.text(`Zip Code Breakdown:`, x, y)
      x += 10
      svc.zipCodes.map(code => {
        y = updateY(y)
        doc.text(`${code.zipCode}: ${code.membersServed} members served`, x, y)
      })

      y = updateY(y)
    })
  }

  const generateFile = () => {
      
    doc.setFont('helvetica', 'bold')
    doc.text('HealthOpX Service Metrics Report', pageWidth / 2, 30, { align: 'center' })
    doc.setFont('helvetica', 'normal')
    doc.text(`${organization.name}`, 20, 80)
    doc.text(`${organization.addressLine1} ${organization.city}, ${organization.addressLine2}, ${organization.zipCode}`, 20, 100)
    doc.text(`${organization.description}`, 20, 120)
    doc.text(`${organization.name} impacted the communities they served in the following ways:`, 20, 160)

    doc.setFont('helvetica', 'bold')
    doc.text(`Outgoing Requests`, 20, 190)
    doc.setFont('helvetica', 'normal')
    generateText(outServiceData, 20, 200)
    doc.setPage(1)
    doc.setFont('helvetica', 'bold')
    doc.text(`Incoming Requests`, 300, 190)
    doc.setFont('helvetica', 'normal')
    generateText(inServiceData, 300, 200)

    doc.save(`${organization.name}_metrics_report.pdf`)
  }
 
  return (
    <IonButton onClick={generateFile}>
      Download PDF
      <IonIcon slot='end' color='white' icon={download} />
    </IonButton>
  )
}
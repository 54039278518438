/* eslint-disable */

import React from 'react'
import styles from '../styles.module.scss'

const StepContent = ({
  isStepDone,
  children,
}: {
  isStepDone: boolean
  children: any
}): JSX.Element => {
  return (
    <div className={styles.content}>
      <div className={styles.stepContent}>
        {isStepDone && <span className={styles.stepLine} />}
        {children}
      </div>
    </div>
  )
}

export default StepContent

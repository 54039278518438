/* eslint-disable */
import { BASE_API_URL } from './constants'
import { useQuery } from 'react-query'
import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { PatientMessaging } from '../shared/models/PatientMessaging'

const getPatientMessagingRequests = async ({ queryKey }: any) => {
  const [_key, { organizationId, userId }] = queryKey
  return await fetch(
    `${BASE_API_URL}/v1/organizations/${organizationId}/custom-messages`,
    {
      method: 'GET',
    },
  ).then((response) => response.json())
}

export function usePatientMessagingByOrganizationRequests(
  organizationId: string | number,
) {
  const { userId } = useContext(AuthContext)

  return useQuery<PatientMessaging[]>(
    getOrganizationPatientMessagingRequestsQueryKey(userId, organizationId),
    getPatientMessagingRequests,
  )
}

export function getOrganizationPatientMessagingRequestsQueryKey(
  userId: string | number,
  organizationId: string | number,
): any[] {
  return [`${organizationId}-patient-messaging`, { organizationId, userId }]
}

export const createPatientMessaging = async (data: any) => {
  const res = await fetch(
    `${BASE_API_URL}/v2/organizations/${data.organizationId}/custom-messages`,
    {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  if (!res.ok) {
    throw new Error('Something went wrong! Please try again')
  }
  return res.json()
}

export const updatePatientMessaging = async (data: any) => {
  const res = await fetch(
    `${BASE_API_URL}/v2/organizations/${data.organizationId}/custom-messages`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  if (!res.ok) {
    throw new Error('Something went wrong! Please try again')
  }
  return res.json()
}

/* eslint-disable */
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '../contexts/AuthContext'
import { BASE_API_URL } from './constants'

const getOrganizations = async ({ queryKey }) => {
  const [_key, { userId }] = queryKey
  return await fetch(`${BASE_API_URL}/v1/organizations`, {
    headers: { userId: userId },
  }).then((response) => response.json())
}

export default function useOrganizations() {
  const { userId } = useContext(AuthContext)
  return useQuery(['organizations', { userId }], getOrganizations)
}

const getOrganizationsByState = async ({ queryKey }) => {
  const [_key, { userId, state }] = queryKey
  return await fetch(
    `${BASE_API_URL}/v1/organizations/byState?state=${state}`,
    { headers: { userId: userId } },
  ).then((response) => response.json())
}

export function useOrganizationsByState(state) {
  const { userId } = useContext(AuthContext)

  return useQuery(['organizations', { userId, state }], getOrganizationsByState)
}

const getOrganizationsByZip = async ({ queryKey }) => {
  const [_key, { userId, zipCode, radius }] = queryKey
  return await fetch(
    `${BASE_API_URL}/v1/organizations?zipCode=${zipCode}&radius=${radius}`,
    { headers: { userId: userId } },
  ).then((response) => response.json())
}

export function useOrganizationsByZip(zipCode, radius) {
  const { userId } = useContext(AuthContext)

  return useQuery(
    ['organizations', { userId, zipCode, radius }],
    getOrganizationsByZip,
  )
}

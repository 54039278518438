/* eslint-disable */
import { useContext } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { AuthContext } from '../contexts/AuthContext'
import { OrganizationServices } from '../shared/models/OrganizationServices'
import { BASE_API_URL } from './constants'

export interface MappedOrganizationServices {
  organizationName: string
  services: OrganizationServices[]
}

const getMultipleOrganizationServices = async ({ queryKey }) => {
  const [_key, { organizationIds, userId }] = queryKey
  let promises = []
  for (let i = 0; i < organizationIds?.length; i++) {
    promises.push(
      `${BASE_API_URL}/v1/organizations/${organizationIds[i]}/services`,
    )
  }

  return Promise.all(
    promises.map((promise) =>
      fetch(promise, {
        method: 'GET',
        headers: {
          userId: userId,
        },
      }).then((response) => response.json()),
    ),
  ).then((data) => {
    const mappedServices = [] as MappedOrganizationServices[]
    data.map((orgServices: OrganizationServices[]) => {
      if (orgServices.length) {
        mappedServices.push({
          organizationName: orgServices[0].organization.name,
          services: orgServices,
        })
      }
    })
    return mappedServices
  })
}

export default function useMultipleOrganizationServices(
  organizationIds: number[],
): UseQueryResult<MappedOrganizationServices[], unknown> {
  const { userId } = useContext(AuthContext)

  return useQuery(
    [`multiple-organization-services`, { organizationIds, userId }],
    getMultipleOrganizationServices,
  )
}

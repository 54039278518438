/* eslint-disable  */

import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import styles from './styles.module.scss'

import { SMSRequestProps } from './interfaces'
import { closeSharp, personCircleOutline } from 'ionicons/icons'
import {
  getServiceRequestSMSTranscripts,
  getServiceRequestSMSTranscriptsCSV,
} from '../../api/useSMSTranscripts'
import { CSVLink } from 'react-csv'

export function SMSRequest({ serviceRequest, onClose }: SMSRequestProps) {
  const [allSMS, setAllSMS] = useState([])
  const [data, setData] = useState([])
  const transcriptHeaders = [
    'SMS ID',
    'RECIPIENT TYPE',
    'FIRST NAME',
    'PHONE NUMBER',
    'SERVICE TYPE',
    'SERVICE REQUEST ID',
  ]

  useEffect(() => {
    getServiceRequestSMSTranscripts(serviceRequest?.id).then((data: any) => {
      setAllSMS(data)
    })
    getServiceRequestSMSTranscriptsCSV(serviceRequest?.id).then((data: any) => {
      setData(data)
    })
  }, [])

  const convertISOStringToMonthDay = (date: string) => {
    const tempDate = new Date(date)
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return tempDate.toLocaleDateString(undefined, options)
  }

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={onClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      <IonIcon
        className={styles.closeCross}
        icon={closeSharp}
        onClick={onClose}
      />
      <div className={styles.wrapper}>
        <IonButton size='small' className={styles.csvExportButton}>
          <CSVLink
            data={data}
            headers={transcriptHeaders}
            filename={`SMS Transcripts.csv`}
          >
            Export to CSV
          </CSVLink>
        </IonButton>
        <h2 className={styles.smsTitle}>
          {serviceRequest.patient.firstName} {serviceRequest.patient.lastName} -
          Messaging Transcript
        </h2>
        <div className={styles.smsWrapper}>
          {allSMS?.map((smsTranscript) => (
            <IonGrid key={smsTranscript.id}>
              <IonRow>
                <IonCol size='1'>
                  <IonIcon
                    className={styles.profileImage}
                    icon={personCircleOutline}
                    onClick={onClose}
                  />
                </IonCol>
                <IonCol size='9'>
                  <div className={styles.smsText}>{smsTranscript.smsText}</div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='10'>
                  <div className={styles.smsTimeStamp}>
                    {smsTranscript.recipient?.firstName}{' '}
                    {smsTranscript.recipient?.lastName}{' '}
                    {convertISOStringToMonthDay(smsTranscript.createTimestamp)}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          ))}
        </div>
      </div>
    </Modal>
  )
}

/* eslint-disable  */

import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  FunctionComponent,
  useContext,
} from 'react'
import { IonBackdrop } from '@ionic/react'
import { RouteProps } from 'react-router-dom'

import useUser from '../api/useUser'
import useUserSurveys from '../api/useUserSurveys'
import useUserServiceRequests from '../api/useUserServiceRequests'

import {
  getUserServiceRequestsQueryKey,
  updateUserServiceRequestInCache,
} from '../api/useUserServiceRequests'
import {
  getServiceRequestNotesQueryKey,
  addServiceRequestNoteInCache,
} from '../api/useServiceRequestNotes'

import Page from '../page/Page'
import { PatientSurveys } from './components/PatientSurveys'
import { PatientServiceRequests } from './components/PatientServiceRequests'
import {
  useCustomFormRequestModal,
  useNewServiceRequestModal,
  useSMSRequestModal,
} from '../shared/NewServiceRequest'
import { SuccessModal } from '../shared/SuccessModal'

import styles from './styles.module.scss'
import { useServiceRequestReviewModal } from '../shared/ServiceRequestReviewModal'
import { ServiceRequest } from '../shared/models/ServiceRequest'
import { useEditUserModal } from '../shared/EditUserModal'
import { UserInfo } from '../shared/UserInfo'
import { UserType } from '../shared/models/UserType'
import useSurveysByOrganizationRequests, {
  saveSurveyResponse,
} from '../api/useSurvey'
import { getCognitoAttribute, getCurrentUser } from '../authentication/auth'
import { useFormAnswersRequestModal } from '../shared/FormAnswersModal/useFormAnswersRequestModal'
import { useQueryClient } from 'react-query'
import { Error } from '../shared/utils/constants'
import { ErrorModal } from '../shared/ErrorModal'
import { User } from '../shared/models/User'
import { usePatientSMSModal } from './usePatientSMSModal'
import { AuthContext } from '../contexts/AuthContext'
import { PatientFiles } from './components/PatientFiles/PatientFiles'
import { getUserDocuments, uploadFiles } from '../api/useUserDocuments'
import { useUploadFileModal } from './components/UploadFilesModal/useUploadFilesModal'

const PatientProfile: FunctionComponent<RouteProps> = (props: RouteProps) => {
  // @ts-ignore
  const userId = props.match.params.id
  const queryClient = useQueryClient()

  const { userId: currentUserId } = useContext(AuthContext)
  const [error, setError] = useState('')
  const [onCloseSmsModal, setonCloseSmsModal] = useState(false)
  const [userDocuments, setUserDocumnts] = useState([])

  const { data: patient } = useUser(userId)
  // TODO: remove after data mutation is complete
  useEffect(() => {
    console.log('patient', patient)
  }, [patient])

  const { data: patientSurveys } = useUserSurveys(userId)
  // TODO: remove after data mutation is complete
  useEffect(() => {
    console.log('patientSurveys', patientSurveys)
  }, [patientSurveys])
  
  // Patient Service Requests
  const { data: patientServiceRequests } = useUserServiceRequests(
    userId,
    currentUserId,
  )
  const getBindedPatientServiceRequestsQueryKey = useMemo(
    () => getUserServiceRequestsQueryKey.bind(null, userId, currentUserId),
    [userId],
  )
  // TODO: remove after data mutation is complete
  useEffect(() => {
    console.log('patientServiceRequests', patientServiceRequests)
  }, [patientServiceRequests])
  // Patient Surveys
  const [
    isAssignNewSurveyPopupVisible,
    setAssignNewSurveyPopupVisible,
  ] = useState(false)
  const handleAssignNewSurveyClick = useCallback(() => {
    setAssignNewSurveyPopupVisible(true)
  }, [])

  const handleSurveyView = useCallback((id) => {
    localStorage.setItem('answersFormId', id)
    showFormAnswersModal()
  }, [])
  
  // Patient Documents
  useEffect(() => {
    const fetchUserDocuments = async (userId: number) => await getUserDocuments(userId)
    .then((res) => { return setUserDocumnts(res); })
    .catch((err) => { 
      console.log(err.message); 
      return setUserDocumnts([]); 
    });
    fetchUserDocuments(userId)
  }, [userId])

  const [selectedServiceRequest, setSelectedServiceRequest] = useState()

  // Success Modal
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false)
  const handleSuccessModalClose = useCallback(() => {
    setSuccessModalVisible(false)
  }, [])

  // New Service Request
  const handleNewServiceRequestSuccess = useCallback(() => {
    setSuccessModalVisible(true)
  }, [])
  const {
    NewServiceRequest,
    show: showNewServiceRequest,
    isVisible: isNewServiceRequestPopupVisible,
    isError: isSRError,
  } = useNewServiceRequestModal({
    patientId: +userId,
    onSuccess: (patientId) => {
      const survey = JSON.parse(localStorage.getItem('pendingSurvey'))
      if (patientId && survey) {
        survey.userId = patientId
        saveSurveyResponse(survey).then(() => {
          queryClient.invalidateQueries(`user-${userId}-surveys`)
        })
      }
    },
    onError: () => setError(Error.SERVICE_REQUEST_CREATION_FAILED),
    patient: patient,
  })

  const { PatientSMSModal, show: showPatientSMSModal } = usePatientSMSModal({
    patient,
  })
  // Service Request Preview
  const [
    serviceRequestChosen,
    setServiceRequestChosen,
  ] = useState<ServiceRequest | null>(null)
  const handleServiceRequestPreviewClose = useCallback(() => {
    setServiceRequestChosen(null)
  }, [setServiceRequestChosen])

  const handleServiceRequestViewClick = useCallback(
    (id) => {
      setServiceRequestChosen(
        (patientServiceRequests as ServiceRequest[]).find((i) => i.id === id) ||
          null,
      )
    },
    [setServiceRequestChosen, patientServiceRequests],
  )

  // SMS Request
  const { SMSRequest, show: showSMSRequest } = useSMSRequestModal({
    serviceRequest: selectedServiceRequest,
    onClose: () => {
      setonCloseSmsModal(true), onShowServiceRequestModal(true)
      const serviceRequest = JSON.parse(localStorage.getItem('serviceRequest'))
      setServiceRequestChosen(serviceRequest)
    },
    onSuccess: () => {},
    onCanceled: () => {},
  })

  const onSelectSMSTranscriptsHandler = (serviceRequest: any) => {
    setServiceRequestChosen(null)
    setSelectedServiceRequest(serviceRequest)
    showSMSRequest()
  }

  const getBindedServiceRequestNotesQueryKey = useMemo(
    () =>
      getServiceRequestNotesQueryKey.bind(
        null,
        currentUserId,
        serviceRequestChosen?.id || NaN,
      ),
    [currentUserId, serviceRequestChosen?.id],
  )

  const {
    ServiceRequestReviewModal,
    isVisible: isServiceRequestReviewModalVisible,
    show: onShowServiceRequestModal,
  } = useServiceRequestReviewModal({
    serviceRequest: serviceRequestChosen,
    onClose: handleServiceRequestPreviewClose,

    getQueryKeyForNotes: getBindedServiceRequestNotesQueryKey,
    addServiceRequestNoteInCache: addServiceRequestNoteInCache,

    getQueryKeyForServiceRequests: getBindedPatientServiceRequestsQueryKey,
    updateServiceRequestInCache: updateUserServiceRequestInCache,
    onSelectSMSTranscripts: onSelectSMSTranscriptsHandler,
  })

  /// Patient edit
  const [isPatientEditing, setIsPatientEditing] = useState(false)
  const handlePatientEditClick = useCallback(() => {
    setIsPatientEditing(true)
  }, [setIsPatientEditing])
  // * Cancel
  const handleUserEditCancel = useCallback(() => {
    setIsPatientEditing(false)
  }, [setIsPatientEditing])
  // * Success
  const handleUserEditSuccess = useCallback(() => {
    setIsPatientEditing(false)
  }, [setIsPatientEditing])
  // * Error
  const handleUserEditError = useCallback(() => {
    alert('Something went wrong during updating user data')
    setIsPatientEditing(false)
  }, [setIsPatientEditing])
  const { EditUserModal, isVisible: isEditUserModalVisible } = useEditUserModal(
    {
      userId: userId,
      onSuccess: handleUserEditSuccess,
      onCancel: handleUserEditCancel,
      onError: handleUserEditError,
    },
  )

  // File Upload
  const [isFilesUploading, setIsFileUploading] = useState(false)
  const handleUploadFileButtonClick = useCallback(() => {
    showImport()
  }, [])
  const handleUploadFileModalSuccess = useCallback(() => {
    setIsFileUploading(false)
  }, [setIsFileUploading])
  const handleUploadFileModalError = useCallback(() => {
    setIsFileUploading(false)
  }, [setIsFileUploading])

  const {
    UploadFilesModal,
    isVisible: isUploadFilesModalVisible,
    show: showImport,
  } = useUploadFileModal(handleUploadFileModalSuccess, handleUploadFileModalError, userId)

  const [currentUserAttributes, setCurrentUserAttributes] = useState([])

  useEffect(() => {
    getCurrentUser((attributes: any) => {
      setCurrentUserAttributes(attributes)
    })
  }, [getCurrentUser])

  const loggedInUserOrgId = useMemo(() => {
    return getCognitoAttribute(currentUserAttributes, 'custom:organizationId')
  }, [currentUserAttributes])

  const { data: allSurveys } = useSurveysByOrganizationRequests(
    currentUserId,
    loggedInUserOrgId,
  )

  // Custom Form Service Request
  const {
    CustomFormServiceRequest,
    show: showCustomFormServiceRequest,
    hide: hideCustomFormServiceRequest,
  } = useCustomFormRequestModal({
    isPendingModal: true, // if yes, it means we don't have the patient id yet
    onSuccess: () => {
      hideCustomFormServiceRequest()
      setSuccessModalVisible(true)
    },
    onPending: () => {
      hideCustomFormServiceRequest()
      showNewServiceRequest()
    },
    onCanceled: () => setSuccessModalVisible(null),
    onSkip: () => {
      hideCustomFormServiceRequest()
      showNewServiceRequest()
    },
  })

  // Standalone Form Service Request
  const {
    StandaloneCustomFormServiceRequest,
    show: showStandaloneFormRequest,
    hide: hideStandaloneFormRequest,
  } = useCustomFormRequestModal({
    isStandalone: true,
    onSuccess: () => {
      hideCustomFormServiceRequest()
      queryClient.invalidateQueries(`user-${userId}-surveys`)
    },
    onPending: () => {
      hideStandaloneFormRequest()
    },
    onCanceled: () => setSuccessModalVisible(null),
    onSkip: () => {
      hideCustomFormServiceRequest()
      showNewServiceRequest()
    },
  })

  const handleNewCustomForm = () => {
    localStorage.setItem('currentPatientId', userId)
    showStandaloneFormRequest()
  }

  // Form Answers Modal
  const {
    FormAnswersModal,
    show: showFormAnswersModal,
    isVisible: isFormAnswersModalVisible,
  } = useFormAnswersRequestModal({
    onCanceled: () => {},
    surveys: patientSurveys,
  })

  const areSomePopupOpen =
    isNewServiceRequestPopupVisible ||
    isAssignNewSurveyPopupVisible ||
    isSuccessModalVisible ||
    isFormAnswersModalVisible ||
    isPatientEditing ||
    isUploadFilesModalVisible ||
    error

  return (
    <>
      {areSomePopupOpen && <IonBackdrop className={styles.backdrop} />}
      <Page name='Member Profile'>
        <div className={styles.page}>
          <UserInfo
            userType={UserType.PATIENT}
            user={patient as User}
            onEdit={handlePatientEditClick}
            patientSMSTranscripts={showPatientSMSModal}
          />
          <div className={styles.tablesWrapper}>
            <div className={styles.serviceRequestListWrapper}>
              <PatientServiceRequests
                serviceRequests={patientServiceRequests}
                onNewServiceRequestClick={showCustomFormServiceRequest}
                onServiceRequestView={handleServiceRequestViewClick}
                onSelectSMSTranscripts={onSelectSMSTranscriptsHandler}
              />
            </div>
            <div className={styles.patientSurveysWrapper}>
              <PatientSurveys
                surveys={patientSurveys}
                allSurveys={allSurveys}
                onAssignNewSurveyClick={() => handleNewCustomForm()}
                onSurveyView={handleSurveyView}
              />
            </div>
            <div className={styles.fileListWrapper}>
              <PatientFiles
                  files={userDocuments} 
                  onUploadFileClick={handleUploadFileButtonClick}
                  // onFileDownload={}           
              />
            </div>
          </div>
        </div>
      </Page>
      <PatientSMSModal />
      <NewServiceRequest />
      <FormAnswersModal />
      <UploadFilesModal/>
      <CustomFormServiceRequest />
      <StandaloneCustomFormServiceRequest />
      <SMSRequest />
      {isSuccessModalVisible && (
        <SuccessModal
          onClose={handleSuccessModalClose}
          text='The request was created, someone from the CBO or Nonprofit should be in
        contact with the member soon.'
        />
      )}
      {serviceRequestChosen && isServiceRequestReviewModalVisible && <ServiceRequestReviewModal />}
      {isPatientEditing && <EditUserModal />}
      {isSRError && error && (
        <ErrorModal
          onDismiss={() => {
            setError('')
            showNewServiceRequest()
          }}
          text={error}
        />
      )}
    </>
  )
}

export default PatientProfile

/* eslint-disable */
import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'

import App from './App'
import * as serviceWorker from './serviceWorker'
import 'tailwindcss/dist/tailwind.min.css'
import './login/login.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthProvider } from './contexts/AuthContext'
import './fetchIntercept'
import { BrowserRouter } from 'react-router-dom'
const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </QueryClientProvider>,
  document.getElementById('root'),
  () => {
    Modal.setAppElement(document.querySelector('ion-app') || document.body)
  },
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

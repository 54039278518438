/* eslint-disable */

import React, { useContext } from 'react'
import { IonApp, IonContent, IonPage } from '@ionic/react'
import 'react-notifications-component/dist/theme.css'

import SideMenu from './sidemenu/SideMenu'
import './styles/app.css'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

import WebRoutes from './main/WebRoutes'
import { AuthContext } from './contexts/AuthContext'
import ErrorBoundary from './error-boundary/ErrorBoundary'
import HelpFab from './help-fab/HelpFab'
import { APP_UNDER_MAINTENANCE } from './api/constants'
import MaintenancePage from './maintenance/MaintenancePage'
import { ReactNotifications } from 'react-notifications-component'

/* Theme variables */
// TODO: edit ion varables for hox styling
// import './theme/variables.css'

function App(): JSX.Element {
  const { isLoggedIn } = useContext(AuthContext)

  if (APP_UNDER_MAINTENANCE === 'true') {
    return (
      <IonApp className='app'>
        <IonPage>
          <MaintenancePage />
        </IonPage>
      </IonApp>
    )
  }

  return (
    <ErrorBoundary>
      <IonApp className='app'>
        <IonContent fullscreen>
          {isLoggedIn && (
            <>
              <div style={{ display: 'flex' }}>
                <SideMenu />
                <WebRoutes />
                <div style={{ position: 'fixed' }}>
                  <HelpFab />
                </div>
              </div>
            </>
          )}
          {!isLoggedIn && (
            <>
              <WebRoutes />
            </>
          )}
        </IonContent>
        <ReactNotifications />
      </IonApp>
    </ErrorBoundary>
  )
}

export default App

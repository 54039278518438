/* eslint-disable  */

import React from 'react'
import { ServiceRequestStatusText } from '../../shared/models/ServiceRequestStatuses'
import { formatTimestampToShortDate } from '../../shared/utils/formatTimestamp'

import styles from './styles.module.scss'

export const tableColumns = ({
  onServiceRequestIdClick,
}: {
  onServiceRequestIdClick: (serviceRequestId: number) => void
}) => [
  {
    Header: 'Ticket ID',
    accessor: 'id',
    Cell: ({
      row: {
        original: { id },
      },
    }: any) => {
      return (
        <div
          onClick={() => onServiceRequestIdClick(id)}
          className={styles.serviceRequestId}
        >
          {id}
        </div>
      )
    },
  },
  {
    Header: 'Service Type',
    Cell: ({ row: { original } }: any) => {
      return original.organizationId === original.service.organizationId
        ? 'Direct'
        : 'Referal'
    },
  },
  {
    Header: 'Service Name',
    Cell: ({ row: { original } }: any) => {
      return original.service.name
    },
  },
  {
    Header: 'Category',
    accessor: 'service.type',
  },
  {
    Header: 'Ref. Organization',
    accessor: 'organization.name',
  },
  {
    Header: 'Member Name',
    accessor: (row) =>
      row.assignee ? `${row.patient.lastName}, ${row.patient.firstName}` : '--',
    Cell: ({
      row: {
        original: { patient },
      },
    }: any) => `${patient.firstName} ${patient.lastName}`,
  },
  {
    Header: 'Assigned Agent',
    accessor: (row) =>
      row.assignee
        ? `${row.assignee.lastName}, ${row.assignee.firstName}`
        : '--',
    Cell: ({
      row: {
        original: { assignee },
      },
    }: any) => {
      return assignee ? `${assignee.firstName} ${assignee.lastName}` : '--'
    },
  },
  {
    Header: 'Date Completed',
    accessor: 'completeTimestamp',
    Cell: ({ row: { original } }: any) => {
      return original.completeTimestamp
        ? formatTimestampToShortDate(original.completeTimestamp)
        : '--'
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    // @ts-ignore
    Cell: ({ row }) => ServiceRequestStatusText[row.original.status],
  },
]

/* eslint-disable */
export default {
  id: 1000,
  name: 'ORG',
  organizationType: 'Service Provider',
  phoneNumber: '+12223334444',
  email: 'org@mail.com',
  addressLine1: 'Test addresss',
  city: 'Test city',
  addressLine2: 'Test state',
  zipCode: '1234',
  website: 'https://www.org.com',
  description: 'Test description',
}

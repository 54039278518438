/* eslint-disable */

import React, { Dispatch, SetStateAction } from 'react'
import { Patient } from '../../employee/interfaces'
import Table from '../../table/Table'
import useEmployeeColumns from '../useEmployeeColumns'

interface EmployeesTabProps {
  orgId?: number
  setFilters?: Dispatch<
    SetStateAction<{ serviceType: string; serviceName: string }>
  >
  employees: Patient[]
  onEmployeeSelect: (selected: any) => void
}

const EmployeesTab = ({ employees, onEmployeeSelect }: EmployeesTabProps) => {
  const columns = useEmployeeColumns()

  return (
    <>
      <div style={{ height: 50 }} />
      <Table
        data={employees}
        columns={columns}
        showFilters
        getSelectedRows={onEmployeeSelect}
      />
    </>
  )
}

export default EmployeesTab

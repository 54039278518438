/* eslint-disable */
import { BASE_API_URL } from './constants'
import { jsonToQueryString } from './utils'

export function sendUserList({
  orgId,
  serviceIds,
  file,
  userId,
  serviceStatus
}: {
  orgId: number
  serviceIds: number[]
  file: File
  userId: string,
  serviceStatus: string
}): Promise<Response> {
  const formData = new FormData()
  formData.append('file', file, file.name)

  const queryString = jsonToQueryString({ serviceIds: serviceIds })

  return fetch(
    `${BASE_API_URL}/v1/organizations/${orgId}/services/upload-patients?${queryString}&serviceStatus=${serviceStatus}`,
    {
      method: 'POST',
      body: formData,
      headers: {
        userId: userId,
      },
    },
  )
}

/* eslint-disable */
import { BASE_API_URL } from './constants'

export const updateEmailPreference = async (data: any): any => {
  const res = await fetch(
    `${BASE_API_URL}/v1/users/${data[0].userId}/email-preferences`,
    {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  if (!res.ok) {
    throw new Error('Something went wrong! Please try again')
  }

  return res.json()
}

export const saveEmailPreference = async (data: any): any => {
  const res = await fetch(
    `${BASE_API_URL}/v1/users/${data[0].userId}/email-preferences`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  if (!res.ok) {
    throw new Error('Something went wrong! Please try again')
  }
  return res.json()
}

/* eslint-disable */
import { useMutation } from 'react-query'
import { BASE_API_URL } from './constants'

interface IResetPassword {
  email: string
  token: string
  password: string
}

interface IMutateParams {
  onSuccess: (res: Response) => void
  onError: (err: Error) => void
}

const handleResponse = (response: Response) => {
  if (!response.ok) {
    return response.text().then((text) => {
      throw new Error(text)
    })
  }
  return response.json()
}

const forgotPassword = (email: string) => {
  return fetch(`${BASE_API_URL}/v1/auth/forgotPassword/${email}`, {
    method: 'POST',
  }).then(handleResponse)
}

const resetPassword = ({ email, password, token }: IResetPassword) => {
  return fetch(`${BASE_API_URL}/v1/auth/forgotPasswordVerify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, token, password }),
  }).then(handleResponse)
}

export function useForgotPassword({ onSuccess, onError }: IMutateParams) {
  return useMutation(forgotPassword, {
    onSuccess,
    onError,
  })
}

export function useResetPassword({ onSuccess, onError }: IMutateParams) {
  return useMutation(resetPassword, {
    onError,
    onSuccess,
  })
}

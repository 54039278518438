/* eslint-disable */

import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import Page from '../page/Page'
import styles from './MemberMessaging.module.scss'
import { RouteProps } from 'react-router-dom'
import { usePatientMessagingRequestModal } from '../shared/PatientMessagingRequest'
import { usePatientMessagingByOrganizationRequests } from '../api/usePatientMessaging'
import { getCognitoAttribute, getCurrentUser } from '../authentication/auth'
import { CustomMessage } from './components/custom-message/CustomMessage'

export const MemberMessaging: FunctionComponent<RouteProps> = (
  props: RouteProps,
) => {
  const [currentUserAttributes, setCurrentUserAttributes] = useState([])
  const [editCustomMessage, setEditCustomMessage] = useState()

  useEffect(() => {
    getCurrentUser((attributes: any) => {
      setCurrentUserAttributes(attributes)
    })
  }, [getCurrentUser])

  const loggedInUserOrgId = useMemo(() => {
    return getCognitoAttribute(currentUserAttributes, 'custom:organizationId')
  }, [currentUserAttributes])

  const {
    data: allPatientMessaging,
  } = usePatientMessagingByOrganizationRequests(Number(loggedInUserOrgId))

  // Patient Messaging Service Request
  const {
    PatientMessagingServiceRequest,
    show: showPatientMessagingServiceRequest,
  } = usePatientMessagingRequestModal({
    msg: editCustomMessage,
    onSuccess: () => {},
    onCanceled: () => {},
  })

  const onEditMsgHandler = (msgData: any) => {
    setEditCustomMessage(msgData)
    showPatientMessagingServiceRequest()
  }

  return (
    <Page hideTopBar>
      <IonGrid className={styles.pageContainer}>
        <IonRow className={styles.cardContainer}>
          <IonCol>
            <IonRow>
              <IonText className={styles.pageTitle}>Member Messaging</IonText>
            </IonRow>
          </IonCol>
        </IonRow>
        <CustomMessage
          msgType={'Service assigned to employee (to member)'}
          msgKey={'service_request_patient'}
          messages={allPatientMessaging}
          onEditMsg={(msg: any) => onEditMsgHandler(msg)}
        ></CustomMessage>
        <CustomMessage
          msgType={'Service assigned to employee (to employee)'}
          msgKey={'service_request_employee'}
          messages={allPatientMessaging}
          onEditMsg={(msg: any) => onEditMsgHandler(msg)}
        ></CustomMessage>
        <CustomMessage
          msgType={'Send confirmation message (to member)'}
          msgKey={'service_request_confirmation_patient'}
          messages={allPatientMessaging}
          onEditMsg={(msg: any) => onEditMsgHandler(msg)}
        ></CustomMessage>
      </IonGrid>
      <PatientMessagingServiceRequest />
    </Page>
  )
}

export default MemberMessaging

/* eslint-disable */
import { useQuery } from 'react-query'
import { ServiceRequest } from '../shared/models/ServiceRequest'
import { BASE_API_URL } from './constants'

const getAssignedServiceRequests = async ({ queryKey }: any) => {
  const [_key, { userId, currentUserId }] = queryKey
  return fetch(
    `${BASE_API_URL}/v1/users/${userId}/service-request-assignments`,
    {
      method: 'GET',
      headers: {
        userId: currentUserId,
      },
    },
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.json()
  })
}

export default function useAssignedServiceRequests(
  userId: string | number,
  currentUserId: string,
) {
  return useQuery(
    getAssignedServiceRequestsQueryKey(userId, currentUserId),
    getAssignedServiceRequests,
  )
}

export function getAssignedServiceRequestsQueryKey(
  userId: string | number,
  currentUserId: string,
): any[] {
  return [`service-requests`, { userId: userId, currentUserId }]
}

export function updateAssignedServiceRequestInCache(
  cachedData: ServiceRequest[] | undefined,
  updated: ServiceRequest,
) {
  if (!cachedData) {
    return cachedData
  }

  return cachedData.reduce<ServiceRequest[]>((acc, serviceRequest) => {
    if (serviceRequest.id === updated.id) {
      // remove that SR if the assignee was changed
      if (serviceRequest.assignee.id !== updated.assignee.id) {
        return acc
      }

      // full update otherwise
      acc.push(updated)
      return acc
    } else {
      acc.push(serviceRequest)
      return acc
    }
  }, [])
}

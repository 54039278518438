/* eslint-disable */

import React, { useState, useEffect, useContext } from 'react'
import {
  IonPage,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonImg,
  IonSlides,
  IonSlide,
} from '@ionic/react'
import { FormProvider } from 'react-hook-form'
import SignInDoctors from '../../public/assets/signin-doctors.png'
import EmployeeStepOne from './steps/EmployeeStepOne'
import EmployeeStepTwo from './steps/EmployeeStepTwo'
import { UserType, EmployeeData } from '../util'
import { useOnboardingForm, useValidateSignUp } from '../hooks'
import Signup from '../../authentication/Signup'
import VerifyAccount from '../VerifyAccount'
import { AuthContext } from '../../contexts/AuthContext'
import useLanguages from '../../api/useLanguages'
import { useMutation } from 'react-query'
import { signUp } from '../../authentication/auth'

function EmployeeSignUp(): JSX.Element {
  const { userId } = useContext(AuthContext)
  const { data: languages } = useLanguages()
  const [error, setError] = useState('')
  const [employeeData, setEmployeeData] = useState<EmployeeData>({
    organizationId: null,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    language: 'English',
    userType: UserType.EMPLOYEE,
  })

  const {
    slideOpts,
    slides,
    formMethods,
    next,
    onSubmit,
    trigger,
    switchSlide,
  } = useOnboardingForm(setEmployeeData)
  const { handleSubmit } = formMethods

  const { mutate: mutateSignUp, isLoading } = useMutation(signUp, {
    onSuccess: () => {
      setError('')
      switchSlide(false, true)
    },
    onError: (err: any) => {
      setError(err.message)
    },
  })

  useEffect(() => {
    const { status } = useValidateSignUp(employeeData)
    const signUpUser = async () => {
      const defaultLanguage = languages?.find((l) => l.name === 'English')
      employeeData['languageId'] = defaultLanguage?.id
      mutateSignUp(employeeData)
    }
    if (status) {
      signUpUser()
    }
  }, [employeeData])

  return (
    <IonGrid className='ion-no-padding'>
      <IonRow>
        <IonCol>
          <IonRow>
            <IonImg className='sign-in-img' src={SignInDoctors} />
          </IonRow>
        </IonCol>
        <IonCol className='ion-padding' size={'6'}>
          <FormProvider {...formMethods}>
            <form
              onSubmit={handleSubmit((data) =>
                onSubmit({ ...employeeData, ...data }),
              )}
              autoComplete='off'
            >
              <IonSlides options={slideOpts} ref={slides}>
                <IonSlide>
                  <EmployeeStepOne next={next} />
                </IonSlide>
                <IonSlide>
                  <EmployeeStepTwo
                    next={trigger}
                    isLoading={isLoading}
                    error={error}
                  />
                </IonSlide>
                <IonSlide>
                  <VerifyAccount />
                </IonSlide>
              </IonSlides>
            </form>
          </FormProvider>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default EmployeeSignUp

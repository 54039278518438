/* eslint-disable */
import { BASE_API_URL } from './constants'

export const getUserDocuments = async (userId: number) => {
  return await fetch(`${BASE_API_URL}/v1/users/${userId}/documents`, {
    method: 'GET',
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    
    return response.json();
  })
}

export const uploadFiles = async ({
  orgId,
  userId,
  createdById,
  fileList,
}: {
  orgId: number
  userId: number
  createdById: number
  fileList: File[]
}) => {
  const formData = new FormData()
  const data = JSON.stringify({
    createdById: createdById,
  })
  
  if (fileList && fileList.length > 0) {
    fileList.forEach(file => {
      formData.append('files', file, file.name)
    })
  }

  return await fetch(`${BASE_API_URL}/v1/users/${userId}/documents`, {
    method: 'POST',
    body: formData,
    headers: {
      "created-by-id": userId.toString(),
      "organization-id": orgId.toString()
    },
}).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response
  }).catch((err) => { 
    console.log(err.message)
  })
}

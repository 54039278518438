/* eslint-disable  */

import React from 'react'
import { IonAvatar } from '@ionic/react'
import Avatar from 'react-avatar'

interface ProfileIconProps {
  userName: string
  size: string
  url?: string
}

function ProfileIcon(props: ProfileIconProps): JSX.Element {
  return (
    <IonAvatar className='toolbarProfile' slot='end'>
      <Avatar name={props.userName} src={props.url} round size={props.size} />
    </IonAvatar>
  )
}

export default ProfileIcon

/* eslint-disable */
import { useQuery } from 'react-query'
import { ServiceRequest } from '../shared/models/ServiceRequest'
import { BASE_API_URL } from './constants'
import { IFetchServiceRequestsParams } from './interfaces'
import { jsonToQueryString } from './utils'

const getOrgIncomingServiceRequests = async ({ queryKey }: any) => {
  const [_key, , { organizationId, userId, status }] = queryKey
  if (organizationId == null) {
    return []
  }
  let url = `${BASE_API_URL}/v1/organizations/${organizationId}/incoming-service-requests`
  if (status != null) {
    const queryString = jsonToQueryString({ status })
    url += `?${queryString}`
  }

  return fetch(url, {
    headers: {
      userId: userId,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`${response.status}`)
    }
    return response.json()
  })
}

export function useOrgIncomingServiceRequests(
  {
    userId,
    organizationId,
  }: {
    userId: string
    organizationId: string
  },
  {
    refetchOnWindowFocus = false,
    onSuccess,
    onError,
  }: IFetchServiceRequestsParams,
  status?: string,
) {
  return useQuery(
    getOrgIncomingServiceRequestsQueryKey(userId, organizationId, status),
    getOrgIncomingServiceRequests,
    { refetchOnWindowFocus, onSuccess, onError },
  )
}

export function getOrgIncomingServiceRequestsQueryKey(
  userId: string | number,
  organizationId: string | number,
  status?: string,
): any[] {
  return ['service-requests', 'incoming', { organizationId, userId, status }]
}

export function updateOrgIncomingServiceRequestsInCache(
  cachedData: ServiceRequest[] | undefined,
  updated: ServiceRequest,
) {
  if (!cachedData) {
    return cachedData
  }

  return cachedData.map((serviceRequest) =>
    serviceRequest.id === updated.id ? updated : serviceRequest,
  )
}

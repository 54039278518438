/* eslint-disable  */

import React from 'react'
import { useFetchNotesFile } from '../../../api/useDownloadFile'
import ProfileIcon from '../../../profile/ProfileIcon'
import { ServiceRequestNote } from '../../models/ServiceRequestNote'
import { formatTimestampToFullDate } from '../../utils/formatTimestamp'
import styles from '../styles.module.scss'

interface INote {
  note: ServiceRequestNote
}

function Note({ note }: INote): JSX.Element {
  const { refetch: downloadNoteFile } = useFetchNotesFile(
    note.id as string,
    note.fileName as string,
    {
      onSuccess: (fileUrl: string) => {
        const a = document.createElement('a')
        a.href = fileUrl
        a.download = note.fileName as string
        a.click()
      },
    },
  )

  const renderNotesFile = (name: string | undefined) => {
    if (name) {
      return (
        <span>
          {note.content && ' - '}
          <a onClick={() => downloadNoteFile()} className={styles.fileLink}>
            {name}
          </a>
        </span>
      )
    }
  }

  const noteDetails = () => {
    const details = `${note.createdBy?.firstName} ${note.createdBy?.lastName}`
    const timeStamp = formatTimestampToFullDate(note?.updateTimestamp as string)
    return details + ' ' + timeStamp
  }

  return (
    <div className={styles.comment}>
      <span className={styles.profileIcon}>
        <ProfileIcon
          size={'32'}
          userName={
            note?.createdBy?.firstName + ' ' + note?.createdBy?.lastName
          }
        />
      </span>
      <div className={styles.commentBody}>
        <div className={styles.commentText}>
          {note.content} {renderNotesFile(note?.fileName)}
        </div>
        {note.createdBy && <div className={styles.author}>{noteDetails()}</div>}
      </div>
    </div>
  )
}

export default Note

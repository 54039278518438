/* eslint-disable  */
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import Select from 'react-select'
import { Employee } from '../models/Employee'
import { ISelectEmployeeProps } from './interfaces'
import styles from './styles.module.scss'

const userName = (user: { firstName: string; lastName: string }) => {
  return user?.firstName + ' ' + user?.lastName
}

export const SelectEmployee = ({
  employees = [],
  assignee,
  isLoading,
  onEmployeeChange,
  cssClass,
  isAssignLater,
}: ISelectEmployeeProps) => {
  const employeesList = useMemo(() => {
    return employees
      .map((item) => {
        return { ...item, value: item.id, label: userName(item) }
      })
      .concat(
        isAssignLater ? [{ value: null, label: "I'll assign later" }] : [],
      )
  }, [employees])

  const selectedEmployee = useMemo(() => {
    if (!assignee) return null

    return {
      ...assignee,
      value: assignee.id,
      label: userName(assignee),
    }
  }, [assignee])

  const onChange = (selected: any) => {
    if (selected.id) {
      onEmployeeChange(selected.id)
    }
  }

  return (
    <Select
      options={employeesList}
      isSearchable
      defaultValue={selectedEmployee}
      className={classNames(styles.select, cssClass)}
      onChange={onChange}
      placeholder='Search employee'
    />
  )
}

/* eslint-disable */

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSlide,
  IonSlides,
  IonText,
  IonTextarea,
} from '@ionic/react'
import { checkmark } from 'ionicons/icons'
import React, { useRef, useState } from 'react'
import { useSendFeedback } from '../api/useSendFeedback'
import styles from './feedbackForm.module.scss'

function FeedbackSlide(props: FeedbackSlidesProps): JSX.Element {
  const [supportQuestion, setSupportQuestion] = useState(false)
  const [businessQuestion, setBusinessQuestion] = useState(false)
  const [question, setQuestion] = useState('')
  const [error, setError] = useState(false)

  const { mutate: sendFeedback } = useSendFeedback({
    onSuccess: () => {
      if (props.swithchSlide) props.swithchSlide(1)
    },
    onError: (err: Error) => {
      console.log('err', err)
    },
  })

  const onSubmit = (data: { messageType: string; feedback: string }) => {
    sendFeedback(data)
  }

  const getData = () => {
    let messageType = ''
    if (supportQuestion) messageType = 'Support Question'
    if (businessQuestion) messageType = 'Business Question'
    return { messageType: messageType, feedback: question }
  }

  return (
    <IonCardContent className={styles.cardContent}>
      <IonGrid>
        <IonRow>
          <IonText style={{ color: '#5E6366', fontSize: '18px' }}>
            We're happy to help in anyway we can. Please enter your questions
            and check the box of the type of question you have.
          </IonText>
        </IonRow>
        <IonRow>
          <IonText
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: '17px',
              marginTop: '15px',
            }}
          >
            Select area of question / support
          </IonText>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem lines='none'>
              <IonCheckbox
                color='dark'
                checked={supportQuestion}
                onIonChange={(e) => {
                  setSupportQuestion(e.detail.checked)
                  if (e.detail.checked) setBusinessQuestion(false)
                }}
              />
              <IonLabel style={{ marginLeft: '5px' }}>
                Technical Support Issue
              </IonLabel>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem lines='none'>
              <IonCheckbox
                color='dark'
                checked={businessQuestion}
                onIonChange={(e) => {
                  setBusinessQuestion(e.detail.checked)
                  if (e.detail.checked) setSupportQuestion(false)
                }}
              />
              <IonLabel style={{ marginLeft: '5px' }}>
                Business Inquery
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonTextarea
            value={question}
            onIonChange={(e) => {
              setQuestion(e.detail.value as string)
            }}
            style={{
              border: 'solid 1px #0A3860',
              borderRadius: '8px',
              height: '100px',
              marginBottom: '15px',
            }}
            placeholder='Type your question here...'
          />
        </IonRow>
        <IonRow>
          {error && (
            <IonText color='danger'>
              <small>Please type your question</small>
            </IonText>
          )}
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton
              shape='round'
              className={styles.cancelButton}
              onClick={() => props.setOpen(false)}
            >
              Cancel
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton
              shape='round'
              type='submit'
              className={styles.submitButton}
              onClick={() => {
                if (question != '') {
                  setError(false)
                  return onSubmit(getData())
                }
                setError(true)
              }}
            >
              Submit
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCardContent>
  )
}

function SuccessSlide(props: FeedbackSlidesProps): JSX.Element {
  return (
    <IonCardContent className={styles.cardContent}>
      <IonGrid>
        <IonRow style={{ marginBottom: '50px' }}>
          <IonCol>
            <IonText
              style={{ fontWeight: 'bold', fontSize: '24px', color: 'black' }}
            >
              Success!
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonIcon
              icon={checkmark}
              style={{
                width: '50px',
                height: '50px',
                backgroundColor: '#BDEBFA',
                borderRadius: '50%',
                color: 'white',
              }}
            />
          </IonCol>
        </IonRow>
        <IonRow style={{ marginBottom: '100px' }}>
          <IonCol>
            <IonText style={{ color: '#5E6366', fontSize: '20px' }}>
              Your request was successfully sent through successfully, someone
              will contact you shortly.
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton
              shape='round'
              className={styles.doneButton}
              onClick={() => props.setOpen(false)}
            >
              Done
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCardContent>
  )
}

interface FeedbackFormProps {
  setOpen: (open: boolean) => void
}

interface FeedbackSlidesProps extends FeedbackFormProps {
  swithchSlide?: (slide: number) => void
}
export default function FeedbackForm(props: FeedbackFormProps): JSX.Element {
  const slides = useRef<any>(null)

  const switchSlide = async (slideNumber: number) => {
    await slides.current.lockSwipes(false)
    if (slideNumber) await slides.current.slideTo(slideNumber)
    await slides.current.lockSwipes(true)
  }

  return (
    <IonCard className={styles.card}>
      <IonCardHeader className={styles.cardHeader}>
        Contact Support
      </IonCardHeader>
      <IonSlides
        options={{ initialSlide: 0, speed: 400 }}
        ref={slides}
        onIonSlidesDidLoad={() => slides.current.lockSwipes(true)}
      >
        <IonSlide style={{ textAlign: 'left' }}>
          <FeedbackSlide setOpen={props.setOpen} swithchSlide={switchSlide} />
        </IonSlide>
        <IonSlide>
          <SuccessSlide setOpen={props.setOpen} />
        </IonSlide>
      </IonSlides>
    </IonCard>
  )
}

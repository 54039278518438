/* eslint-disable */
import React, { useState, useEffect, useMemo, useContext } from 'react'
import {
  IonSpinner,
  IonButton,
  IonIcon,
  IonText,
  IonCheckbox,
  IonItem,
} from '@ionic/react'
import classNames from 'classnames'

import { AvatarStatus } from './components/AvatarStatus'

import { UserInfoProps, Role } from './interfaces'

import styles from './styles.module.scss'
import { UserTypeFormatted } from '../models/UserType'

import { formatDisplayDate } from '../../shared/utils/formatTimestamp'
import useOrganization from '../../api/useOrganization'
import useEmailPreferences from '../../api/useEmailPreferences'
import {
  updateEmailPreference,
  saveEmailPreference,
} from '../../api/useUpdateEmailPreferences'
import ProfileIcon from '../../profile/ProfileIcon'

import Select, { SingleValue } from 'react-select'

import { ACTIVE, ADMIN, EMPLOYEE } from '../../api/constants'

import { getCurrentUser, getCognitoAttribute } from '../../authentication/auth'
import { documentTextOutline } from 'ionicons/icons'
import { AuthContext } from '../../contexts/AuthContext'

const customStyles = {
  option: (provided: any, state: { isSelected: boolean }): any => ({
    ...provided,
    color: '#000',
    backgroundColor: state.isSelected ? '#eeedee' : '#fff',
  }),
  control: (provided: any): any => ({ ...provided, width: 200 }),
}

export function UserInfo({
  userType,
  user,
  setRole,
  role,
  options,
  onEdit,
  onUpdateStatus,
  roleError,
  statusError,
  data,
  patientSMSTranscripts,
}: UserInfoProps): any {
  const [currentUserAttributes, setCurrentUserAttributes] = useState([])

  useEffect(() => {
    getCurrentUser((attributes: any) => {
      setCurrentUserAttributes(attributes)
    })
  }, [getCurrentUser])

  const currentUserId = useMemo(() => {
    return getCognitoAttribute(currentUserAttributes, 'custom:userId')
  }, [currentUserAttributes])

  const { userId } = useContext(AuthContext)

  const { data: organization } = useOrganization(user?.organizationIds[0])
  const { data: emailPreference } = useEmailPreferences(userId)

  const setEmailPreference = (emailReq: any, e: any): void => {
    const body = {
      id: e.id,
      userId: e.userId,
      serviceName: e.serviceName,
      isEmailRequired: emailReq,
      serviceId: e.serviceId,
    }

    if (e.id === null) {
      updateEmailPreference([body])
    } else {
      saveEmailPreference([body])
    }
  }

  const formatRole = (): Role | null => {
    if (user?.userType) {
      return {
        value: user.userType.toLocaleLowerCase(),
        label: UserTypeFormatted[user.userType],
      }
    }
    return null
  }

  const setUserRole = (_role: SingleValue<Role>): any => {
    if (setRole) {
      setRole(_role as Role)
    }
  }

  const renderValue = ({ key }: { key: string }): any => {
    if (key === 'dateOfBirth') {
      return formatDisplayDate(user?.dateOfBirth as string)
    }
    return user ? user[key] : ''
  }

  if (!user) {
    return (
      <div className={styles.section}>
        <h5
          className={classNames(
            styles.sectionTitle,
            styles.userInformationSectionTitle,
          )}
        >
          {UserTypeFormatted[userType]} Information
        </h5>
        <div className={styles.userIdentityWrapper}>
          <IonSpinner />
        </div>
        <div className={styles.userContacts}>
          <IonSpinner />
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className={styles.section}>
        <div>
          <h5
            className={classNames(
              styles.sectionTitle,
              styles.userInformationSectionTitle,
            )}
          >
            {UserTypeFormatted[user.userType]} Information
          </h5>
          <div className={styles.userIdentityWrapper}>
            <AvatarStatus
              status={user.status === ACTIVE ? 'active' : 'unknown'}
            >
              <ProfileIcon
                size={'50'}
                userName={user.firstName + ' ' + user.lastName}
              />
            </AvatarStatus>
            <div className={styles.userIdentity}>
              <div className={styles.userName}>
                {user.firstName} {user.lastName}
              </div>
              <div className={styles.userType}>
                {UserTypeFormatted[user.userType]} User
              </div>
            </div>
            <div className={styles.editProfileWrapper}>
              <IonButton
                fill='clear'
                className={styles.editProfileButton}
                onClick={onEdit}
              >
                <IonIcon name='pencil' className={styles.editProfileIcon} />
                Edit Profile
              </IonButton>
            </div>
          </div>
          <div className={styles.userContacts}>
            {user?.organizationIds?.length === 1 && userType === 'EMPLOYEE' && (
              <div className={styles.userContactRow}>
                <span className={styles.userContactKey}>Organization:</span>
                <span className={styles.userContactValue}>
                  {organization?.name}
                </span>
              </div>
            )}
            {currentUserId !== user.id && userType === EMPLOYEE && (
              <div className={styles.userRoleRow}>
                <span className={styles.userContactKey}>Role:</span>
                <span className={styles.userContactValue}>
                  <Select
                    defaultValue={formatRole()}
                    value={role}
                    onChange={setUserRole}
                    options={options}
                    styles={customStyles}
                  />
                </span>
              </div>
            )}
            {roleError && (
              <span className={styles.errorMessage}>{roleError}</span>
            )}
            {data ? (
              data.map((item, i) => {
                return (
                  <div key={i} className={styles.userContactRow}>
                    <span className={styles.userContactKey}>{item.name}:</span>
                    <span className={styles.userContactValue}>
                      {renderValue(item)}
                    </span>
                  </div>
                )
              })
            ) : (
              <>
                <div className={styles.userContactRow}>
                  <span className={styles.userContactKey}>Birthdate:</span>
                  <span className={styles.userContactValue}>
                    {formatDisplayDate(user.dateOfBirth as string)}
                  </span>
                </div>
                <div className={styles.userContactRow}>
                  <span className={styles.userContactKey}>Email:</span>
                  <span className={styles.userContactValue}>{user.email}</span>
                </div>
                <div className={styles.userContactRow}>
                  <span className={styles.userContactKey}>Phone #:</span>
                  <span className={styles.userContactValue}>
                    {user.phoneNumber}
                  </span>
                </div>
                <div className={styles.spacer} />
                <div className={styles.userContactRow}>
                  <span className={styles.userContactKey}>Address Line 1:</span>
                  <span className={styles.userContactValue}>
                    {user.addressLine1}
                  </span>
                </div>
                <div className={styles.userContactRow}>
                  <span className={styles.userContactKey}>Address Line 2:</span>
                  <span className={styles.userContactValue}>
                    {user.addressLine2}
                  </span>
                </div>
                <div className={styles.userContactRow}>
                  <span className={styles.userContactKey}>City:</span>
                  <span className={styles.userContactValue}>{user.city}</span>
                </div>
                <div className={styles.userContactRow}>
                  <span className={styles.userContactKey}>State:</span>
                  <span className={styles.userContactValue}>{user.state}</span>
                </div>
                <div className={styles.userContactRow}>
                  <span className={styles.userContactKey}>Zipcode:</span>
                  <span className={styles.userContactValue}>
                    {user.zipCode}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>

        <div className={styles.userAction}>
          {patientSMSTranscripts && (
            <div className={styles.service}>
              <div className={styles.sectionTitle}>Transcript</div>
              <div className={styles.msgReview} onClick={patientSMSTranscripts}>
                <IonText>Review latest transcription</IonText>
                <IonIcon
                  icon={documentTextOutline}
                  className={styles.msgReviewIcon}
                />
              </div>
            </div>
          )}
          {currentUserId !== user.id && userType === EMPLOYEE && (
            <span
              onClick={onUpdateStatus}
              className={styles.userTerminateButton}
            >
              {user.active ? 'Terminate Employee' : 'Activate Employee'}
            </span>
          )}
          {statusError && <p className={styles.errorMessage}>{statusError}</p>}
        </div>
      </div>

      {userType === ADMIN || userType === EMPLOYEE && (
        <div className={styles.emailSection}>
          <div className={styles.emailContainer}>
            <h5
              className={classNames(
                styles.sectionTitle,
                styles.userInformationSectionTitle,
              )}
            >
              Email Preferences
            </h5>
            {emailPreference?.map((email: any, i: any) => {
              return (
                <div key={i} className={styles.emailWrapper}>
                  <div className={styles.emailIdentityWrapper}>
                    <IonItem lines='none'>
                      <IonCheckbox
                        className={styles.emailCheckBox}
                        checked={email.isEmailRequired}
                        onIonChange={(e): any => {
                          setEmailPreference(e.detail.checked, email)
                        }}
                        slot='end'
                        color='dark'
                      />
                    </IonItem>
                    <div className={styles.emailContacts}>
                      <div className={styles.emailContactRow}>
                        <span className={styles.emailContactKey}>
                          <span key={i} className={styles.emailContactKey}>
                            {email.serviceName}
                            <span> - Service Request Activity</span>
                          </span>
                        </span>
                      </div>
                      <div className={styles.emailContactRow}>
                        <span className={styles.emailContactValue}>
                          Updates on new requests that need assignment.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )
          })}
          </div>
        </div>
      )}
    </div>
  )
}

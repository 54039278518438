/* eslint-disable  */

import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react'
import React, { useState } from 'react'
import Modal from 'react-modal'
import Select from 'react-select'
import { serviceTypes } from '../../../shared/utils/constants'
import { customStyles } from './style'

import styles from './styles.module.scss'

type Params = {
  organizationId: string
  name: string
  type: string
  description: string
}

interface IProps {
  isOpen: boolean
  organizationId: string
  onClose: () => void
  onAddService: ({ name, type, description, organizationId }: Params) => void
}

const NewServiceModal = ({
  isOpen,
  organizationId,
  onClose,
  onAddService,
}: IProps): JSX.Element => {
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [type, setType] = useState('')
  const [typeError, setTypeError] = useState(false)
  const [description, setDescription] = useState('')

  const onSubmit = () => {
    if (!name) {
      setNameError(true)
      return
    } else if (!type) {
      setTypeError(true)
      return
    }

    onAddService({ name, type, description, organizationId })
    onClose()
  }

  const renderLabel = (isError: boolean, label: string) => {
    if (isError) {
      return (
        <IonLabel position='floating' color='danger'>
          {label} is required.
        </IonLabel>
      )
    }
    return <IonLabel position='floating'>{label}</IonLabel>
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modalContent}
      overlayClassName={styles.modalOverlay}
    >
      <IonGrid>
        <IonRow>
          <IonText className={styles.title}>Add New Servce</IonText>
        </IonRow>
        <IonRow style={{ marginTop: 20 }}>
          <IonCol>
            <IonItem>
              {renderLabel(nameError, 'Service name')}
              <IonInput
                value={name}
                onIonChange={(e) => setName(e.detail.value as string)}
                name='name'
              />
            </IonItem>
          </IonCol>
          <IonCol>
            {renderLabel(typeError, 'Service type')}
            <Select
              options={serviceTypes}
              onChange={(value) => setType(value?.value as string)}
              className={styles.dropdown}
              styles={customStyles}
              menuPortalTarget={document.body}
              placeholder='Service Type'
            />
            {/* <IonSelect
                onIonChange={(e) => setType(e.detail.value)}
                name='type'
              >
                {serviceTypes.map((serviceType, i) => {
                  return (
                    <IonSelectOption key={i} value={serviceType.value} className={styles.options}>
                      {serviceType.label}
                    </IonSelectOption>
                  )
                })}
              </IonSelect> */}
            {/* </IonItem> */}
          </IonCol>
        </IonRow>
        <IonItem>
          {renderLabel(false, 'Service description')}
          <IonInput
            value={description}
            onIonChange={(e) => setDescription(e.detail.value as string)}
            name='description'
          />
        </IonItem>
        <IonRow>
          <IonButton
            shape='round'
            size='small'
            className={styles.button}
            onClick={onSubmit}
          >
            Add Service
          </IonButton>
        </IonRow>
      </IonGrid>
    </Modal>
  )
}

export default NewServiceModal

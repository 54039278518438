/* eslint-disable  */

import React, { useCallback, useMemo, useState } from 'react'
import { ArchiveModal } from './ArchiveModal'
import { ArchiveModalHookProps } from './interfaces'

export function useArchiveModal({
  onConfirm,
  text,
  isArchive,
}: ArchiveModalHookProps) {
  const [isVisible, setVisible] = useState(false)
  const [itemId, setItemId] = useState<string | number>('')

  const handleShow = useCallback(() => {
    setVisible(true)
  }, [setVisible])

  const handleDismiss = useCallback(() => {
    setVisible(false)
  }, [isVisible])

  const handleSetItemId = useCallback(
    (id) => {
      setItemId(id)
    },
    [setItemId],
  )

  const handleConfirm = useCallback(() => {
    onConfirm(itemId)
    setVisible(false)
  }, [onConfirm, itemId, setVisible])

  const component = useMemo(() => {
    return () => (
      <React.Fragment>
        {isVisible && (
          <ArchiveModal
            onConfirm={handleConfirm}
            onDismiss={handleDismiss}
            text={text}
            isArchive={isArchive}
          />
        )}
      </React.Fragment>
    )
  }, [isVisible, text, isArchive, handleConfirm, itemId, handleDismiss])

  return {
    ArchiveModal: component,
    show: handleShow,
    confirm: handleConfirm,
    setItemId: handleSetItemId,
  }
}

/* eslint-disable */
import { useMutation } from 'react-query'
import { BASE_API_URL } from './constants'

const updateServices = async (data: any) => {
  const response = await fetch(`${BASE_API_URL}/v1/services/updateServices`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json()
}

export const useUpdateServices = (config = {}) => {
  return useMutation(updateServices, { ...config })
}

/* eslint-disable */
import { BASE_API_URL } from './constants'

import { User } from '../shared/models/User'
import { formatSystemDate } from '../shared/utils/formatTimestamp'

export const updateUserDetails = async ({
  user,
  userId,
}: {
  user: User
  userId: string
}): Promise<User> => {
  const userToSend: User = {
    ...user,
    dateOfBirth: !user.dateOfBirth ? null : formatSystemDate(user.dateOfBirth),
  }

  return fetch(`${BASE_API_URL}/v1/users/${user.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      userId: userId,
    },
    body: JSON.stringify(userToSend),
  }).then((response) => response.json())
}

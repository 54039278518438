/* eslint-disable  */

import {
  IonCol,
  IonRow,
  IonInput,
  IonItem,
  IonList,
  IonLabel,
  IonSelect,
  IonText,
  IonButton,
  IonSelectOption,
} from '@ionic/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { OrganizationData } from '../models/Organization'
import {
  validEmail,
  validPhoneNumber,
} from '../../onboarding/validation/SignUpValidation'

import styles from './styles.module.scss'
import { OrganizationFormProps } from './interfaces'
import { FileUpload } from './components/FileUpload/FileUpload'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const orgTypes = [
  { label: 'Social Services Provider', value: 'CARE_PROVIDER' },
  { label: 'Medical Services Provider', value: 'SERVICE_PROVIDER' },
]

export function OrganizationForm({
  organization,
  organization: { addressLine1 = '', addressLine2 = '' },
  orgLogo,
  onSubmit,
  error,
  isEdit,
  setOrganization,
  next,
}: OrganizationFormProps): JSX.Element {
  const [file, setFile] = useState<string | ArrayBuffer | null>('')

  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...organization,
      address: addressLine1,
      state: addressLine2,
      logo: null,
    },
  })

  const fileInput = register('logo')

  const doSubmit = async (data: OrganizationData) => {
    if (setOrganization) setOrganization(data)
    const result = await trigger([
      'name',
      'organizationType',
      'email',
      'phoneNumber',
      'address',
      'city',
      'state',
      'zipCode',
      'website',
      'description',
    ])
    if (next) next(result)

    if (result && onSubmit) {
      onSubmit(data)
    }
  }

  const renderLabel = (inputError: any, label: string) => {
    if (inputError) {
      return (
        <IonLabel position='floating' color='danger'>
          {inputError.message}
        </IonLabel>
      )
    }
    if (label === 'Phone Number') {
      return <IonLabel position='stacked'>{label}</IonLabel>
    } else {
      return <IonLabel position='floating'>{label}</IonLabel>
    }
  }

  const renderButton = () => {
    if (isEdit) {
      return (
        <IonButton
          shape='round'
          size='small'
          type='submit'
          className={styles.button}
        >
          Save Changes
        </IonButton>
      )
    }
    return (
      <IonButton
        shape='round'
        fill='outline'
        expand='full'
        color='dark'
        className='next-button'
        type='submit'
      >
        NEXT
      </IonButton>
    )
  }

  const [phone, setPhone] = useState()

  const setPhoneNumber = (data: any) => {
    setPhone(data)
  }

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit((data) => doSubmit({ ...organization, ...data }))}
    >
      <IonCol className='ion-text-center'>
        <IonList>
          <IonItem>
            {renderLabel(errors.name, 'Company Name')}
            <IonInput
              {...register('name', {
                required: 'Company Name is required.',
              })}
              name='name'
            />
          </IonItem>
          <IonItem>
            {renderLabel(errors.organizationType, 'Type of Organization')}
            <IonSelect
              {...register('organizationType', {
                required: 'Organization type is required.',
              })}
              name='organizationType'
            >
              {orgTypes.map((type, i) => {
                return (
                  <IonSelectOption key={i} value={type.value}>
                    {type.label}
                  </IonSelectOption>
                )
              })}
            </IonSelect>
          </IonItem>
          <IonItem>
            {renderLabel(errors.phoneNumber, 'Phone Number')}
            <PhoneInput
              country={'US'}
              defaultCountry='US'
              international
              countryCallingCodeEditable={false}
              displayInitialValueAsLocalNumber={true}
              autoComplete='tel'
              type='tel'
              placeholder='+12223334444'
              value={phone}
              {...register('phoneNumber', {
                minLength: 12,
                setValueAs: (v) => v.replace(/ /g, ''),
                required: 'Phone Number is required.',
                validate: (value) => {
                  if (value) {
                    return (
                      validPhoneNumber(value) || 'Must be a valid Phone Number.'
                    )
                  }
                  return true
                },
              })}
              onChange={setPhoneNumber}
              limitMaxLength={true}
              inputExtraProps={{
                name: 'phoneNumber',
                required: true,
                autoFocus: true,
                country: 'us',
              }}
              style={{
                padding: '10px 0',
              }}
            />
          </IonItem>
          <IonItem>
            {renderLabel(errors.email, 'Email')}
            <IonInput
              {...register('email', {
                validate: (value) => {
                  if (value) {
                    return validEmail(value) || 'Must be a valid Email.'
                  }
                  return true
                },
              })}
              type='email'
              name='email'
            />
          </IonItem>
          <IonItem>
            {renderLabel(errors.address, 'Address')}
            <IonInput {...register('address', {})} name='address' />
          </IonItem>
          <IonRow>
            <IonCol>
              <IonItem>
                {renderLabel(errors.city, 'City')}
                <IonInput {...register('city', {})} name='city' />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                {renderLabel(errors.state, 'State')}
                <IonInput {...register('state', {})} name='state' />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                {renderLabel(errors.zipCode, 'Zip Code')}
                <IonInput {...register('zipCode', {})} name='zipCode' />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonItem>
            {renderLabel(errors.website, 'Website')}
            <IonInput {...register('website')} name='website' type='url' />
          </IonItem>
          <IonItem>
            {renderLabel(errors.description, 'Company Bio')}
            <IonInput {...register('description', {})} name='description' />
          </IonItem>
        </IonList>
        <IonRow>
          <FileUpload
            file={file}
            orgLogo={orgLogo}
            fileInput={fileInput}
            setFile={setFile}
          />
        </IonRow>
        {error && (
          <IonItem>
            <IonText className='ion-text-center' color='danger'>
              {error}
            </IonText>
          </IonItem>
        )}
        {renderButton()}
      </IonCol>
    </form>
  )
}

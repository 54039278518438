/* eslint-disable  */

import React, { useCallback, useState, useMemo } from 'react'
import { useMutation } from 'react-query'
import { CustomFormServiceRequest } from '.'
import { CustomFormServiceRequestValues } from './interfaces'
import useLanguages from '../../api/useLanguages'
import { saveSurveyResponse } from '../../api/useSurvey'

export function useCustomFormRequestModal(props: {
  isPendingModal?: boolean
  isStandalone?: boolean
  onSuccess: () => void
  onError?: () => void
  onSettled?: () => void
  onCanceled: () => void
  onPending: () => void
  onSkip: () => void
}) {
  const { data: languages } = useLanguages()

  const [isPopupVisible, setPopupVisible] = useState(false)

  const handleShow = useCallback(() => {
    setPopupVisible(true)
  }, [])
  const handleHide = useCallback(() => {
    setPopupVisible(false)
  }, [])

  const { mutate: mutateFormAnswersRequest, isLoading } = useMutation(
    saveSurveyResponse,
    {
      onSuccess: (data) => {},
    },
  )

  const handleSubmit = useCallback(
    (requestData: CustomFormServiceRequestValues) => {
      const { id, version, data, surveyId, language } = requestData

      if (props.isPendingModal === true) {
        localStorage.setItem(
          'pendingSurvey',
          JSON.stringify({
            id,
            version,
            data,
            surveyId,
            userId: localStorage.getItem('currentPatientId'),
            language,
          }),
        )
        return props.onPending()
      }
      console.log('Submitting Form Answers Request. Form values: ', requestData)
      mutateFormAnswersRequest(
        {
          id,
          version,
          data,
          surveyId,
          userId: localStorage.getItem('currentPatientId'),
          language,
        },
        {
          onSuccess: () => {
            props.onSuccess()
          },
          onError: () => {
            props.onError && props.onError()
          },
          onSettled: () => {
            setPopupVisible(false)
            props.onSettled && props.onSettled()
          },
        },
      )
    },
    [],
  )

  const handleCancel = useCallback(() => {
    setPopupVisible(false)
    props.onCanceled()
  }, [])

  const handleSkip = useCallback(() => {
    setPopupVisible(false)
    props.onSkip()
  }, [])

  const component = useMemo(() => {
    return () => (
      <>
        {isPopupVisible && languages && (
          <CustomFormServiceRequest
            onSubmit={(surveyAnswers: CustomFormServiceRequestValues) =>
              handleSubmit(surveyAnswers)
            }
            onCancel={handleCancel}
            onSkip={handleSkip}
            isLoading={isLoading}
            isStandalone={props.isStandalone}
          />
        )}
      </>
    )
  }, [isPopupVisible, handleSubmit, handleCancel, isLoading])

  return {
    isVisible: isPopupVisible,
    show: handleShow,
    hide: handleHide,
    CustomFormServiceRequest: component,
    StandaloneCustomFormServiceRequest: component,
  }
}

/* eslint-disable  */

import React, { useCallback, useState, useMemo } from 'react'
import useLanguages from '../../api/useLanguages'
import { FormAnswersModal } from './FormAnswersModal'
import { Survey } from '../models/Survey'

export function useFormAnswersRequestModal(props: {
  onError?: () => void
  onSettled?: () => void
  onCanceled: () => void
  surveys: Survey[]
}) {
  const { data: languages } = useLanguages()

  const [isPopupVisible, setPopupVisible] = useState(false)

  const handleShow = useCallback(() => {
    setPopupVisible(true)
  }, [])
  const handleHide = useCallback(() => {
    setPopupVisible(false)
  }, [])

  const handleCancel = useCallback(() => {
    setPopupVisible(false)
    props.onCanceled()
  }, [])

  const component = useMemo(() => {
    return () => (
      <>
        {isPopupVisible && languages && (
          <FormAnswersModal onCancel={handleCancel} surveys={props.surveys} />
        )}
      </>
    )
  }, [isPopupVisible, handleCancel])

  return {
    isVisible: isPopupVisible,
    show: handleShow,
    hide: handleHide,
    FormAnswersModal: component,
  }
}

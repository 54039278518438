/* eslint-disable */
import { BASE_API_URL } from './constants'

const getServiceRequestSMSTranscriptsById = async (id: string | number) => {
  return await fetch(
    `${BASE_API_URL}/v1/messages/getServiceRequestMessages/${id}`,
    {
      method: 'GET',
      headers: {},
    },
  ).then((response) => {
    return response.json()
  })
}

export function getServiceRequestSMSTranscripts(id: string | number) {
  return getServiceRequestSMSTranscriptsById(id)
}

const getServiceRequestSMSTranscriptsCSVById = async (id: string | number) => {
  return await fetch(
    `${BASE_API_URL}/v1/messages/getServiceRequestMessagesCsv/${id}`,
    {
      method: 'GET',
      headers: {},
    },
  ).then((response) => {
    return response.text()
  })
}

export function getServiceRequestSMSTranscriptsCSV(id: string | number) {
  return getServiceRequestSMSTranscriptsCSVById(id)
}

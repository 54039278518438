/* eslint-disable */

import React, { Component, ErrorInfo } from 'react'
import styles from './styles.module.scss'
import errorIcon from '../assets/icon/error_icon.png'
import { IonButton } from '@ionic/react'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps, StaticContext } from 'react-router'

type IProps = {
  history: any
}

type IState = {
  hasError: boolean
  error: Error | null
  errorInfo: ErrorInfo | null
}

class ErrorBoundary extends Component<
  RouteComponentProps<any, StaticContext, unknown>,
  IState
> {
  constructor(prop: any) {
    super(prop)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    }
  }

  static getDerivedStateFromError = () => {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error: error, errorInfo: errorInfo })
  }

  goBack = () => {
    this.props.history.push('/')
    window.location.reload()
  }

  render(): React.ReactNode {
    const { children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return (
        <div className={styles.container}>
          <div>
            <section>
              <h1 className={styles.errorTitle}>We're sorry...</h1>
              <p>An error occurred while loading this page.</p>
              <IonButton className={styles.nextButton} onClick={this.goBack}>
                Back
              </IonButton>
            </section>
            <section>
              <img src={errorIcon} className={styles.errorIcon} />
            </section>
          </div>
        </div>
      )
    }
    return children
  }
}

export default withRouter(ErrorBoundary)

/* eslint-disable  */

import React from 'react'
import { IonButton, IonSpinner } from '@ionic/react'
import classNames from 'classnames'

import Table from '../../../table/Table'
import { PatientSurveysProps } from './interfaces'

import styles from './styles.module.scss'

const surveyTableColumns = ({
  onSurveyView: onSurvayView,
}: {
  onSurveyView: (id: number) => void
}) => [
  {
    Header: 'Survey Title',
    accessor: 'name',
  },
  {
    Header: 'Date survey Sent',
    accessor: 'createTimestamp',
  },
  {
    Header: 'Survey Progress',
    accessor: 'completed',
    // @ts-ignore
    // Cell: ({ row }) => (row.values.completed ? 'Complete' : 'In Progress'),
    // TODO update this logic when we add feature to assign surveys to patients that can be completed at a future date
    Cell: ({ row }) => (row.values.completed ? 'Complete' : 'Complete'),
  },
  {
    Header: 'Survey Results',
    // @ts-ignore
    Cell: ({
      row: {
        original: { id },
      },
    }: any) => (
      <span className={styles.surveyView} onClick={() => onSurvayView(id)}>
        {id}
      </span>
    ),
  },
]

export function PatientSurveys({
  surveys,
  allSurveys,
  onAssignNewSurveyClick,
  onSurveyView,
}: PatientSurveysProps) {
  const convertISOStringToMonthDay = (date: string) => {
    const tempDate = new Date(date).toString().split(' ')
    const formattedDate = `${new Date(date).getMonth() + 1}/${
      tempDate[2]
    }/${+tempDate[3]}`
    return formattedDate
  }

  const formattedSurveys = surveys?.map((survey) => {
    const matchingSurvey = allSurveys?.find((s) => s.id === survey.surveyId)
    return {
      ...survey,
      ...{
        name: `${survey?.title} V.${survey?.version}`,
        createTimestamp: convertISOStringToMonthDay(survey?.createTimestamp),
      },
    }
  })

  if (!surveys) {
    return (
      <div className={styles.section}>
        <h5
          className={classNames(
            styles.sectionTitle,
            styles.patientSurveysTitle,
          )}
        >
          Member Surveys
          <IonSpinner />
        </h5>
      </div>
    )
  }
  return (
    <div className={styles.section}>
      <h5
        className={classNames(styles.sectionTitle, styles.patientSurveysTitle)}
      >
        Member Surveys
        <IonButton
          size='small'
          className={styles.newSurveyButton}
          onClick={onAssignNewSurveyClick}
        >
          Assign New Survey
        </IonButton>
      </h5>
      <div className={styles.patientSurveys}>
        <Table
          data={formattedSurveys}
          columns={surveyTableColumns({ onSurveyView })}
        />
      </div>
    </div>
  )
}

/* eslint-disable  */

import React, { useCallback, useState, useMemo } from 'react'
import { PatientSMSModal } from './components/PatientSMSModal/PatientSMSModal'

export function usePatientSMSModal(props: {
  patient: any
  onSuccess?: () => void
  onError?: () => void
  onSettled?: () => void
}) {
  const [isPopupVisible, setPopupVisible] = useState(false)

  const handleShow = useCallback(() => {
    setPopupVisible(true)
  }, [])
  const handleHide = useCallback(() => {
    setPopupVisible(false)
  }, [])

  const handleClose = useCallback(() => {
    setPopupVisible(false)
  }, [])

  const component = useMemo(() => {
    return () => (
      <>
        {isPopupVisible && (
          <PatientSMSModal onClose={handleClose} patient={props.patient} />
        )}
      </>
    )
  }, [isPopupVisible])

  return {
    isVisible: isPopupVisible,
    show: handleShow,
    hide: handleHide,
    PatientSMSModal: component,
  }
}

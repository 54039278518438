/* eslint-disable  */

import {
  IonIcon,
  IonButton,
  IonText,
  IonBackdrop,
  IonLoading,
} from '@ionic/react'
import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import Modal from 'react-modal'
import { FormState, useForm } from 'react-hook-form'
import { closeSharp } from 'ionicons/icons'
import classNames from 'classnames'
import { Store } from 'react-notifications-component'
import useUser from '../../../api/useUser'
import { uploadFiles } from '../../../api/useUserDocuments'
import {
  FileListUploadModalFormValues,
  FileListUploadModalProps,
} from './interfaces'

import { FileUploadZone } from '../../components/FileUploadZone'
import { AuthContext } from '../../../contexts/AuthContext'
import { defaultNotificationOptions } from '../../../shared/Notification'
import styles from './styles.module.scss'

export function UploadFilesModal(props: FileListUploadModalProps) {
  const authContext = useContext(AuthContext)
  const userQuery = useUser(authContext.userId)

  const {
    setValue,
    handleSubmit,
    formState,
    control,
    watch,
  } = useForm<FileListUploadModalFormValues>({
    defaultValues: useMemo(
      () => ({
        files: [],
      }),
      [],
    ),
  })
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const handleFileUploadZoneAccept = useCallback((acceptedFiles: File[]) => {
    setAcceptedFiles((prev) => [...prev, ...acceptedFiles])
  }, [])

  const [isFilesUploading, setFilesUploading] = useState(false)

  const handleSubmitClick = useCallback(
    (formValues) => {
      if (acceptedFiles?.length === 0) return

      setFilesUploading(true)
      uploadFiles({
        orgId: (userQuery.data as User).organizationIds[0],
        userId: props.userId,
        createdById: Number(authContext.userId),
        fileList: acceptedFiles,
      })
      .then((response) => {
        const isSuccess = response?.status === 200
        if (isSuccess) {
          props.onSubmit()
        } else {
          Store.addNotification({
            ...defaultNotificationOptions,
            type: 'danger',
            message:
              'Some files were not uploaded properly. Please, try again.',
          })
        }
      })
      .catch((_e) => {
        Store.addNotification({
          ...defaultNotificationOptions,
          type: 'danger',
          message:
            'Some files were not uploaded properly due to network error. Please, try again later.',
        })
      })
      .finally(() => {
        setAcceptedFiles([])
        setFilesUploading(false)
      })
    },
    [acceptedFiles],
  )

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={props.onCancel}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >

      {isFilesUploading && (
        <IonLoading
          isOpen={true}
          message='Please wait until the documents are uploaded.'
        />
      )}
      <IonIcon
        className={styles.closeCross}
        icon={closeSharp}
        onClick={props.onCancel}
      />
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Upload your list</h2>
        <form
          onSubmit={handleSubmit(handleSubmitClick)}
          noValidate
          className={styles.form}
        >
          <div className={classNames(styles.step)}>
            <div className={styles.uploadListTitle}>
              <span className={styles.requiredMark}>*</span>Upload your
              documents:
            </div>
            <p className={styles.uploadListInfo}>
              Drag and drop or click the choose file button to upload your
              list files
            </p>
          </div>
          <div>
            <FileUploadZone
              acceptableFileTypes={'*'}
              onFilesAccepted={handleFileUploadZoneAccept}
              acceptedFiles={acceptedFiles}
            />
          </div>
          <div className={styles.separator}></div>
          
          <div className={styles.buttons}>
            <IonButton
              size='large'
              className={styles.cancelButton}
              onClick={props.onCancel}
            >
              Cancel
            </IonButton>
            <IonButton
              size='large'
              type='submit'
              fill='solid'
              className={styles.submitButton}
            >
              Submit list
            </IonButton>
          </div>
        </form>
      </div>
    </Modal>
  )
}

function renderErrorMessage(
  formState: FormState<FileListUploadModalFormValues>,
  name: string,
) {
  if (name in formState?.errors) {
    return (
      <IonText color='danger'>
        <small>This is a required field</small>
      </IonText>
    )
  }

  return null
}

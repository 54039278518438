/* eslint-disable  */

import React from 'react'
import styles from './Page.module.scss'
import Header from '../header/Header'
export interface PageProps {
  action?: JSX.Element
  body?: JSX.Element
  children?: JSX.Element | JSX.Element[]
  name?: string
  sidebar?: JSX.Element
  hideTopBar?: boolean
}

export default function Page({
  body,
  children,
  name,
  hideTopBar,
}: PageProps): JSX.Element {
  return (
    <div className={styles.pageWrapper}>
      <Header />
      {!hideTopBar && (
        <div className={styles.topBar}>
          <h1 className={styles.name} title={name}>
            <slot name='name'>{name}</slot>
          </h1>
        </div>
      )}
      <div className={styles.content}>{body || children}</div>
    </div>
  )
}

/* eslint-disable */
import React, { FunctionComponent } from 'react'
import 'survey-react/survey.css'
import 'survey-core/modern.min.css'
import * as Survey from 'survey-react'
import { RouteProps } from 'react-router-dom'

Survey.StylesManager.applyTheme('modern')

export const FormDisplay: FunctionComponent<RouteProps> = (
  props: RouteProps,
) => {
  const model = new Survey.Model(props.formTemplate)

  props.setForm(model)

  if (props.formTemplate) {
    model.data = {}
    // model.showNavigationButtons = false;
  }

  if (props.formAnswers) {
    model.data = props.formAnswers
    model.mode = 'display'
  }

  const submitFormHandler = (survey: any, options: any): any => {
    // console.log('Survey results: ' + JSON.stringify(survey.data));
    props.setFormAnswers(survey.data)
  }

  if (props.formTemplate) {
    return <Survey.Survey model={model} onComplete={submitFormHandler} />
  } else {
    return null
  }
}

export default FormDisplay
/* eslint-disable  */

import React from 'react'
import { IonImg } from '@ionic/react'

import FileUploadIcon from '../../../../assets/icon/file.png'

import styles from './styles.module.scss'
import { FileUplaodProps } from '../../interfaces'

export function FileUpload({
  file,
  orgLogo,
  fileInput,
  setFile,
}: FileUplaodProps) {
  return (
    <div className={styles.fileUplaodContainer}>
      {file ? (
        <img src={file as string} className={styles.uploadedImage} />
      ) : (
        <>
          <span className={styles.circle}>
            <IonImg
              className={styles.icon}
              src={orgLogo || FileUploadIcon}
            ></IonImg>
          </span>
          <p className={styles.logoplaceholderLabel}>
            Upload a company profile image (jpg/png)
          </p>
          <input
            type='file'
            {...fileInput}
            accept='image/*'
            onChange={(event) => {
              fileInput.onChange(event)
              if (event.target.files) {
                setFile(URL.createObjectURL(event.target.files[0]))
              }
            }}
            className={styles.fileUploadInput}
          />
        </>
      )}
    </div>
  )
}

/* eslint-disable  */

import React, { useMemo, useState, useCallback } from 'react'
import { useQueryClient } from 'react-query'
import useLanguages from '../../../api/useLanguages'
import { useUpdateOrganization } from '../../../api/useUpdateOrganization'
import { OrganizationData } from '../../../shared/models/Organization'
import { EditOrgModal } from './EditOrgModal'

export function useEditOrgModal(props: {
  org: OrganizationData
  onSuccess?: () => void
  onError?: () => void
  onCancel?: () => void
}) {
  const { data: languages } = useLanguages()

  // Visibility
  const [isModalVisible, setModalIsVisible] = useState(false)
  const queryClient = useQueryClient()

  const handleShow = useCallback(() => {
    setModalIsVisible(true)
  }, [setModalIsVisible])

  const { mutate: mutateUpdateOrg } = useUpdateOrganization(props.org?.id, {
    onSuccess: () => {
      queryClient.invalidateQueries(`organization-${props.org.id}`)
      setModalIsVisible(false)
    },
  })

  const handleUpdateOrg = useCallback(
    (org: OrganizationData) => {
      const data = new FormData()
      if (org.logo && org.logo.length) {
        data.append('file', org.logo[0], org.logo[0]?.name)
      }

      delete org.logo

      const _org = {
        ...org,
        addressLine1: org.address,
        addressLine2: org.state,
      }
      data.append('organization', JSON.stringify(_org))
      mutateUpdateOrg(data)
    },
    [mutateUpdateOrg],
  )
  const handleModalClose = useCallback(() => {
    setModalIsVisible(false)
    // props.onCancel()
  }, [])

  const component = useMemo(
    () => () => (
      <>
        {props.org && languages && isModalVisible && (
          <EditOrgModal
            organization={props.org}
            onSubmit={handleUpdateOrg}
            onCancel={handleModalClose}
          />
        )}
      </>
    ),
    [props.org, languages, isModalVisible, handleUpdateOrg, handleModalClose],
  )

  return {
    EditOrgModal: component,
    isVisible: isModalVisible,
    showEditOrgModal: handleShow,
    dismissEditOrgModal: handleModalClose,
  }
}

/* eslint-disable */
import { useQuery } from 'react-query'
import { BASE_API_URL } from './constants'

import { Language } from '../shared/models/Language'
import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'

const getLanguages = async ({ queryKey }: any) => {
  const [_key, { userId }] = queryKey
  return await fetch(`${BASE_API_URL}/v1/languages`, {
    headers: { userId: userId },
  }).then((response) => response.json())
}

export default function useLanguages() {
  const { userId } = useContext(AuthContext)

  return useQuery<Language[]>(['languages', { userId }], getLanguages, {
    refetchOnWindowFocus: false,
  })
}

/* eslint-disable */
import { useQuery } from 'react-query'
import { BASE_API_URL } from './constants'

const fetchPatientMessages = (patientId: string | number) => async () => {
  return fetch(`${BASE_API_URL}/v1/messages/getPatientMessages/${patientId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.json()
  })
}

export const usePatientMessages = (id: string | number, config = {}) => {
  return useQuery('v1/messages/getPatientMessages', fetchPatientMessages(id), {
    ...config,
  })
}

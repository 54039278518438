/* eslint-disable  */
import fetchIntercept from 'fetch-intercept'
import { reAuth } from './authentication/auth'

const fetchInterceptor = fetchIntercept.register({
  request: function (url, config) {
    let token = localStorage.getItem('token')
    let userId = localStorage.getItem('u=')
    
    let origConfig = config

    if (origConfig) {
      if (origConfig.headers && token) {
        origConfig.headers['Authorization'] = 'Bearer ' + token
      } else {
        if (token && userId) {
          origConfig['headers'] = {
            userId: userId,
            Authorization: 'Bearer ' + token,
          }
        }
      }
    } else {
      if (token && userId) {
        origConfig = {
          headers: {
            userId: userId,
            Authorization: 'Bearer ' + token,
          },
        }
      }
    }
    return [url, origConfig]
  },

  requestError: function (error) {
    return Promise.reject(error)
  },

  response: function (response) {
    if (response.status === 401 && !response.url.endsWith('refresh-token')) {
      reAuth().then(() => {
        window.location.reload()
      })
    }
    return response
  },

  responseError: function (error) {
    return Promise.reject(error)
  },
})

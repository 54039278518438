/* eslint-disable  */

import React from 'react'
import Modal from 'react-modal'
import { IonText, IonRow, IonIcon } from '@ionic/react'
import { closeSharp } from 'ionicons/icons'

import { EditUserModalProps } from './interfaces'
import styles from './styles.module.scss'
import { OrganizationForm } from '../../../shared/OrganizationForm'
import { useFetchOrgLogo } from '../../../api/useOrganization'

export function EditOrgModal({
  organization,
  onCancel,
  onSubmit,
}: EditUserModalProps) {
  const { data: orgLogo } = useFetchOrgLogo(
    organization.id,
    organization.imageFilename,
    {
      enabled: !!organization.id && !!organization.imageFilename,
    },
  )

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={onCancel}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      <IonIcon
        className={styles.closeCross}
        icon={closeSharp}
        onClick={onCancel}
      />
      <div className={styles.wrapper}>
        <IonRow>
          <IonText className={styles.title}>
            Update your service information
          </IonText>
          <IonText className={styles.subTitle}>
            Provide your organization's information below
          </IonText>
        </IonRow>

        <OrganizationForm
          isEdit
          organization={organization}
          orgLogo={orgLogo}
          onSubmit={onSubmit}
        />
      </div>
    </Modal>
  )
}

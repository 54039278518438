import { ServiceRequestStatus } from '../models/ServiceRequestStatuses'

const SERVICE_REQUESTS_TABS = [
  {
    type: ServiceRequestStatus.REQUESTED,
    label: 'Requested',
  },
  {
    type: ServiceRequestStatus.OPEN,
    label: 'Open',
  },
  {
    type: ServiceRequestStatus.IN_PROGRESS,
    label: 'In Progress',
  },
  {
    type: ServiceRequestStatus.PENDING,
    label: 'Pending',
  },
  {
    type: ServiceRequestStatus.COMPLETED,
    label: 'Completed',
  },
]

export default SERVICE_REQUESTS_TABS

/* eslint-disable */
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '../contexts/AuthContext'
import { ServiceRequest } from '../shared/models/ServiceRequest'
import { BASE_API_URL } from './constants'

const getUserServiceRequests = async ({ queryKey }: any) => {
  const [_key, { userId, currentUserId }] = queryKey
  return await fetch(`${BASE_API_URL}/v1/users/${userId}/service-requests`, {
    method: 'GET',
    headers: {
      userId: currentUserId,
    },
  }).then((response) => response.json())
}

export default function useUserServiceRequests(
  userId: string | number,
  currentUserId: string | number,
) {
  return useQuery<ServiceRequest[]>(
    getUserServiceRequestsQueryKey(userId, currentUserId),
    getUserServiceRequests,
  )
}

export function getUserServiceRequestsQueryKey(
  userId: string | number,
  currentUserId: string | number,
): any[] {
  return [`user-${userId}-service-requests`, { userId: userId, currentUserId }]
}

export function updateUserServiceRequestInCache(
  cachedData: ServiceRequest[] | undefined,
  updated: ServiceRequest,
) {
  if (!cachedData) {
    return cachedData
  }

  return cachedData.map((serviceRequest) =>
    serviceRequest.id === updated.id ? updated : serviceRequest,
  )
}

/* eslint-disable */
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonListHeader,
  IonText,
} from '@ionic/react'
import { chevronDown, chevronUp } from 'ionicons/icons'
import React, { useRef, useState } from 'react'
import styles from './styles.module.scss'

interface AccordionListItemProps {
  header: string | JSX.Element
  children: JSX.Element | JSX.Element[]
  idx?: number | string
}

export default function AccordionListItem(
  props: AccordionListItemProps,
): JSX.Element {
  const [open, setOpen] = useState(false)
  const ref = useRef<null | HTMLIonItemElement>(null)

  return (
    <li key={props.idx}>
      <IonItem
        onClick={() => setOpen((prev) => !prev)}
        style={{ cursor: 'pointer' }}
        lines='none'
      >
        {props.header}
        <IonIcon
          slot='end'
          icon={open ? chevronUp : chevronDown}
          color='dark'
        />
      </IonItem>
      <IonItem
        className={styles.listItem}
        ref={ref}
        style={
          open ? { height: ref?.current?.scrollHeight } : { height: '0px' }
        }
      >
        {props.children}
      </IonItem>
    </li>
  )
}

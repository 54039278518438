/* eslint-disable */
import { useQuery } from 'react-query'
import { BASE_API_URL, ORGANIZATION, PATIENT, ACTIVE } from './constants'
import { jsonToQueryString } from './utils'

const getPatients = async ({ queryKey }) => {
  const [_key, { orgId, userId, serviceType, serviceName, page, size, sortBy, filter }] = queryKey
  if (orgId == null) {
    return []
  }

  const queryString = jsonToQueryString({
    entityId: orgId,
    entityType: ORGANIZATION,
    role: PATIENT,
    page: page,
    size: size,
    sort: sortBy,
    status: ACTIVE,
    name: filter,
    ...(serviceType && { serviceType }),
    ...(serviceName && { serviceName }),
  })

  return fetch(`${BASE_API_URL}/v1/organizations/${orgId}/members?${new URLSearchParams(queryString)}`, {
    headers: {
      userId: userId,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.status)
    }

    return response.json()
  })
}

export default function usePatients(
  { userId, orgId, serviceType = '', serviceName = '', page = 0, size = 50, sortBy = 'user.firstName,desc', filter = '' },
  {
    refetchOnWindowFocus = false,
    onSuccess = (_res) => {
      // success
    },
    onCompleted = (_res) => {
      nextPage = page + 1
      useQuery(
        [`patients-${orgId}`, { orgId, userId, serviceType, serviceName, nextPage, size, sortBy, filter }],
        getPatients,
        { refetchOnWindowFocus, onError, onSuccess, onCompleted, keepPreviousData: true, staleTime: Infinity },
      )
    },
    onError = (_error) => {
      // error
    },
  },
) {

  return useQuery(
    [`patients-${orgId}`, { orgId, userId, serviceType, serviceName, page, size, sortBy, filter }],
    getPatients,
    { refetchOnWindowFocus, onError, onSuccess, onCompleted, keepPreviousData: true, staleTime: Infinity },
  )
}
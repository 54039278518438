/* eslint-disable */

import React, { useContext } from 'react'
import {
  IonCol,
  IonGrid,
  IonHeader,
  IonImg,
  IonRow,
  IonText,
  IonToolbar,
} from '@ionic/react'
import './styles/headerStyles.css'
import HealthOpXLogo from '../public/assets/logo/LogoHeader.png'
import { AuthContext } from '../contexts/AuthContext'
import useUser from '../api/useUser'
import ProfileIcon from '../profile/ProfileIcon'
import useOrganization from '../api/useOrganization'

export default function Header(): JSX.Element {
  const { userId } = useContext(AuthContext)
  const { data: userData } = useUser(userId)
  const { data: organization } = useOrganization(userData?.organizationIds[0])

  let userName = ''
  let userStatus = ''
  if (userData) {
    userName = userData?.firstName + ' ' + userData?.lastName
    userStatus = userData?.userType
  }

  return (
    <IonHeader>
      <IonToolbar style={{ paddingRight: '15px' }}>
        <IonImg src={HealthOpXLogo} className='toolbarLogo' />
        <IonGrid slot='end'>
          <IonRow>
            <IonCol className='ion-no-padding'>
              <IonText className='ion-text-end toolbarOrganization'>
                {organization ? organization.name : ''}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className='ion-no-padding'>
              <IonText className='ion-text-end toolbarUserInfo'>
                {userName + ', ' + userStatus}
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <ProfileIcon size={'50'} userName={userName} />
      </IonToolbar>
    </IonHeader>
  )
}

/* eslint-disable */
import { BASE_API_URL } from './constants'
import { jsonToQueryString } from './utils'

export function sendFormList({
  orgId,
  surveyIds: surveyIds,
  file,
  userId,
}: {
  orgId: number
  surveyIds: number[]
  file: File
  userId: string
}): Promise<Response> {
  const formData = new FormData()
  console.log(surveyIds)
  console.log(orgId)
  formData.append('file', file, file.name)

  return fetch(
    `${BASE_API_URL}/v1/survey-answers/${surveyIds}/upload-responses`,
    {
      method: 'POST',
      body: formData,
      headers: {
        userId: userId,
      },
    },
  )
}

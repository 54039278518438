/* eslint-disable */
import { useMutation } from 'react-query'
import { BASE_API_URL } from './constants'

interface IParams {
  onError: (err: Error) => void
  onSuccess: (response: Response) => void
}

export const createOrganization = async (data: any) => {
  const res = await fetch(`${BASE_API_URL}/v1/organizations`, {
    method: 'POST',
    body: data,
  })
  if (!res.ok) {
    throw new Error('Something went wrong! Please try again')
  }

  return res.json()
}

const createOrgService = async (data: any) => {
  const response = await fetch(`${BASE_API_URL}/v1/services`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    throw new Error('Something went wrong! Please try again')
  }

  return response.json()
}

export const useCreateService = ({ onError, onSuccess }: IParams) => {
  return useMutation(createOrgService, {
    onError,
    onSuccess,
  })
}

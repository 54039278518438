/* eslint-disable */
import { useMutation } from 'react-query'
import { BASE_API_URL } from './constants'

const updateOrganization = (id?: string | number) => async (data: FormData) => {
  const response = await fetch(`${BASE_API_URL}/v1/organizations/${id}`, {
    method: 'PUT',
    body: data,
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.json()
}

export const useUpdateOrganization = (
  organizationId?: string | number,
  config = {},
) => {
  return useMutation(updateOrganization(organizationId), { ...config })
}

/* eslint-disable */
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import * as SurveyCreator from 'survey-creator-react'
import 'survey-core/survey.i18n.js'
import 'survey-creator-core/survey-creator-core.i18n.js'
import 'survey-core/defaultV2.css'
import 'survey-creator-core/survey-creator-core.css'
import styles from '../styles.module.scss'
import { Serializer } from 'survey-core'
import { ICreatorOptions } from 'survey-creator-core'
import useOrganizationServices from 'src/api/useOrganizationServices'
import { OrganizationServices } from 'src/shared/models/OrganizationServices'
import { createSurvey, updateSurvey } from 'src/api/useSurvey'
import * as Survey from 'survey-react'
import { useHistory } from 'react-router-dom'
import { SuccessModal } from 'src/shared/SuccessModal'
import { ErrorModal } from 'src/shared/ErrorModal'
import 'survey-core/modern.min.css'

const creatorOptions: ICreatorOptions = {
  haveCommercialLicense: true,
  showLogicTab: true,
  themeForPreview: 'modern',
}

interface SurveyJSCreatorProps {
  initialForm: string | null
  organizationId: string
}

const placeholderSurvey = {
  title: 'Enter Title Here ',
  logoPosition: 'right',
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'text',
          name: 'sampleQuestion',
          title: 'Sample Question Title',
        },
      ],
    },
  ],
}

export const SurveyJSCreator: FunctionComponent<SurveyJSCreatorProps> = (
  props: any,
) => {
  const history = useHistory()
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false)
  const [isErrorModalVisible, setErrorModalVisible] = useState(false)
  const [modalConfirmationMessage, setModalConfirmationMessage] = useState('')
  const [services, setServices] = useState<OrganizationServices[]>([])
  const { isFetching, isSuccess, data } = useOrganizationServices(
    Number(props.organizationId),
    {
      refetchOnWindowFocus: false,
    },
  )

  const updateDraft = (newDraft: string) => {
    if (!!newDraft) {
      localStorage.setItem('form-pending', newDraft)
    }
  }

  useMemo(() => {
    if (isSuccess && !!data) setServices(data)
  }, [isFetching, data])

  const handleSuccessModalClose = useCallback(() => {
    setSuccessModalVisible(false)
    localStorage.removeItem('form-pending')
    localStorage.removeItem('form-edit')
    history.push('/forms-library')
  }, [])

  const handleErrorModalClose = useCallback(() => {
    setErrorModalVisible(false)
  }, [])

  const creator = new SurveyCreator.SurveyCreator(creatorOptions)
  creator.showSaveButton = true
  let toEdit = placeholderSurvey

  if (props.formDraft) toEdit = JSON.parse(props.formDraft) as any
  else if (props.initialForm)
    toEdit = (JSON.parse(props.initialForm) as any)?.data
  creator.JSON = toEdit

  creator.onModified.add((sender, options) => {
    if (options.type === 'PROPERTY_CHANGED') {
      updateDraft(JSON.stringify(creator.JSON))
    }
  })

  creator.saveSurveyFunc = (): void => {
    const editForm = props.initialForm
    const currentDraft = localStorage.getItem('form-pending')
    if (!!editForm && !!currentDraft) {
      let editFormJSON = JSON.parse(editForm) as any
      updateSurvey({
        id: editFormJSON.id,
        uniqueSurveyId: editFormJSON.uniqueSurveyId,
        version: editFormJSON.version,
        title: editFormJSON.data.title,
        data: JSON.parse(currentDraft) as any,
        language: 'English',
        organizationId: props.organizationId,
        originalSurveyId: editFormJSON.id,
      })
        .then((data) => {
          setModalConfirmationMessage('Form saved successfully')
          setSuccessModalVisible(true)
        })
        .catch((err) => {
          setModalConfirmationMessage('Error encountered, could not save form')
          setErrorModalVisible(true)
        })
    } else {
      if (!!currentDraft) {
        const model = new Survey.Model(JSON.parse(props.formDraft) as any)
        createSurvey({
          id: null,
          uniqueSurveyId: null,
          version: null,
          title: model.localizableStrings.title.values.default,
          data: model,
          language: 'English',
          organizationId: props.organizationId,
          originalSurveyId: null,
        })
          .then((data) => {
            setModalConfirmationMessage('Form created successfully')
            setSuccessModalVisible(true)
          })
          .catch((err) => {
            setModalConfirmationMessage(
              'Error encountered, could not save form',
            )
            setErrorModalVisible(true)
          })
      }
    }
  }

  addCustomPropertiesToGrid({ services })

  function addCustomPropertiesToGrid(resources: any) {
    Serializer.addProperty('question', {
      name: 'surveyAction',
      displayName: 'Survey Action',
      type: 'itemValues',
      choices: [
        { value: 'none', text: '' },
        { value: 'createServiceRequest', text: 'Create Service Request' },
      ],
      category: 'general',
      categoryIndex: 3,
    })

    Serializer.addProperty('question', {
      name: 'service',
      displayName: 'Select Service To Create on Completion',
      type: 'itemValues',
      dependsOn: ['surveyAction'],
      visibleIf: function (obj: any) {
        return (
          obj['surveyAction'] === 'createServiceRequest' && services.length > 0
        )
      },
      choices: resources.services.map((e) => ({ value: e.id, text: e.name })),
      category: 'general',
      categoryIndex: 4,
    })
  }

  return (
    <>
      <div className={styles.formCreator}>
        <SurveyCreator.SurveyCreatorComponent creator={creator} />
      </div>
      {isSuccessModalVisible && (
        <SuccessModal
          onClose={handleSuccessModalClose}
          text={modalConfirmationMessage}
        />
      )}
      {isErrorModalVisible && (
        <ErrorModal
          onDismiss={handleErrorModalClose}
          text={modalConfirmationMessage}
        />
      )}
    </>
  )
}

export default SurveyJSCreator

/* eslint-disable */
import { useQuery } from 'react-query'
import { BASE_API_URL } from './constants'
import { fetchFile } from './utils'

type Params = {
  onSuccess: (response: string) => void
}

async function fetchSRFile({ queryKey }: any) {
  const [key, { serviceRequestId, name }] = queryKey
  const url = `${BASE_API_URL}/v1/service-requests/${serviceRequestId}/file`
  return fetchFile(url, name)
}

async function fetchNotesFile({ queryKey }: any) {
  const [key, { noteId, name }] = queryKey
  const url = `${BASE_API_URL}/v1/notes/${noteId}/file`
  return fetchFile(url, name)
}

export function useFetchSRFile(
  serviceRequestId: string,
  name: string,
  { onSuccess }: Params,
) {
  return useQuery(
    ['service-request-file', { serviceRequestId, name }],
    fetchSRFile,
    { onSuccess, enabled: false },
  )
}

export function useFetchNotesFile(
  noteId: string,
  name: string,
  { onSuccess }: Params,
) {
  return useQuery(
    ['service-request-note-file', { noteId, name }],
    fetchNotesFile,
    { onSuccess, enabled: false },
  )
}

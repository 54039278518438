/* eslint-disable  */

import React, { useCallback, useMemo, useState } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Modal from 'react-modal'
import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import { closeSharp } from 'ionicons/icons'
import { Controller, useForm, Validate } from 'react-hook-form'
import classNames from 'classnames'

import { EditUserModalProps } from './interfaces'
import { UserTypeFormatted } from '../models/UserType'
import { User } from '../models/User'

import styles from './styles.module.scss'

export function EditUserModal({
  user,
  languages,
  onCancel,
  onSubmit,
}: EditUserModalProps) {
  const {
    handleSubmit,
    formState,
    register,
    control,
    setValue,
    watch,
  } = useForm<User>({
    defaultValues: useMemo(
      () => ({
        ...user,
        sendPushNotifications: !!user.sendPushNotifications,
        sendSmsTexts: !!user.sendSmsTexts,
        language: 'English',
      }),
      [user],
    ),
  })

  const languageChoosen = watch('language')
  const handleLanguageChoose = useCallback(
    (e: CustomEvent) => {
      // @ts-ignore
      const language = e.target.value
      setValue('language', language)
    },
    [setValue],
  )

  const dateValidator = useCallback<Validate<string | null>>((value) => {
    if (!value || value === 'Invalid Date') {
      return 'This is a required field'
    }
    return true
  }, [])

  const [phone, setPhone] = useState(user.phoneNumber)

  const setPhoneNumber = (data: any) => {
    setPhone(data)
  }

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={onCancel}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      <IonIcon
        className={styles.closeCross}
        icon={closeSharp}
        onClick={onCancel}
      />
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          {UserTypeFormatted[user.userType]} Profile Update
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.row}>
            <IonItem
              className={classNames(styles.firstName, {
                [styles.itemError]: formState.errors.firstName,
              })}
            >
              <IonLabel position='floating'>First Name</IonLabel>
              <IonInput
                type='text'
                autocomplete='off'
                {...register('firstName', { required: true })}
              />
            </IonItem>
            <IonItem
              className={classNames(styles.lastName, {
                [styles.itemError]: formState.errors.lastName,
              })}
            >
              <IonLabel position='floating'>Last Name</IonLabel>
              <IonInput
                type='text'
                autocomplete='off'
                {...register('lastName', { required: true })}
              />
            </IonItem>
          </div>
          <div className={styles.row}>
            <IonItem
              className={classNames(styles.language, {
                [styles.itemError]: formState.errors.language,
              })}
            >
              <IonLabel position='floating'>Language</IonLabel>
              <IonSelect
                value={languageChoosen}
                defaultValue={languageChoosen}
                onIonChange={handleLanguageChoose}
              >
                {languages.map((language) => (
                  <IonSelectOption key={language.name} value={language.name}>
                    {language.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </div>
          <div className={styles.row}>
            <IonItem
              className={classNames(styles.email, {
                [styles.itemError]: formState.errors.email,
              })}
            >
              <IonLabel position='floating'>Email</IonLabel>
              <IonInput
                type='text'
                autocomplete='off'
                {...register('email', { required: false })}
              />
            </IonItem>
          </div>
          <div className={styles.row}>
            <IonItem
              className={classNames(styles.phoneNumber, {
                [styles.itemError]: formState.errors.phoneNumber,
              })}
            >
              <IonLabel position='stacked'>Phone Number</IonLabel>
              <PhoneInput
                country={'US'}
                defaultCountry='US'
                international
                countryCallingCodeEditable={false}
                displayInitialValueAsLocalNumber={true}
                autoComplete='tel'
                type='tel'
                placeholder='Enter phone number'
                value={phone}
                {...register('phoneNumber', {
                  minLength: 12,
                  setValueAs: (v) => v.replace(/ /g, ''),
                })}
                onChange={setPhoneNumber}
                limitMaxLength={true}
                inputExtraProps={{
                  name: 'phoneNumber',
                  required: true,
                  autoFocus: true,
                  country: 'us',
                }}
              />
            </IonItem>
            <IonItem
              className={classNames(styles.dateOfBirth, {
                [styles.itemError]: formState.errors.dateOfBirth,
              })}
            >
              <IonLabel position='stacked'>D.O.B.</IonLabel>
              <IonInput
                type='date'
                {...register('dateOfBirth', {
                  required: false,
                  validate: dateValidator,
                })}
              />
              <IonIcon name='cake' slot='end' />
            </IonItem>
          </div>
          <div className={styles.row}>
            <IonItem
              className={classNames(styles.addressLine1, {
                [styles.itemError]: formState.errors.addressLine1,
              })}
            >
              <IonLabel position='floating'>Address Line 1</IonLabel>
              <IonInput
                type='text'
                autocomplete='off'
                {...register('addressLine1')}
              />
            </IonItem>
            <IonItem
              className={classNames(styles.addressLine2, {
                [styles.itemError]: formState.errors.addressLine2,
              })}
            >
              <IonLabel position='floating'>Address Line 2</IonLabel>
              <IonInput
                type='text'
                autocomplete='off'
                {...register('addressLine2')}
              />
            </IonItem>
          </div>
          <div className={styles.row}>
            <IonItem
              className={classNames(styles.city, {
                [styles.itemError]: formState.errors.city,
              })}
            >
              <IonLabel position='floating'>City</IonLabel>
              <IonInput type='text' autocomplete='off' {...register('city')} />
            </IonItem>
            <IonItem
              className={classNames(styles.state, {
                [styles.itemError]: formState.errors.state,
              })}
            >
              <IonLabel position='floating'>State</IonLabel>
              <IonInput type='text' autocomplete='off' {...register('state')} />
            </IonItem>
            <IonItem
              className={classNames(styles.zipCode, {
                [styles.itemError]: formState.errors.zipCode,
              })}
            >
              <IonLabel position='floating'>Zip Code</IonLabel>
              <IonInput
                type='number'
                autocomplete='off'
                {...register('zipCode')}
              />
            </IonItem>
          </div>
          <div className={styles.row}>
            <Controller
              name='sendPushNotifications'
              control={control}
              render={(props) => (
                <IonItem lines='none'>
                  <IonCheckbox
                    checked={props.field.value}
                    onIonChange={(e) => {
                      const isChecked = !!e?.detail.checked
                      props.field.onChange(isChecked)
                    }}
                  />
                  <IonLabel className={styles.checkboxLabel}>
                    Send Push Notifications
                  </IonLabel>
                </IonItem>
              )}
            />
            <Controller
              name='sendSmsTexts'
              control={control}
              render={(props) => (
                <IonItem lines='none'>
                  <IonCheckbox
                    checked={props.field.value}
                    onIonChange={(e) => {
                      const isChecked = !!e?.detail.checked
                      props.field.onChange(isChecked)
                    }}
                  />
                  <IonLabel className={styles.checkboxLabel}>
                    Send SMS Texts
                  </IonLabel>
                </IonItem>
              )}
            />
          </div>
          <div className={styles.buttons}>
            <IonButton
              shape='round'
              className={styles.cancelButton}
              onClick={onCancel}
            >
              Cancel
            </IonButton>
            <IonButton
              type='submit'
              shape='round'
              className={styles.submitButton}
            >
              Submit
            </IonButton>
          </div>
        </form>
      </div>
    </Modal>
  )
}

/* eslint-disable */

import React from 'react'
import styles from '../styles.module.scss'

const Step = ({
  step,
  children,
}: {
  step: string
  children: any
}): JSX.Element => {
  return (
    <div className={styles.stepRow}>
      <span className={styles.circle}>{step}</span>
      <p className={styles.stepTitle}>{children}</p>
    </div>
  )
}

export default Step

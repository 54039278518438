/* eslint-disable */
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '../contexts/AuthContext'
import { BASE_API_URL } from './constants'

const getUserSurveys = async ({ queryKey }) => {
  const [_key, { userId }] = queryKey
  return await fetch(`${BASE_API_URL}/v1/users/${userId}/surveys`, {
    method: 'GET',
  }).then((response) => response.json())
}

export default function useUserSurveys(userId) {
  return useQuery(
    [`user-${userId}-surveys`, { userId: userId }],
    getUserSurveys,
  )
}

/* eslint-disable */
import React, { ComponentPropsWithRef, useEffect, useState } from 'react'
import 'survey-react/survey.css'
import { SurveyModel } from 'survey-core'
import * as SurveyAnalytics from 'survey-analytics'
import 'survey-analytics/survey.analytics.css'

var timer = undefined
var isUpdating = false
var currMin = undefined
var currMax = undefined
function createValsUpdater(parent, vizPanel, data) {
  return function () {
    let sliders = parent.getElementsByTagName('input')
    let slide1 = parseFloat(sliders[0].value)
    let slide2 = parseFloat(sliders[1].value)
    if (slide1 > slide2) {
      let tmp = slide2
      slide2 = slide1
      slide1 = tmp
    }
    currMin = slide1
    currMax = slide2
    let displayElement = parent.getElementsByClassName('rangeValues')[0]
    displayElement.innerHTML =
      new Date(slide1).toLocaleDateString() +
      ' - ' +
      new Date(slide2).toLocaleDateString()
    displayElement = parent.getElementsByClassName('rangeValuesCount')[0]
    displayElement.innerHTML =
      vizPanel.dataProvider.filteredData.length + ' item(s)'
    if (isUpdating) {
      return
    }
    if (timer !== undefined) {
      clearTimeout(timer)
      timer = undefined
    }
    timer = setTimeout(function () {
      isUpdating = true
      vizPanel.setFilter('submissionDate', { start: slide1, end: slide2 })
      timer = undefined
      isUpdating = false
    }, 100)
  }
}
function setupDateRange(vizPanel, data) {
  vizPanel.registerToolbarItem('dateRange', (toolbar) => {
    let itemRoot = undefined
    if (data.length > 10 && !!data[0].submissionDate) {
      let min = data[0].submissionDate
      let max = data[data.length - 1].submissionDate
      itemRoot = document.createElement('div')
      itemRoot.style.display = 'inline-block'
      itemRoot.innerHTML =
        `<div class="range-slider">
        <span class="rangeValues"></span>
        <input value="` +
        currMin +
        `" min="` +
        min +
        `" max="` +
        max +
        `" type="range">
        <input value="` +
        currMax +
        `" min="` +
        min +
        `" max="` +
        max +
        `" type="range">
        <div class="rangeValuesCount"></div>
      </div>`
      toolbar.appendChild(itemRoot)
      let slider1 = itemRoot.children[0].children[1]
      let slider2 = itemRoot.children[0].children[2]
      slider1.oninput = createValsUpdater(itemRoot.children[0], vizPanel, data)
      slider1.oninput()
      slider2.oninput = createValsUpdater(itemRoot.children[0], vizPanel, data)
      slider2.oninput()
    }
    return itemRoot
  })
}

function CustomTextVisualizer(question, data) {
  let values = []

  let dataProvider = new SurveyAnalytics.DataProvider(data)

  dataProvider.getData = function (visualizer) {
    let result = {}
    this.filteredData.forEach(function (row) {
      let rowValue = row[visualizer.question.name]
      if (rowValue !== undefined) {
        console.log(rowValue)
        if (result[rowValue] === undefined) {
          result[rowValue] = 1
        } else {
          result[rowValue]++
        }
      }
    })

    values.push.apply(values, Object.keys(result))
    return [
      values.map(function (value) {
        return result[value]
      }),
    ]
  }

  var visualizer = new SurveyAnalytics.SelectBasePlotly(
    question,
    data,
    {
      dataProvider: dataProvider,
      labelTruncateLength: 27,
    },
    'textChartVisualizer',
  )
  visualizer.getValues = function () {
    return values
  }
  visualizer.getLabels = function () {
    return values
  }

  return visualizer
}

SurveyAnalytics.VisualizationManager.unregisterVisualizer(
  'text',
  SurveyAnalytics.WordCloud,
)

SurveyAnalytics.VisualizationManager.registerVisualizer(
  'text',
  CustomTextVisualizer,
)
SurveyAnalytics.localization.locales['en']['visualizer_textChartVisualizer'] =
  'Text as Chart'

function CustomTotalVisualizer(question, data, options) {
  let getData = function (visualizer) {
    let result = 0
    visualizer.data.forEach(function (row) {
      let rowValue = row[visualizer.question.name]
      if (rowValue !== undefined) {
        if (typeof rowValue === 'string' && !isNaN(+rowValue))
          result += +rowValue
        else if (!isNaN(rowValue)) {
          result += +rowValue
        }
      }
    })

    return result
  }

  let renderContent = (contentContainer, visualizer) => {
    let data2render = getData(visualizer)
    let totalEl = document.createElement('div')
    let totalTextEl = document.createElement('span')
    totalTextEl.innerText = 'Total: '
    let totalValEl = document.createElement('span')
    totalValEl.innerText = data2render.toString()
    totalEl.appendChild(totalTextEl)
    totalEl.appendChild(totalValEl)
    contentContainer.appendChild(totalEl)
  }

  return new SurveyAnalytics.VisualizerBase(
    question,
    data,
    { renderContent: renderContent, dataProvider: options.dataProvider },
    'totalVisualizer',
  )
}
SurveyAnalytics.localization.locales['en']['visualizer_totalVisualizer'] =
  'Total Amount'

SurveyAnalytics.VisualizationManager.registerVisualizer(
  'text',
  CustomTotalVisualizer,
)

SurveyAnalytics.VisualizationManager.registerVisualizer(
  'number',
  CustomTotalVisualizer,
)

SurveyAnalytics.PlotlySetup.onPlotCreating.add(function (visualizer, options) {
  if (
    visualizer.chartType == 'bar' ||
    visualizer.chartType == 'stackedbar' ||
    visualizer.chartType == 'scatter'
  ) {
    options.layout.xaxis['tickformat'] = ',d'
    options.layout.font['size'] = 12
  }
})

export const FormAnalytics: React.FC<ComponentPropsWithRef<any>> = (props) => {
  const [vizPanel, setVizPanel] = useState(null)
  const { selectedForm, submissions } = props

  useEffect(() => {
    if (vizPanel) {
      vizPanel.render('chartContainer')
    }

    return () => {
      document.getElementById('chartContainer').innerHTML = ''
    }
  }, [vizPanel])

  if (
    !vizPanel &&
    !!selectedForm &&
    selectedForm.data &&
    !!submissions &&
    submissions.length > 0
  ) {
    const model = new SurveyModel(selectedForm.data)

    function randomDate(start, end) {
      return new Date(
        start.getTime() + Math.random() * (end.getTime() - start.getTime()),
      )
    }
    const data =
      submissions?.length > 0
        ? submissions?.map((submission) => {
            submission.data.submissionDate = Date.parse(
              submission.createTimestamp,
            )

            return submission.data
          })
        : []

    data.sort(function (d1, d2) {
      return d1.submissionDate > d2.submissionDate
    })
    currMin = data[0].submissionDate
    currMax = data[data.length - 1].submissionDate

    let visPanelChart = new SurveyAnalytics.VisualizationPanel(
      model.getAllQuestions(),
      data,
      {
        haveCommercialLicense: true,
        allowExperimentalFeatures: true,
        labelTruncateLength: 27,
        allowTopNAnswers: true,
      },
    )
    setupDateRange(visPanelChart, data)
    visPanelChart.showHeader = true
    setVizPanel(visPanelChart)
  }

  return <div id='chartContainer'></div>
}

export default FormAnalytics

/* eslint-disable  */
export const customStyles = {
  option: (provided: any) => ({
    ...provided,
    '&:hover': {
      backgroundColor: '#eeedee',
    },
    color: '#000',
    backgroundColor: '#fff',
    textAlign: 'left',
  }),
  control: (provided: any) => {
    return {
      ...provided,
      backgroundColor: '#fff',
      border: 0,
      fontFamily: 'Arial, Helvetica, sans-serif',
    }
  },
  menuPortal: (base: any) => ({ ...base, zIndex: 999 }),
}

/* eslint-disable  */

import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  IonBackdrop,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRouterLink,
  IonText,
  IonTextarea,
} from '@ionic/react'
import {
  checkmarkOutline,
  closeSharp,
  caretDownOutline,
  documentTextOutline,
} from 'ionicons/icons'
import Modal from 'react-modal'
import classNames from 'classnames'

import {
  formatTimestampToFullDate,
  formatTimestampToFullDatetime,
} from '../utils/formatTimestamp'
import { getNextServiceRequestStatusesWithTips } from '../utils/getNextServiceRequestStatusesWithTips'
import {
  ServiceRequestStatus,
  ServiceRequestStatusText,
} from '../models/ServiceRequestStatuses'
import { ServiceRequestReviewModalProps } from './interfaces'

import styles from './styles.module.scss'
import ProfileIcon from '../../profile/ProfileIcon'
import NotesActions from './components/NotesActions'
import { useFetchSRFile } from '../../api/useDownloadFile'
import Note from './components/Note'
import { Employee } from '../models/Employee'
import { SelectEmployee } from '../SelectEmployee'

export function ServiceRequestReviewModal(
  props: ServiceRequestReviewModalProps,
) {
  const {
    serviceRequest,
    serviceRequestNotes,
    statusError,
    isEmployeesLoading,
  } = props
  const [file, setFile] = useState<File>()
  const [notes, setNotes] = useState(serviceRequestNotes)
  const textAreaRef = useRef<HTMLIonTextareaElement>(null)
  const handleNewCommentSubmit = useCallback(() => {
    // @ts-ignore
    const enteredText = (textAreaRef?.current?.value || '').trim()
    if (enteredText || file) {
      // @ts-ignore
      textAreaRef.current.value = ''
      props.onCommentAdd(enteredText, file as File)
      setFile(undefined)
    }
  }, [props, file])

  const [statusPopoverState, setStatusPopoverState] = useState({
    showPopover: false,
    event: undefined,
  })

  const [employeePopoverState, setEmployeePopoverState] = useState({
    showPopover: false,
    event: undefined,
  })

  const { refetch: downloadSRFile } = useFetchSRFile(
    serviceRequest.id,
    serviceRequest.fileName,
    {
      onSuccess: (fileUrl: string) => {
        const a = document.createElement('a')
        a.href = fileUrl
        a.download = serviceRequest.fileName
        a.click()
      },
    },
  )

  const handleStatusChange = useCallback(
    (e) => {
      const { value } = e.target.dataset

      if (value) {
        setStatusPopoverState({
          showPopover: false,
          event: undefined,
        })
        props.onStatusChange(value as ServiceRequestStatus)
      }
    },
    [props.serviceRequest.status, props.onStatusChange],
  )

  const handleEmployeeChange = useCallback(
    (e) => {
      e.persist()
      const { id } = e.target.closest('[data-id]').dataset
      if (id) {
        setEmployeePopoverState({
          showPopover: false,
          event: undefined,
        })
        props.onEmployeeChange(id)
      }
    },
    [props.serviceRequest.status, props.onEmployeeChange],
  )

  const nextServiceRequestStatuses = getNextServiceRequestStatusesWithTips(
    props.serviceRequest.status,
    {
      isAssigned: !!props.serviceRequest.assignee,
    },
  )

  const renderSRFile = (name: string) => {
    if (name) {
      return (
        <span>
          {serviceRequest.description && ' - '}
          <a onClick={() => downloadSRFile()} className={styles.fileLink}>
            {name}
          </a>
        </span>
      )
    }
  }

  const onSelectSMSTranscripts = () => {
    props.onSelectSMSTranscripts(serviceRequest)
  }

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      onRequestClose={props.onClose}
    >
      {props.disabled ? <IonBackdrop /> : null}
      <IonIcon
        className={styles.closeCross}
        icon={closeSharp}
        onClick={props.onClose}
      />
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          {serviceRequest.patient
            ? `${serviceRequest.patient.firstName} ${serviceRequest.patient.lastName} - `
            : ''}
          {serviceRequest.service.name}
        </h2>
        <div className={styles.instruction}>
          Please update the form below with any updates or important information
          while speaking with the community member.
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.contentLeft}>
            <div className={styles.requestDescriptionWrapper}>
              <div className={styles.sectionTitle}>Request Description</div>
              <div className={styles.requestDescription}>
                {serviceRequest.description}
                {renderSRFile(serviceRequest.fileName)}
              </div>
              <div className={styles.author}>{`${
                serviceRequest.organization.name
              } ${formatTimestampToFullDate(
                serviceRequest.updateTimestamp,
              )}`}</div>
            </div>
            <div className={styles.commentsWrapper}>
              <div className={styles.sectionTitle}>Comments</div>
              <div className={classNames(styles.comments)}>
                {notes.map((note, i) => (
                  <Note note={note} key={i} />
                ))}
              </div>
            </div>
            <div className={styles.newComment}>
              {/* <img
                className={styles.avatar}
                src={props.comments[0].author.avatarUrl}
              /> */}
              <IonItem className={styles.newCommentText}>
                <IonLabel
                  className={styles.newCommentLabel}
                  position='floating'
                >
                  Additional Notes about the Member (non medical info.)
                </IonLabel>
                <IonTextarea
                  ref={textAreaRef}
                  rows={6}
                  placeholder='Enter any important details here...'
                  onSubmit={handleNewCommentSubmit}
                />
                <NotesActions
                  file={file}
                  setFile={setFile}
                  onSubmit={() => {
                    setNotes([
                      ...notes,
                      {
                        content: (textAreaRef?.current?.value || '').trim(),
                      },
                    ])
                    handleNewCommentSubmit()
                  }}
                />
              </IonItem>
            </div>
          </div>
          <div className={styles.contentRight}>
            <IonButton
              title={
                nextServiceRequestStatuses.tips
                  ? nextServiceRequestStatuses.tips.join('; /n')
                  : ''
              }
              disabled={nextServiceRequestStatuses.statuses.length === 0}
              className={styles.statusButton}
              onClick={(e: any) => {
                // it may catch click while being "disabled"
                if (!nextServiceRequestStatuses.statuses.length) {
                  return
                }
                e.persist()
                setStatusPopoverState({ showPopover: true, event: e })
              }}
            >
              {ServiceRequestStatusText[serviceRequest.status]}
              <IonIcon
                className={styles.shevron}
                slot='end'
                icon={caretDownOutline}
                title='Post a new comment'
              />
            </IonButton>
            <IonPopover
              event={statusPopoverState.event}
              isOpen={statusPopoverState.showPopover}
              onDidDismiss={() =>
                setStatusPopoverState({
                  showPopover: false,
                  event: undefined,
                })
              }
            >
              <IonList onClick={handleStatusChange}>
                {nextServiceRequestStatuses.statuses.map(
                  (status, index, array) => {
                    return (
                      <IonItem
                        data-value={status}
                        key={status}
                        button
                        lines={index === array.length - 1 ? 'none' : 'full'}
                      >
                        {ServiceRequestStatusText[status]}
                        {status === serviceRequest.status ? (
                          <IonIcon icon={checkmarkOutline} slot='end' />
                        ) : null}
                      </IonItem>
                    )
                  },
                )}
              </IonList>
            </IonPopover>

            {statusError && (
              <p className={styles.errorMessage}>{statusError}</p>
            )}

            <div className={styles.patient}>
              <div className={styles.sectionTitle}>Patient</div>
              <ProfileIcon
                size={'32'}
                userName={
                  serviceRequest?.patient?.firstName +
                  ' ' +
                  serviceRequest?.patient?.lastName
                }
              />
              <IonRouterLink
                onClick={() => {
                  props.onClose()
                }}
                routerLink={`/members/${serviceRequest.patient.id}`}
              >
                {`${serviceRequest.patient.firstName} ${serviceRequest.patient.lastName}`}
              </IonRouterLink>
            </div>
            <div className={styles.employee}>
              <div className={styles.sectionTitle}>Assigned Employee</div>
              <SelectEmployee
                employees={props.employees}
                isLoading={isEmployeesLoading}
                onEmployeeChange={props.onEmployeeChange}
                assignee={serviceRequest.assignee as Employee}
              />
            </div>
            <div className={styles.organization}>
              <div className={styles.sectionTitle}>Requesting Organization</div>
              <div>{serviceRequest.organization.name}</div>
            </div>
            <div className={styles.service}>
              <div className={styles.sectionTitle}>Service Provider</div>
              <div>{serviceRequest.service.organization.name}</div>
            </div>
            <div className={styles.service}>
              <div className={styles.sectionTitle}>Transcript</div>
              <div
                className={styles.msgReview}
                onClick={() => onSelectSMSTranscripts()}
              >
                <IonText>Review latest transcription</IonText>
                <IonIcon
                  icon={documentTextOutline}
                  className={styles.msgReviewIcon}
                />
              </div>
            </div>
            <div className={styles.separator}></div>
            <div className={styles.ticketInfo}>
              <div className={styles.ticketInfoRow}>
                <span className={styles.ticketInfoRowKey}>Created</span>
                <span>
                  {formatTimestampToFullDatetime(
                    serviceRequest.createTimestamp,
                  )}
                </span>
              </div>
              <div className={styles.ticketInfoRow}>
                <span className={styles.ticketInfoRowKey}>Updated </span>
                <span>
                  {formatTimestampToFullDatetime(
                    serviceRequest.updateTimestamp,
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttonsRow}>
          <IonButton
            className={styles.cancelButton}
            color="danger"
            onClick={props.onCancel}
            disabled={
              !nextServiceRequestStatuses.statuses.includes(
                ServiceRequestStatus.CANCELLED,
              )
            }
          >
            Cancel Ticket
          </IonButton>
          <IonButton
            className={styles.submitButton}
            onClick={props.onConfirmationMessageSend}
            disabled={
              !nextServiceRequestStatuses.statuses.includes(
                ServiceRequestStatus.PENDING,
              )
            }
          >
            Send Confirmation Message
          </IonButton>
        </div>
      </div>
    </Modal>
  )
}

export enum UserType {
  PATIENT = 'MEMBER',
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE',
}

export enum UserTypeFormatted {
  PATIENT = 'Member',
  ADMIN = 'Admin',
  EMPLOYEE = 'Employee',
}

/* eslint-disable */
import { BASE_API_URL } from './constants'

export async function sendFormImportId({
  formId
}: {
  formId: string
}): Promise<Response> {
  const formData = new FormData()

  return await fetch(
    `${BASE_API_URL}/v1/surveys/import?formId=${formId}`,
    {
      method: 'POST',
      headers: {
      },
    },
  )
}

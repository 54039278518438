/* eslint-disable  */
import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
  useReducer,
} from 'react'
import { useHistory } from 'react-router-dom'
import styles from './PatientTable.module.scss'
import { IonBackdrop, IonSpinner, IonButton } from '@ionic/react'

import PaginatedTable from '../table/PaginatedTable'
import useColumns from './useColumns'
import Page from '../page/Page'
import usePatients from '../api/usePatients'

import { usePatientListUploadModal } from '../patient-list-upload-modal'
import { SuccessModal } from '../shared/SuccessModal'
import { ErrorMessage } from '../shared/ErrorMessage'
import { ErrorModal } from '../shared/ErrorModal'
import { Error } from '../shared/utils/constants'
import { AuthContext } from 'src/contexts/AuthContext'

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: null,
}

const PAGE_CHANGED = 'PAGE_CHANGED'
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED'
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED'
const SORT_BY_CHANGED = 'SORT_BY_CHANGED'
const FILTER_CHANGED = 'FILTER_CHANGED'

const reducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      }
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      }
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      }
    case SORT_BY_CHANGED:
      return {
        ...state,
        querySortBy: payload,
      }
    case FILTER_CHANGED:
      return {
        ...state,
        queryFilterBy: payload,
      }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}

export default function PatientTable() {
  const { userId, userAttributes } = useContext(AuthContext)
  const history = useHistory()
  const [error, setError] = useState('')
  const columns = useColumns()

  const [
    { queryPageIndex, queryPageSize, querySortBy, totalCount, queryFilterBy },
    dispatch,
  ] = useReducer(reducer, initialState)

  const {
    data: patients,
    isFetching: isFetchingPatientList,
    isError,
    refetch,
  } = usePatients(
    {
      userId: userId,
      orgId: userAttributes.OrgId,
      page: queryPageIndex,
      size: queryPageSize,
      sortBy: querySortBy,
      filter: queryFilterBy,
    },
    {
      onError: (err) => {
        if (err.message === '403') {
          setError(Error.UNAUTHORIZE)
        }
      },
    },
  )

  const handleFilterChange = useCallback((e) => {
    const value = e.target.value || undefined
    dispatch({ type: FILTER_CHANGED, payload: value })
  }, [])

  // Member Upload
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false)
  const handlePatientListUploadSuccess = useCallback(() => {
    setSuccessModalVisible(true)
  }, [])

  const handleSuccessModalClose = useCallback(() => {
    setSuccessModalVisible(false)
    history.push('/service-requests')
  }, [])

  const handleDismiss = useCallback(() => {
    setError('')
    history.goBack()
  })

  const {
    PatientListUploadModal,
    isVisible: isPatientListUploadModalVisible,
    show,
  } = usePatientListUploadModal(handlePatientListUploadSuccess)
  const handleUploadPatientsListButtonClick = useCallback(() => {
    show()
  }, [])

  if (isError && error) {
    return <ErrorModal text={error} onDismiss={handleDismiss} />
  }

  if (isError) {
    return <ErrorMessage page='Members' />
  }

  if (!patients) {
    return (
      <>
        <Page name='Members'>
          <IonSpinner className={styles.loader} />
        </Page>
      </>
    )
  }

  return (
    <>
      {isFetchingPatientList ? <IonBackdrop /> : null}
      <Page name='Members'>
        <div className={styles.pageWrapper}>
          <div className={styles.tableWrapper}>
            <div className={styles.tableTopRow}>
              <input
                className={styles.searchBar}
                value={queryFilterBy}
                onChange={handleFilterChange}
                placeholder='Search Members...'
              />

              <div>
                <IonButton
                  className={styles.uploadList}
                  onClick={handleUploadPatientsListButtonClick}
                >
                  {'Upload your list'}
                  <svg className={styles.uploadIcon} width='16' height='20'>
                    <image
                      xlinkHref='/assets/file-upload.svg'
                      src='/assets/file-upload.svg'
                      width='16'
                      height='20'
                    />
                  </svg>
                </IonButton>
              </div>
            </div>
            {!isFetchingPatientList && (
              <PaginatedTable
                data={patients}
                columns={columns}
                globalFilterInput={queryFilterBy}
                dispatch={dispatch}
                onClick={({ row }) =>
                  history.push(`/members/${row?.original.id}`)
                }
                pageCount={Math.ceil(patients.totalElements / patients.size)}
                queryPageIndex={queryPageIndex}
                queryPageSize={queryPageSize}
                querySortBy={querySortBy}
              />
            )}
          </div>
        </div>
      </Page>
      {isPatientListUploadModalVisible && <PatientListUploadModal />}
      {isSuccessModalVisible && (
        <SuccessModal
          onClose={handleSuccessModalClose}
          text='Your documents were uploaded successfully, your member lists are now updated.'
        />
      )}
    </>
  )
}

/* eslint-disable  */

import React, { useEffect, useState, useContext, useMemo } from 'react'
import Modal from 'react-modal'
import {
  IonButton,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonSpinner,
} from '@ionic/react'
import styles from './styles.module.scss'

import { CustomFormServiceRequestProps } from './interfaces'
import classNames from 'classnames'
import useSurveysByOrganizationRequests from '../../api/useSurvey'
import FormDisplay from '../../custom-forms/components/FormDisplay'
import { AuthContext } from '../../contexts/AuthContext'

export function CustomFormServiceRequest({
  onSubmit,
  onCancel,
  onSkip,
  isLoading,
  isStandalone,
}: CustomFormServiceRequestProps) {
  const { userAttributes } = useContext(AuthContext)

  const [selectedSurvey, setSelectedSurvey] = useState()
  const [currentFormTemplate, setFormTemplate] = useState()

  let inProgressForm: { completeLastPage: () => void }

  const { data: surveys } = useSurveysByOrganizationRequests(
    userAttributes.UserId,
    userAttributes.OrgId,
  )

  const formSelectionHandler = (e: CustomEvent) => {
    const customForm = e.target.value
    const survey = surveys.find((survey) => survey.id === customForm)
    if (survey) {
      setSelectedSurvey(survey)
      setFormTemplate(survey.data)
    }
  }

  const setInProgressFormHandler = (survey: any) => {
    inProgressForm = survey
  }

  const setInProgressFormAnswersHandler = (answers: any) => {
    onSubmit({
      id: null,
      version: selectedSurvey.version,
      data: answers,
      surveyId: selectedSurvey.id,
      userId: Number(userAttributes.UserId),
      language: 'English',
    })
  }

  const onSubmitHandler = () => {
    inProgressForm.completeLastPage()
  }

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={onCancel}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      <div className={styles.wrapper}>
        <h2 className={styles.title}>New Member Screening</h2>
        <div className={styles.info}>
          Please fill out the form below to submit the members name and contact
          information. Someone from your local Nonprofit should be in contact
          soon. &nbsp;
          <span className={styles.infoHours}>(24 to 48hrs)</span>
        </div>
        <div className={styles.patient}>
          <div className={styles.row}>
            <div className={classNames(styles.field, styles.organization)}>
              <IonItem>
                <IonLabel position='floating'>
                  Select Member Screening Form
                </IonLabel>
                <IonSelect
                  value={selectedSurvey?.id}
                  defaultValue={selectedSurvey?.id}
                  onIonChange={formSelectionHandler}
                >
                  {surveys
                    ?.filter((survey: any) => !survey.archivedAt)
                    .map((survey) => (
                      <IonSelectOption key={survey.id} value={survey.id}>
                        {survey.title
                          ? `${survey.title} V.${survey.version}`
                          : 'Untitled'}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonItem>
            </div>
          </div>
          <FormDisplay
            formTemplate={currentFormTemplate}
            setForm={(survey: any) => setInProgressFormHandler(survey)}
            setFormAnswers={(answers: any) =>
              setInProgressFormAnswersHandler(answers)
            }
          />
          <div className={styles.buttons}>
            <IonButton
              shape='round'
              className={styles.cancelButton}
              onClick={onCancel}
            >
              Cancel
            </IonButton>
            {!isStandalone && (
              <IonButton
                shape='round'
                className={styles.cancelButton}
                onClick={onSkip}
              >
                Skip
              </IonButton>
            )}
            {/*<IonButton*/}
            {/*    shape='round'*/}
            {/*    className={styles.submitButton}*/}
            {/*    disabled={isLoading || !selectedSurvey}*/}
            {/*    onClick={onSubmitHandler}*/}
            {/*>*/}
            {/*    {isLoading ? <IonSpinner color='dark' /> : 'Submit and continue'}*/}
            {/*</IonButton>*/}
          </div>
        </div>
      </div>
    </Modal>
  )
}

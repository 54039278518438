/* eslint-disable */
import React, { useCallback, useContext, useMemo, useState } from 'react'

import useUser from '../../api/useUser'
import { AuthContext } from '../../contexts/AuthContext'
import { SurveyImportModal } from './SurveyImportModal'

export function useSurveyImportModal(
  onSuccess: () => void,
  onError: () => void,
) {
  const { userId } = useContext(AuthContext)

  const [isModalVisible, setModalVisible] = useState(false)
  const handleShow = useCallback(() => {
    setModalVisible(true)
  }, [])
  const handleHide = useCallback(() => {
    setModalVisible(false)
  }, [])

  const handleSubmit = useCallback(() => {
    setModalVisible(false)
    onSuccess()
  }, [userId])

  const handleConfirm = useCallback(() => {
    setModalVisible(false)
    onSuccess()
  }, [userId])

  const handleCancel = useCallback(() => {
    handleHide()
  }, [])

  const handleError = useCallback(() => {
    setModalVisible(false)
    onError()
  }, [userId])

  const component = useMemo(() => {
    return () => (
      <>
        {isModalVisible && (
          <SurveyImportModal
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            onError={handleError}
          />
        )}
      </>
    )
  }, [isModalVisible])

  return {
    isVisible: isModalVisible,
    show: handleShow,
    hide: handleHide,
    SurveyImportModal: component,
  }
}

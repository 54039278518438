/* eslint-disable  */

import { IonButton } from '@ionic/react'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { store } from 'react-notifications-component'
import { defaultNotificationOptions } from '../../../shared/Notification'

import styles from './styles.module.scss'

export function FileUploadZone(props: {
  acceptedFiles: File[]
  onFilesAccepted: (files: File[]) => void
  acceptableFileTypes: string
}) {
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length !== 0) {
        if (fileRejections.length === 1) {
          store.addNotification({
            ...defaultNotificationOptions,
            type: 'danger',
            message: `File "${fileRejections[0].file.name}" was rejected.`,
          })
        } else {
          const rejectedFileNames = fileRejections
            .map((f) => f.file.name)
            .join('", "')

          store.addNotification({
            ...defaultNotificationOptions,
            type: 'danger',
            message: `Files "${rejectedFileNames}" were rejected.`,
          })
        }
      }
      props.onFilesAccepted(acceptedFiles)
    },
    [],
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFileDialogActive,
    isFocused,
  } = useDropzone({
    onDrop,
    accept: props.acceptableFileTypes,
  })

  return (
    <div className={styles.wrapper}>
      <div {...getRootProps({ className: styles.zone })}>
        <svg className={styles.icon} width='16' height='20'>
          <image xlinkHref='/assets/file-upload.svg' width='16' height='20' />
        </svg>
        <div
          className={classNames(styles.help, {
            [styles.zoneActive]:
              isFileDialogActive || isDragActive || isFocused,
          })}
        >
          <input {...getInputProps()} />
          <p>Drag & Drop to Upload Files or</p>
          <IonButton
            size='small'
            shape='round'
            fill='solid'
            className={styles.button}
          >
            Choose file
          </IonButton>
        </div>
      </div>
      <ul className={styles.uploadedFiles}>
        {props.acceptedFiles.map((file, index) => (
          <li key={file.name + index} className={styles.uploadedFileName}>
            {file.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

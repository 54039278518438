/* eslint-disable */

import React from 'react'
import {
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonText,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
} from '@ionic/react'
import { useFormContext } from 'react-hook-form'
import { arrowBack } from 'ionicons/icons'
import PrivacyPolicyText from '../policies/privacy-policy'

function PrivacyPolicy() {
  const { register } = useFormContext()
  return (
    <IonGrid>
      <IonHeader className='ion-no-border'>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton
              text=''
              icon={arrowBack}
              defaultHref={'/login'}
              className='custom-back-button'
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonRow className='intro-container'>
        <IonCol>
          <IonText className='header-text'>
            {'Terms of Service & Privacy Policy'}
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow className='description-container'>
        <IonCol>
          <IonText className='description-text'>
            {PrivacyPolicyText.text}
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className='ion-text-center'>
          <IonRow className='ion-text-end ion-padding-end'>
            <IonCol className='ion-text-end ion-margin-horizontal'>
              <IonButton
                shape='round'
                fill='outline'
                expand='full'
                color='white'
                className='next-button'
                type='submit'
              >
                Accept
              </IonButton>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default PrivacyPolicy

/* eslint-disable */

import React, { useContext } from 'react'
import { Route, Switch } from 'react-router'
import PatientTable from '../patients/PatientTable'
import EmployeeTable from '../employees/EmployeeTable'
import PatientProfile from '../patient/PatientProfile'
import EmployeeProfile from '../employee/EmployeeProfile'
import AddOrganizationDesktop from '../onboarding/AddOrganization'
import PrivacyPolicyDesktop from '../onboarding/PrivacyPolicy'
import { OrgIncomingServiceRequests } from '../org-incoming-service-requests'
import ServiceRequests from '../service-requests'
import Login from '../login/Login'
import EmployeeSignUp from '../onboarding/employees/EmployeeSignUp'
import { AuthContext } from '../contexts/AuthContext'
import MassMessaging from '../mass-messaging/MassMessaging'
import { FormsLibrary } from '../custom-forms'
import { FormsCreator } from '../custom-forms/FormsCreator'
import { FormsPreview } from '../custom-forms/FormsPreview'
import MetricsDashboard from '../metrics/MetricsDashboard'
import MemberMessaging from '../member-messaging/MemberMessaging'
import ProfileManagement from '../profile-management/ProfileManagement'
import SurveyDisplay from 'src/survey/survey-display'
import { FormSubmissions } from '../custom-forms/FormSubmissions'
import { FormAnalyticsCharts } from '../custom-forms/FormAnalyticsCharts'
import ResetPasswordContainer from '../reset-password'

export default function WebRoutes() {
  return (
    <>
      <Switch>
        <Route path='/login' component={Login} exact />
        <Route path='/signup' component={EmployeeSignUp} exact />
        <Route
          path='/reset-password'
          component={ResetPasswordContainer}
          exact
        />
        <Route path='/add-organization' component={AddOrganizationDesktop} />
        <Route path='/privacy-policy' component={PrivacyPolicyDesktop} />
        <PrivateRoute path='/members' component={PatientTable} exact />
        <PrivateRoute path='/employees' component={EmployeeTable} exact />
        <PrivateRoute path='/metrics-dashboard' component={MetricsDashboard} />
        <PrivateRoute path='/member-messaging' component={MemberMessaging} />
        <PrivateRoute
          path='/account-management'
          component={ProfileManagement}
        />
        <PrivateRoute path='/patients/:id' component={PatientProfile} exact />
        <PrivateRoute path='/members/:id' component={PatientProfile} exact />
        <PrivateRoute
          path='/service-requests'
          component={ServiceRequests}
          exact
        />
        <PrivateRoute path='/mass-messaging' component={MassMessaging} />
        <PrivateRoute
          path='/incoming-service-requests'
          component={OrgIncomingServiceRequests}
        />
        <PrivateRoute path='/forms-library' component={FormsLibrary} />
        <PrivateRoute path='/forms-create' component={FormsCreator} />
        <PrivateRoute path='/forms-preview' component={FormsPreview} />
        <PrivateRoute path='/forms-submissions' component={FormSubmissions} />
        <PrivateRoute path='/forms-analytics' component={FormAnalyticsCharts} />
        <Route path='/form/:id' component={SurveyDisplay} exact />
        <PrivateRoute
          path={['/employees/:id', '/']}
          component={EmployeeProfile}
          exact
        />
      </Switch>
    </>
  )
}

export function PrivateRoute({ component, ...options }: any) {
  const { isLoggedIn } = useContext(AuthContext)
  return isLoggedIn ? (
    <Route {...options} component={component} />
  ) : (
    <Route {...options} component={Login} />
  )
}

/* eslint-disable */
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useState,
} from 'react'
import { IonBackdrop, IonButton } from '@ionic/react'
import { RouteProps, useHistory } from 'react-router-dom'
import styles from './styles.module.scss'
import 'react-tabs/style/react-tabs.css'
import Page from '../page/Page'
import SurveyJSPreview from './components/SurveyJSPreview'
import { createSurvey, updateSurvey } from '../api/useSurvey'
import { SuccessModal } from '../shared/SuccessModal'
import * as Survey from 'survey-react'
import { AuthContext } from '../contexts/AuthContext'

export const FormsPreview: FunctionComponent<RouteProps> = (
  props: RouteProps,
) => {
  const [modalConfirmationMessage, setModalConfirmationMessage] = useState('')
  const history = useHistory()
  const { userAttributes } = useContext(AuthContext)

  // Success Modal
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false)
  const handleSuccessModalClose = useCallback(() => {
    setSuccessModalVisible(false)
    localStorage.removeItem('form-pending')
    localStorage.removeItem('form-edit')
    history.push('/forms-library')
  }, [])

  const submitFormHandler = (): void => {
    const editForm = JSON.parse(localStorage.getItem('form-edit') as any)
    if (editForm) {
      setModalConfirmationMessage('Your form was updated successfully!')
      updateSurvey({
        id: editForm.id,
        uniqueSurveyId: editForm.uniqueSurveyId,
        version: editForm.version,
        title: editForm.data.title,
        data: editForm.data,
        language: 'English',
        organizationId: userAttributes.OrgId,
        originalSurveyId: editForm.id,
      }).then((data) => setSuccessModalVisible(true))
    } else {
      setModalConfirmationMessage('Congrats on the new form!')
      const model = new Survey.Model(localStorage.getItem('form-pending'))
      createSurvey({
        id: null,
        uniqueSurveyId: null,
        version: null,
        title: model.localizableStrings.title.values.default,
        data: model,
        language: 'English',
        organizationId: userAttributes.OrgId,
        originalSurveyId: null,
      }).then((data) => setSuccessModalVisible(true))
    }
  }

  return (
    <>
      {isSuccessModalVisible && <IonBackdrop className={styles.backdrop} />}
      <Page name='Forms'>
        <div className={styles.pageWrapper}>
          <div className={styles.formCreatorHeader}>
            <IonButton
              expand='full'
              className={styles.editButton}
              color='base-blue'
              routerLink='/forms-create'
            >
              MAKE EDITS
            </IonButton>
            <IonButton
              expand='full'
              className={styles.previewButton}
              color='base-blue'
              onClick={submitFormHandler}
            >
              PUBLISH
            </IonButton>
          </div>
          <SurveyJSPreview></SurveyJSPreview>
        </div>
      </Page>
      {isSuccessModalVisible && (
        <SuccessModal
          onClose={handleSuccessModalClose}
          text={modalConfirmationMessage}
        />
      )}
    </>
  )
}

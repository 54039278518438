/* eslint-disable  */
import React, { useEffect } from 'react'
import styles from './Table.module.scss'
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useRowSelect,
} from 'react-table'

import UpDownArrowIcon from '../public/assets/icons/UpDownArrowIcon.svg'
import Checkbox from './components/Checkbox'

const defaultPropGetter = () => ({})
const Table = ({ columns, data, ...props }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    setGlobalFilter,
    getRowProps = defaultPropGetter,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: props.initialState,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (!props.showFilters) {
        return null
      }
      hooks.visibleColumns.push((cols) => [
        // Column for selection
        {
          id: 'select',
          // The header can use the table's getToggleAllRowsSelectedProps method
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className={styles.checkboxContainer}>
              <Checkbox {...getToggleAllRowsSelectedProps()} />
              <p>Select All</p>
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
        },
        ...cols,
      ])
    },
  )

  useEffect(() => {
    if (props.getSelectedRows) {
      props.getSelectedRows(selectedRowIds)
    }
  }, [selectedRowIds])

  useEffect(() => {
    setGlobalFilter(props.globalFilterInput)
  }, [props.globalFilterInput])

  const assignClassName = (column) => {
    if (!column.isSorted) {
      return null
    }
    return column.isSortedDesc ? 'sort-desc' : 'sort-asc'
  }

  return (
    <table {...getTableProps()} className={styles.table}>
      <thead slot='fixed'>
        {headerGroups.map((headerGroup) => {
          return (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={assignClassName(column)}
                  >
                    <b>{column.render('Header')}</b>
                    {column.canSort && (
                      <span className={styles.icon}>
                        <img src={UpDownArrowIcon} />
                      </span>
                    )}
                  </th>
                )
              })}
            </tr>
          )
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <React.Fragment key={row.getRowProps().key}>
              <tr
                {...row.getRowProps({
                  ...getRowProps(row),
                  onClick: props.onClick
                    ? () => {
                        props.onClick({ row })
                      }
                    : undefined,
                  className: `${props.onClick && styles.link} ${
                    getRowProps(row)?.className
                  }`,
                })}
              >
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            </React.Fragment>
          )
        })}
      </tbody>
    </table>
  )
}

export default Table

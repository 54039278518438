/* eslint-disable */

import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonSpinner,
} from '@ionic/react'
import Page from '../page/Page'
import styles from './styles.module.scss'
import usePatients from '../api/usePatients'
import { getCognitoAttribute, getCurrentUser } from '../authentication/auth'
import StepContainer from './components/StepContainer'
import { Patient } from '../employee/interfaces'
import { useMutation } from 'react-query'
import { sendMessage } from '../api/useSendMessage'
import { AuthContext } from '../contexts/AuthContext'
import { SuccessModal } from '../shared/SuccessModal'
import { Error } from '../shared/utils/constants'
import { ErrorModal } from '../shared/ErrorModal'
import { useHistory } from 'react-router'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import classNames from 'classnames'
import { useEmployees } from '../api/useEmployeesNew'
import PatientsTab from './components/PatientsTab'
import EmployeesTab from './components/EmployeesTab'

const MassMessaging = () => {
  const history = useHistory()
  const { userId } = useContext(AuthContext)
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [isSuccuessModal, setIsSuccessModal] = useState(false)

  const [isStepOneDone, setStepOneDone] = useState(false)
  const [isStepTwoDone, setStepTwoDone] = useState(false)

  const [selectedUsers, setSelectedUsers] = useState<Patient[]>([])
  const [selectedEmployees, setSelectedEmployees] = useState<any[]>([])
  const [currentUserAttributes, setCurrentUserAttributes] = useState([])
  const [filters, setFilters] = useState({ serviceType: '', serviceName: '' })

  useEffect(() => {
    getCurrentUser((attributes: any) => {
      setCurrentUserAttributes(attributes)
    })
  }, [getCurrentUser])

  const loggedInUserOrgId = useMemo(() => {
    return getCognitoAttribute(currentUserAttributes, 'custom:organizationId')
  }, [currentUserAttributes])

  const handleError = (err: any) => {
    if (err.message == 403) {
      setError(Error.UNAUTHORIZE)
    }
  }

  const { data: patients = [], isFetching, isError } = usePatients(
    {
      userId: userId,
      orgId: loggedInUserOrgId,
      ...(filters.serviceName && { serviceName: filters.serviceName }),
      ...(filters.serviceType && { serviceType: filters.serviceType }),
    },
    {
      refetchOnWindowFocus: false,
      onError: handleError,
    },
  )

  const { data: employees = [] } = useEmployees(loggedInUserOrgId, {
    onError: handleError,
  })

  const { mutate: useSendMessage, isLoading } = useMutation(sendMessage, {
    onSuccess: () => {
      setIsSuccessModal(true)
    },
  })

  const handleSelect = (
    users: any[],
    setUsers: Dispatch<SetStateAction<any[]>>,
    selected: any,
  ) => {
    setUsers(users.filter((_: any, i: number) => selected[i] === true))
  }

  const onPatientSelect = (selected: any) => {
    // Choose Patients
    handleSelect(patients.content, setSelectedUsers, selected)
  }

  const onEmployeeSelect = (selectedUser: any) => {
    // Choose Employees
    handleSelect(employees, setSelectedEmployees, selectedUser)
  }

  const getIds = (users: any) => {
    return users.map((user: any) => user.id)
  }

  const onSendMessage = () => {
    const payload = {
      ids: getIds(selectedUsers).concat(getIds(selectedEmployees)),
      message: message,
    }

    useSendMessage({ payload, userId })
  }

  const onCloseSuccessModal = () => {
    setIsSuccessModal(false)
    // Reset to initial state
    setMessage('')
    setStepOneDone(false)
    setStepTwoDone(false)
  }

  const handleDismiss = () => {
    setError('')
    history.goBack()
  }

  if (isError && error) {
    return <ErrorModal onDismiss={handleDismiss} text={error} />
  }

  return (
    <>
      <Page hideTopBar>
        <div className={styles.pageContainer}>
          <h1 className={styles.pageTitle}>Mass Messaging</h1>
          <p className={styles.pageDesc}>
            *All fields are required unless otherwise noted
          </p>
          <div className={styles.container}>
            <StepContainer
              step='1'
              title='Your Message'
              isStepDone={isStepOneDone}
              nextdisabled={!message}
              onClick={() => setStepOneDone(true)}
            >
              <div className={styles.messageView}>
                <IonItem>
                  <IonLabel position='floating'>SMS Message</IonLabel>
                  <IonInput
                    type='text'
                    value={message}
                    placeholder='Type out your event and/or announcement message here'
                    maxlength={300}
                    onIonChange={(e) => setMessage(e.detail.value as string)}
                  />
                </IonItem>
                <p className={styles.inputInfo}>300 Character Maximum</p>
              </div>
            </StepContainer>
            <StepContainer
              step='2'
              title='Use the table below to identiy who will receive the above message'
              isShow={isStepOneDone}
              isStepDone={isStepTwoDone}
              nextdisabled={!selectedUsers.length && !selectedEmployees.length}
              onClick={() => setStepTwoDone(true)}
            >
              <div className={styles.tableView}>
                <Tabs
                  className={styles.tabsContainer}
                  selectedTabClassName={styles.selectedTab}
                >
                  <TabList className={styles.tabList}>
                    <Tab className={styles.tabItem}>
                      Members ({patients.length})
                    </Tab>
                    <Tab className={classNames(styles.tabItem, styles.nthTab)}>
                      Employees ({employees.length})
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <PatientsTab
                      orgId={loggedInUserOrgId}
                      setFilters={setFilters}
                      isFetching={isFetching}
                      patients={patients.content}
                      onPatientSelect={onPatientSelect}
                    />
                  </TabPanel>
                  <TabPanel>
                    <EmployeesTab
                      orgId={loggedInUserOrgId}
                      setFilters={setFilters}
                      employees={employees}
                      onEmployeeSelect={onEmployeeSelect}
                    />
                  </TabPanel>
                </Tabs>
              </div>
            </StepContainer>
            <StepContainer
              step='3'
              title='If you are ready to send this message, then click the big blue button below.'
              isShow={isStepOneDone && isStepTwoDone}
              hasStepContent={false}
            >
              <div className={styles.buttonContainer}>
                <IonButton
                  onClick={onSendMessage}
                  className={styles.sendButton}
                  disabled={
                    (!selectedUsers.length && !selectedEmployees.length) ||
                    !message
                  }
                  expand='full'
                >
                  {isLoading ? <IonSpinner color='dark' /> : `Send`}
                </IonButton>
              </div>
            </StepContainer>
          </div>
        </div>
      </Page>
      {isSuccuessModal && (
        <SuccessModal
          onClose={onCloseSuccessModal}
          text='Your message was sent successfully to the selected patient(s)'
        />
      )}
    </>
  )
}

export default MassMessaging

/* eslint-disable  */
const defaultPatientMessaging = {
  service_request_patient:
    'Hello {member_name}, this is {assignee_name} reaching out regarding {organization_name}. We will be in contact with you shortly.',
  service_request_employee:
    'Hey {assignee_name}, you have been assigned to {member_name} from {organization_name} for {service_name}. Please reach out to them at your earliest convenience within 48 hours and update your ticket in HealthOpX.',
  service_request_confirmation_patient:
    'Hello {member_name} from {organization_name}. You just received {service_name} from {assignee_name}. To confirm, text back with the following number: {service_request_id}.',
}

export function getDefaultPatientMessage(msgType: string) {
  // @ts-ignore
  return defaultPatientMessaging[msgType] || msgType
}

/* eslint-disable  */

import React, {
  useState,
  useCallback,
  FunctionComponent,
  useMemo,
  useEffect,
  useContext,
} from 'react'
import { IonContent, IonBackdrop, IonBadge, IonButton } from '@ionic/react'
import { RouteProps } from 'react-router-dom'
import { useIsFetching } from 'react-query'

import Page from '../page/Page'
import {
  useCustomFormRequestModal,
  useNewServiceRequestModal,
  useSMSRequestModal,
} from '../shared/NewServiceRequest'
import { SuccessModal } from '../shared/SuccessModal'

import styles from './styles.module.scss'

import { getCurrentUser, getCognitoAttribute } from '../authentication/auth'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import TabContent from './components/TabContent/TabContent'
import SERVICE_REQUESTS_TABS from '../shared/utils/tabs'
import { useOrgIncomingServiceRequests } from '../api/useOrgIncomingServiceRequests'
import { Error } from '../shared/utils/constants'
import { ErrorModal } from '../shared/ErrorModal'
import { ServiceRequest } from '../shared/models/ServiceRequest'
import { saveSurveyResponse } from '../api/useSurvey'
import { AuthContext } from '../contexts/AuthContext'

export const OrgIncomingServiceRequests: FunctionComponent<RouteProps> = (
  props: RouteProps,
) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [error, setError] = useState('')
  const [onCloseSmsModal, setonCloseSmsModal] = useState(false)

  const { userId, userAttributes } = useContext(AuthContext)

  const {
    data: serviceRequests = [],
    isError,
    isLoading,
    refetch: refetchRequests,
  } = useOrgIncomingServiceRequests(
    { userId, organizationId: userAttributes.OrgId },
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        if (err.message === '403') {
          setError(Error.UNAUTHORIZE)
        }
      },
    },
  )

  const getServiceRequestsByType = (type: string): ServiceRequest[] => {
    const results = (serviceRequests as ServiceRequest[]).filter(
      (request: { status: string }) => request.status === type,
    )
    return results
  }

  const isFetching = useIsFetching()

  /// Success Modal
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false)
  const handleSuccessModalClose = useCallback(() => {
    setSuccessModalVisible(false)
  }, [])

  /// New Service Request
  const handleNewServiceRequestSuccess = useCallback(() => {
    setSuccessModalVisible(true)
  }, [setSuccessModalVisible])
  const {
    NewServiceRequest,
    show: showNewServiceRequest,
    isVisible: isNewServiceRequestPopupVisible,
    isError: isSRError,
  } = useNewServiceRequestModal({
    onSuccess: (patientId) => {
      const survey = JSON.parse(localStorage.getItem('pendingSurvey'))
      if (patientId && survey) {
        survey.userId = patientId
        saveSurveyResponse(survey)
      }
    },
    onError: () => {
      setError(Error.SERVICE_REQUEST_CREATION_FAILED)
    },
  })

  // Custom Form Service Request
  const {
    CustomFormServiceRequest,
    show: showCustomFormServiceRequest,
    hide: hideCustomFormServiceRequest,
  } = useCustomFormRequestModal({
    isPendingModal: true, // if yes, it means we don't have the patient id yet
    onSuccess: () => {
      hideCustomFormServiceRequest()
      setSuccessModalVisible(true)
    },
    onPending: () => {
      hideCustomFormServiceRequest()
      showNewServiceRequest()
    },
    onCanceled: () => setSuccessModalVisible(null),
    onSkip: () => {
      hideCustomFormServiceRequest()
      showNewServiceRequest()
    },
  })

  const onSelectTab = (index: number) => setTabIndex(index)

  const onSelectSMSTranscriptsHandler = (serviceRequest: any) => {
    // setSelectedServiceRequest(serviceRequest)
    // showSMSRequest()
  }

  const handleDismiss = useCallback(() => {
    setError('')
    showNewServiceRequest()
  }, [setError])

  return (
    <>
      {isFetching ? <IonBackdrop /> : null}
      <Page name='Incoming Service Requests'>
        <div className={styles.pageWrapper}>
          <div className={styles.tabsContainer}>
            <Tabs selectedIndex={tabIndex} onSelect={onSelectTab}>
              <TabList className={styles.tabList}>
                {SERVICE_REQUESTS_TABS.map((tab, index) => (
                  <Tab className={styles.tabItem} key={index}>
                    {tab.label}
                    <IonBadge color='light'>
                      {getServiceRequestsByType(tab.type).length}
                    </IonBadge>
                  </Tab>
                ))}
              </TabList>
              {SERVICE_REQUESTS_TABS.map((item) => (
                <TabPanel key={item.type} className={styles.tabsContainer}>
                  <TabContent
                    isLoading={isLoading}
                    setError={setError}
                    isError={isError}
                    isSRError={isSRError}
                    error={error}
                    serviceRequests={getServiceRequestsByType(item.type)}
                    refetchRequests={refetchRequests}
                    showCustomFormServiceRequest={showCustomFormServiceRequest}
                    loggedInUserOrgId={userAttributes.OrgId}
                    onSelectSMSTranscripts={onSelectSMSTranscriptsHandler}
                    onSmsModalClose={onCloseSmsModal}
                  />
                </TabPanel>
              ))}
            </Tabs>

            {isNewServiceRequestPopupVisible ? <NewServiceRequest /> : null}
            <CustomFormServiceRequest />
            {isSRError && error && (
              <ErrorModal onDismiss={handleDismiss} text={error} />
            )}
            {isSuccessModalVisible && (
              <SuccessModal
                onClose={handleSuccessModalClose}
                text='The request was created, someone from the CBO or Nonprofit should be in
               contact with the patient soon.'
              />
            )}
          </div>
        </div>
      </Page>
    </>
  )
}

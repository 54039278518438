/* eslint-disable */
import React, { useCallback, useContext, useMemo, useState } from 'react'

import useUser from '../../../api/useUser'
import { AuthContext } from '../../../contexts/AuthContext'
import { UploadFilesModal } from './UploadFilesModal'

export function useUploadFileModal(onSuccess: () => void, onError: () => void, userId: number) {
  const authContext = useContext(AuthContext)
  const { data: user } = useUser(authContext.userId)

  const [isModalVisible, setModalVisible] = useState(false)
  const handleShow = useCallback(() => {
    setModalVisible(true)
  }, [])

  const handleHide = useCallback(() => {
    setModalVisible(false)
  }, [])

  const handleSubmit = useCallback(() => {
    setModalVisible(false)
    onSuccess()
  }, [user])

  const handleCancel = useCallback(() => {
    handleHide()
  }, [])

  const component = useMemo(() => {
    return () => (
      <>
        {isModalVisible && (
          <UploadFilesModal
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            userId={userId}
          />
        )}
      </>
    )
  }, [isModalVisible])

  return {
    isVisible: isModalVisible,
    show: handleShow,
    hide: handleHide,
    UploadFilesModal: component,
  }
}

/* eslint-disable  */
import { ServiceRequestStatus } from '../models/ServiceRequestStatuses'

export function getNextServiceRequestStatusesWithTips(
  currentStatus: ServiceRequestStatus,
  {
    isAssigned,
  }: {
    isAssigned: boolean
  },
): {
  statuses: Array<ServiceRequestStatus>
  tips?: Array<string>
} {
  switch (currentStatus) {
    case ServiceRequestStatus.REQUESTED: {
      return {
        statuses: [ServiceRequestStatus.OPEN, ServiceRequestStatus.CANCELLED],
      }
    }
    case ServiceRequestStatus.OPEN: {
      return {
        statuses: [
          ServiceRequestStatus.IN_PROGRESS,
          ServiceRequestStatus.CANCELLED,
        ],
      }
    }
    case ServiceRequestStatus.IN_PROGRESS: {
      return {
        statuses: [
          ServiceRequestStatus.PENDING,
          ServiceRequestStatus.CANCELLED,
        ],
      }
    }
    case ServiceRequestStatus.PENDING: {
      return {
        statuses: [ServiceRequestStatus.CANCELLED],
        tips: [tips.AUTO_CLOSE_PENDING_REQUEST],
      }
    }
    case ServiceRequestStatus.COMPLETED: {
      return {
        statuses: [],
        tips: [tips.COMPLETED_IS_FINAL],
      }
    }
    case ServiceRequestStatus.CANCELLED: {
      if (isAssigned) {
        return {
          statuses: [ServiceRequestStatus.OPEN],
        }
      }
      return {
        statuses: [ServiceRequestStatus.REQUESTED],
      }
    }
    default: {
      return {
        statuses: [currentStatus],
      }
    }
  }
}

const tips = {
  AUTO_CLOSE_PENDING_REQUEST:
    '"Pending" requests will be "Completed" when the confirmation message is returned',
  COMPLETED_IS_FINAL:
    "Service Request can't be canceled or be moved in any status after completion",
}

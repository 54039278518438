/* eslint-disable */
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '../contexts/AuthContext'
import { BASE_API_URL } from './constants'
import { fetchFile } from './utils'

const getOrganization = async ({ queryKey }) => {
  const [_key, { id, userId }] = queryKey
  if (id == null) {
    return null
  }
  return await fetch(`${BASE_API_URL}/v1/organizations/${id}`, {
    headers: { userId: userId },
  }).then((response) => response.json())
}

export default function useOrganization(id) {
  const { userId } = useContext(AuthContext)

  return useQuery([`organization-${id}`, { id, userId }], getOrganization, {
    refetchOnWindowFocus: false,
  })
}

// Fetch ORG Logo
async function fetchOrgLogo({ queryKey }) {
  const [_, { orgId, name }] = queryKey
  const url = `${BASE_API_URL}/v1/organizations/${orgId}/image`
  return fetchFile(url, name)
}

export function useFetchOrgLogo(orgId, name, config = {}) {
  return useQuery(['org-logo', { orgId, name }], fetchOrgLogo, { ...config })
}

/* eslint-disable */
import { UserAttributes } from '../schema'
import { getCognitoAttribute } from './../authentication/auth'

export function mapUserAttributes(userAttributes: any) {
  const attributes = {} as UserAttributes
  if (!localStorage.getItem('token')) {
    return attributes
  }
  attributes.Sub = getCognitoAttribute({}, 'sub')
  const emailVerified = getCognitoAttribute({}, 'email_verified')
  attributes.EmailVerified = emailVerified?.Value
    ? Boolean(JSON.parse(emailVerified.Value))
    : false
  attributes.Email = getCognitoAttribute({}, 'email')
  attributes.OrgId = getCognitoAttribute({}, 'custom:organizationId')
  attributes.UserId = getCognitoAttribute({}, 'custom:userId')
  return attributes
}

/* eslint-disable  */
import { BASE_API_URL } from './constants'
import { useQuery } from 'react-query'
import { EmailPreference } from '../shared/models/EmailPreference'

const getEmailPreferences = async ({ queryKey }: any): any => {
  const [_key, { userId }] = queryKey
  return await fetch(`${BASE_API_URL}/v1/users/${userId}/email-preferences`, {
    method: 'GET',
    headers: {
      userId: userId,
    },
  }).then((response) => response.json())
}

export function getEmailPreferencesByQueryKey(userId: string | number): any[] {
  return [`${userId}-email-preferences`, { userId }]
}

export default function useEmailPreferencesByUser(
  userId: string | number,
): any {
  return useQuery<EmailPreference[]>(
    getEmailPreferencesByQueryKey(userId),
    getEmailPreferences,
  )
}

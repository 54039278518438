/* eslint-disable  */

import React from 'react'
import { IonButton, IonCol, IonGrid, IonRow, IonText } from '@ionic/react'

function ResetPasswordSuccess() {
  return (
    <IonGrid>
      <IonRow style={{ marginTop: 50 }}>
        <IonCol className='ion-text-start'>
          <IonText id='login-title'>Reset password success</IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className='ion-text-start'>
          <IonText id='login-text'>
            Your password was reset successfully.
          </IonText>
        </IonCol>
      </IonRow>
      <IonButton
        shape='round'
        fill='outline'
        expand='full'
        color='dark'
        routerLink='/login'
        className='ion-margin'
      >
        Go back to login screen
      </IonButton>
    </IonGrid>
  )
}

export default ResetPasswordSuccess

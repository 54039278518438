/* eslint-disable */
import React, { useContext } from 'react'
import {
  IonCol,
  IonRow,
} from '@ionic/react'
import { AuthContext } from '../contexts/AuthContext'
import useUser from '../api/useUser'
import useOrganization from '../api/useOrganization'
import { useServiceRequests } from '../api/useServiceRequests'
import { useOrgIncomingServiceRequests } from '../api/useOrgIncomingServiceRequests'
import _ from 'lodash'
import MetricsPDF from './MetricsPDF'
import { ServiceRequest } from 'src/shared/models/ServiceRequest'
import MetricsCSV from './MetricsCSV'
export interface ServiceCSVData {
  requestType: 'OUTGOING' | 'INCOMING'
  serviceType: string
  memberId: string
  memberZipCode: string
}
export interface ServicePDFData {
  type: string
  zipCodes: ZipCodeData[]
  membersServed: number
}
interface ZipCodeData {
  zipCode: string | null
  membersServed: number
}

export default function MetricsReport(): JSX.Element {
  const { userId, userAttributes } = useContext(AuthContext)
  const { data: userData } = useUser(userId)
  const { data: organization } = useOrganization(userData?.organizationIds[0])
  const {
    data: outServiceRequests = [],
  } = useServiceRequests(userAttributes.OrgId, 
  { 
    refetchOnWindowFocus: false,
  })

  const {
    data: inServiceRequests = [],
  } = useOrgIncomingServiceRequests({ userId, organizationId: userAttributes.OrgId}, 
    {
      refetchOnWindowFocus: false,
    }
  )

  const generatePDFData = (data: ServiceRequest[]): ServicePDFData[] => {
    const svcTypes = _.groupBy(data, 'service.type')
    const svcEntries = Object.entries(svcTypes)
    let svcData: ServicePDFData[] = []
    for (const entry in svcEntries) {
      const svc = {} as ServicePDFData
      svc.type = svcEntries[entry][0]
      svc.membersServed = svcEntries[entry][1].length
      const groupedZipCodes = Object.entries(_.groupBy(svcEntries[entry][1], 'patient.zipCode'))
      let zipCodes = []
      for (const code in groupedZipCodes) {
        const zipCodeData = { zipCode: groupedZipCodes[code][0], membersServed: groupedZipCodes[code][1].length}
        zipCodes.push(zipCodeData)
      }
      svc.zipCodes = zipCodes
      svcData.push(svc)
    }
    return svcData
  }

  const generateCSVData = (outData: ServiceRequest[], inData: ServiceRequest[]): ServiceCSVData[] => {
    let svcData: ServiceCSVData[] = []

    for (let i=0; i<outData.length; i++) {
      const row = {} as ServiceCSVData

      row.requestType = 'OUTGOING'
      row.serviceType = outData[i].service.type
      row.memberId = outData[i].patient.id
      row.memberZipCode = outData[i].patient.zipCode

      svcData.push(row)
    }

    for (let i=0; i<inData.length; i++) {
      const row = {} as ServiceCSVData

      row.requestType = 'INCOMING'
      row.serviceType = inData[i].service.type
      row.memberId = inData[i].patient.id
      row.memberZipCode = inData[i].patient.zipCode

      svcData.push(row)
    }
    return svcData
  }

  const outSvcDataPDF = generatePDFData(outServiceRequests as ServiceRequest[])
  const inSvcDataPDF = generatePDFData(inServiceRequests as ServiceRequest[])

  const csvData = generateCSVData(outServiceRequests as ServiceRequest[], inServiceRequests as ServiceRequest[])

  return (
    <IonRow>
      <IonCol size='2'>
        <MetricsPDF organization={organization} outServiceData={outSvcDataPDF} inServiceData={inSvcDataPDF} />
      </IonCol>
      <IonCol size='2'>
        <MetricsCSV organization={organization} csvData={csvData} />
      </IonCol>
    </IonRow>
  
  )
}
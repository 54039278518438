/* eslint-disable */

import React, { useMemo } from 'react'
import Select from 'react-select'
import useOrganizationServices from '../../api/useOrganizationServices'
import styles from '../styles.module.scss'

const Column = {
  SELECT: 'select',
  SERVICE_TYPE: 'serviceType',
  SERVICE_NAME: 'serviceName',
}

type FilterTypes = {
  serviceName: string
  serviceType: string
}

interface IProps {
  orgId: number
  setFilters: React.Dispatch<React.SetStateAction<FilterTypes>>
}

const Filters = ({ orgId, setFilters }: IProps) => {
  const { data: services = [] } = useOrganizationServices(orgId)

  const names = useMemo(() => {
    return services.map((service) => ({
      ...service,
      value: service.name,
      label: service.name,
    }))
  }, [services])

  const types = useMemo(() => {
    return [...new Map(services.map((service) => ([service['type'], service]))).values()]
      .map(service => ({
        ...service,
        value: service.type,
        label: service.type,  
      }))
  }, [services])

  const onSelectType = (selected: any) => {
    if (!selected) {
      setFilters((item) => ({ ...item, serviceType: '' }))
      return
    }
    setFilters((item) => ({ ...item, serviceType: selected.type }))
  }

  const onSelectName = (seleted: any) => {
    if (!seleted) {
      setFilters((item) => ({ ...item, serviceName: '' }))
      return
    }
    setFilters((item) => ({ ...item, serviceName: seleted.name }))
  }

  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filterItem}>
        <Select
          options={types}
          onChange={onSelectType}
          className={styles.select}
          isClearable
          placeholder='Select service type'
        />
      </div>
      <div className={styles.filterItem}>
        <Select
          options={names}
          onChange={onSelectName}
          className={styles.select}
          isClearable
          placeholder='Select service name'
        />
      </div>
    </div>
  )
}

export default Filters

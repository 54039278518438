/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react'
import { mapUserAttributes } from './util'
import { getCognitoAttribute, getCurrentUser } from '../authentication/auth'
import { UserAttributes } from '../schema'

export const AuthContext = React.createContext({
  isLoggedIn: false as boolean,
  token: '' as string,
  userId: '' as string,
  userAttributes: {} as UserAttributes,
})
AuthContext.displayName = 'AuthContext'

type AuthContextProps = {
  children: JSX.Element | JSX.Element[]
}
export function AuthProvider(props: AuthContextProps): JSX.Element {
  const token = localStorage.getItem('token')

  const [currentUserAttributes, setCurrentUserAttributes] = useState([])

  const userId = useMemo(() => {
    return getCognitoAttribute(currentUserAttributes, 'custom:userId')
  }, [currentUserAttributes])

  useEffect(() => {
    getCurrentUser((attributes: any) => {
      setCurrentUserAttributes(attributes)
    })
  }, [getCurrentUser])
  return (
    <AuthContext.Provider
      value={{
        token: token as string,
        isLoggedIn: Boolean(token),
        userId: userId as string,
        userAttributes: mapUserAttributes(
          currentUserAttributes,
        ) as UserAttributes,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

/* eslint-disable  */

import {
  IonBackdrop,
  IonButton,
  IonCheckbox,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
} from '@ionic/react'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Modal from 'react-modal'
import { Controller, FormState, useForm } from 'react-hook-form'
import { closeSharp } from 'ionicons/icons'
import classNames from 'classnames'
import { Store } from 'react-notifications-component'

import { sendUserList } from '../api/sendUserList'
import useOrganizationServices from '../api/useOrganizationServices'

import {
  PatientListUploadModalFormValues,
  PatientListUploadModalProps,
} from './interfaces'

import { getServiceTypeName } from '../employee/utils/serviceTypes'
import { VerticalStep } from './components/VerticalStep'
import { FileUploadZone } from './components/FileUploadZone'
import { AuthContext } from '../contexts/AuthContext'
import { defaultNotificationOptions } from '../shared/Notification'

import styles from './styles.module.scss'

const excelFileTypes =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx'

export function PatientListUploadModal(props: PatientListUploadModalProps) {
  const { userAttributes } = useContext(AuthContext)
  const {
    data: organizationServicesQuery,
    isSuccess: isOrgQuerySuccess,
    isLoading: isOrgQueryLoading,
  } = useOrganizationServices(Number(userAttributes?.OrgId))
  const [availableServiceTypes, setAvailableServiceTypes] = useState<string[]>(
    [],
  )
  useEffect(() => {
    if (!isOrgQuerySuccess || !organizationServicesQuery) return

    const availableServiceTypes = [
      ...new Set(
        organizationServicesQuery.map(
          (organizationService) => organizationService.type,
        ),
      ),
    ]

    setAvailableServiceTypes(availableServiceTypes)
  }, [isOrgQuerySuccess, organizationServicesQuery])

  const { setValue, handleSubmit, formState, control, watch } =
    useForm<PatientListUploadModalFormValues>({
      defaultValues: useMemo(
        () => ({
          serviceIds: [],
          serviceStatus: 'COMPLETED',
        }),
        [],
      ),
    })
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [serviceStatus, setServiceStatus] = useState('COMPLETED')
  const handleFileUploadZoneAccept = useCallback((acceptedFiles: File[]) => {
    setAcceptedFiles((prev) => [...prev, ...acceptedFiles])
  }, [])

  const [isFilesUploading, setFilesUploading] = useState(false)

  const handleSubmitClick = useCallback(
    (formValues) => {
      if (formValues?.serviceIds?.length === 0) return
      if (acceptedFiles?.length === 0) return

      setFilesUploading(true)
      Promise.all(
        acceptedFiles.map((file) =>
          sendUserList({
            orgId: Number(userAttributes.OrgId),
            serviceIds: formValues.serviceIds,
            userId: userAttributes.UserId,
            file: file,
            serviceStatus: serviceStatus,
          }),
        ),
      )
        .then((responses) => {
          const isSuccess = responses.every((r) => r.status === 200)
          if (isSuccess) {
            props.onSubmit()
          } else {
            Store.addNotification({
              ...defaultNotificationOptions,
              type: 'danger',
              message:
                'Some files were not uploaded properly. Please, try again.',
            })
          }
        })
        .catch((_e) => {
          Store.addNotification({
            ...defaultNotificationOptions,
            type: 'danger',
            message:
              'Some files were not uploaded properly due to network error. Please, try again later.',
          })
        })
        .finally(() => {
          setAcceptedFiles([])
          setFilesUploading(false)
        })
    },
    [watch('serviceIds'), acceptedFiles, serviceStatus],
  )

  const isUiBlocked = isFilesUploading || isOrgQueryLoading

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={props.onCancel}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      {isUiBlocked ? <IonBackdrop /> : null}
      {isFilesUploading && (
        <IonLoading
          isOpen={true}
          message='Please wait until the documents are uploaded.'
        />
      )}
      <IonIcon
        className={styles.closeCross}
        icon={closeSharp}
        onClick={props.onCancel}
      />
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Upload your list</h2>
        <div className={styles.info}>
          Select your applicable services below and upload your member names
          list. The names should appear in your organization’s patient list.
          &nbsp;
        </div>
        <form
          onSubmit={handleSubmit(handleSubmitClick)}
          noValidate
          className={styles.form}
        >
          <div className={styles.step}>
            <VerticalStep content='1' showLine />

            <div className={styles.requiredServices}>
              <div className={styles.requiredServicesTitle}>
                <span className={styles.requiredMark}>*</span>Select Required
                Services:
              </div>
              <p className={styles.requiredServicesInfo}>
                Select All of the services needed from the list provided below.
              </p>
              {!availableServiceTypes?.length ? (
                <IonSpinner className={styles.availableServiceTypesSpinner} />
              ) : (
                <>
                  <div className={styles.requiredServicesBody}>
                    <div className={styles.requiredServicesColumn}>
                      {availableServiceTypes.map((serviceType) => (
                        <div
                          key={serviceType}
                          className={styles.requiredService}
                        >
                          <div className={styles.requiredServiceType}>
                            {getServiceTypeName(serviceType)}
                          </div>
                          {(isOrgQuerySuccess && !!organizationServicesQuery
                            ? organizationServicesQuery
                            : []
                          )
                            .filter((service) => service.type === serviceType)
                            .map((service) => (
                              <div
                                key={service.type + service.id}
                                className={styles.requiredServiceOption}
                              >
                                <IonItem lines='none'>
                                  <Controller
                                    name='serviceIds'
                                    control={control}
                                    shouldUnregister={true}
                                    rules={{ required: true, minLength: 1 }}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <IonCheckbox
                                        title={String(service.id)}
                                        value={String(service.id)}
                                        checked={(value || []).includes(
                                          service.id,
                                        )}
                                        onIonChange={(e) => {
                                          const isChecked = e?.detail.checked
                                          setValue(
                                            'serviceIds',
                                            isChecked
                                              ? [...value, service.id]
                                              : value.filter(
                                                  (i) => i !== service.id,
                                                ),
                                          )
                                        }}
                                      />
                                    )}
                                  />
                                  <IonLabel>{service.name}</IonLabel>
                                </IonItem>
                              </div>
                            ))}
                        </div>
                      ))}
                    </div>
                  </div>
                  {formState?.errors.serviceIds && (
                    <span className='ion-padding-start'>
                      {renderErrorMessage(formState, 'serviceIds')}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={classNames(styles.step, styles.stepTwo)}>
            <VerticalStep content='2' />
            <div className={styles.stepTwoContent}>
              <div className={styles.uploadListTitle}>
                <span className={styles.requiredMark}>*</span>Upload your
                document:
              </div>
              <p className={styles.uploadListInfo}>
                Drag and drop or click the choose file button to upload your
                list files
              </p>
              <FileUploadZone
                acceptableFileTypes={excelFileTypes}
                onFilesAccepted={handleFileUploadZoneAccept}
                acceptedFiles={acceptedFiles}
              />
            </div>
          </div>
          <div className={classNames(styles.step, styles.stepTwo)}>
            <VerticalStep content='3' />
            <div className={styles.stepTwoContent}>
              <div className={styles.uploadListTitle}>
                <span className={styles.requiredMark}>*</span>Request Status:
              </div>
              <IonSelect
                placeholder='Status'
                onIonChange={(e) => setServiceStatus(e.detail.value)}
              >
                <IonSelectOption value='REQUESTED' key='REQUESTED'>
                  Requested
                </IonSelectOption>
                <IonSelectOption value='COMPLETED' key='COMPLETED'>
                  Completed
                </IonSelectOption>
              </IonSelect>
            </div>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.buttons}>
            <IonButton
              size='large'
              className={styles.cancelButton}
              onClick={props.onCancel}
            >
              Cancel
            </IonButton>
            <IonButton
              size='large'
              type='submit'
              fill='solid'
              className={styles.submitButton}
            >
              Submit list
            </IonButton>
          </div>
        </form>
      </div>
    </Modal>
  )
}

function renderErrorMessage(
  formState: FormState<PatientListUploadModalFormValues>,
  name: string,
) {
  if (name in formState?.errors) {
    return (
      <IonText color='danger'>
        <small>This is a required field</small>
      </IonText>
    )
  }

  return null
}

/* eslint-disable  */

import { IonButton } from '@ionic/react'
import React from 'react'
import Modal from 'react-modal'

import { SuccessModalProps } from './interfaces'
import styles from './styles.module.scss'

export function SuccessModal({ onClose, text }: SuccessModalProps) {
  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={onClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      <h2 className={styles.title}>Success</h2>
      <div className={styles.successImage}>
        <svg
          width='194'
          height='194'
          viewBox='0 0 194 194'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle
            className={styles.circle}
            cx='96.5312'
            cy='96.5312'
            r='96.5312'
            fill='#37D6BA'
          />
          <circle cx='96.5312' cy='96.5312' r='96.5312' stroke='none' />
          <path
            className={styles.checkmark}
            d='M61.3713 105.116L85.1173 125.153C86.0371 125.929 87.4188 125.78 88.152 124.826L132.971 66.4854'
            stroke='white'
            strokeWidth='12.6631'
            strokeLinecap='round'
          />
        </svg>
      </div>
      <div className={styles.explanatoryText}>{text}</div>
      <div className={styles.buttons}>
        <IonButton
          shape='round'
          className={styles.closeButton}
          onClick={onClose}
        >
          Continue
        </IonButton>
      </div>
    </Modal>
  )
}

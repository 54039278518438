/* eslint-disable */
import { authenticateUser } from './auth'

export default async function Login(email, password) {
  let user = null
  let error = null
  try {
    user = await authenticateUser(email, password)
  } catch (err) {
    error = err
  }
  return { user, error }
}

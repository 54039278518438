/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react'
import { useParams } from 'react-router'
import 'survey-react/survey.css'
import * as Survey from 'survey-react'
import { getSurveyByUniqueId, submitSurvey } from 'src/api/useSurvey'
import 'survey-core/modern.min.css'
import { JsonObject, Model, Question, SurveyModel } from 'survey-core'

const SurveyDisplay = () => {
  const params = useParams() as string
  const [surveyModel, setSurveyModel] = useState(null)
  Survey.StylesManager.applyTheme('modern')

  useEffect(() => {
    const fetchSurvey = async () =>
      await getSurveyByUniqueId(params.id).then((res) => {
        const model = new Survey.Model(res.data)
        const firstPage = model.getPage(0)
        model.surveyId = res.uniqueId
        if (firstPage) {
          const firstName = firstPage.addNewQuestion('text', 'FirstName', 0)
          firstName.title = 'Enter your first name:'
          // Add a LastName question to the page
          const lastName = firstPage.addNewQuestion('text', 'LastName', 1)
          lastName.title = 'Enter your last name:'
          const phoneNumber = firstPage.addNewQuestion('text', 'PhoneNumber', 2)
          phoneNumber.title = 'Enter your phone number:'
          phoneNumber.isRequired = true
        }

        setSurveyModel(model)
      })

    fetchSurvey()
  }, [params])

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const onCompleteHandler = async (
    survey: SurveyModel,
    options: any,
  ): Promise<void> => {
    await getSurveyByUniqueId(params.id).then((res) => {
      let baseModel = new Model(res.data)
      let props = JsonObject
      let action = {}
      let userPhoneNumber = survey.getQuestionByName('PhoneNumber').value
      let firstName = survey.getQuestionByName('FirstName').value
      let lastName = survey.getQuestionByName('LastName').value

      if (res.data['pages']) {
        res.data['pages'].flat().forEach((p) => {
          if (!p['elements']) return

          p['elements'].flat().map((e) => {
            if (e['service']) {
              action = {
                ...action,
                type: e['surveyAction'],
                value: e['service'],
              }
            }
          })
        })
      }

      submitSurvey({
        surveyAnswerJson: survey.data,
        surveyUniqueId: params.id,
        version: 1,
        userFirstName: firstName,
        userLastName: lastName,
        userPhoneNumber: userPhoneNumber,
        action: action,
      })
    })
  }

  return (
    <>
      {surveyModel && (
        <IonPage>
          <IonHeader>
            <IonToolbar />
          </IonHeader>
          <IonContent>
            <Survey.Survey model={surveyModel} onComplete={onCompleteHandler} />
          </IonContent>
        </IonPage>
      )}
    </>
  )
}

export default SurveyDisplay

/* eslint-disable */
import { useMutation } from 'react-query'
import { SLACK_API_URL } from './constants'

interface FeedbackProps {
  messageType: string
  feedback: string
}

interface MutationProps {
  onSuccess: (res: Response) => void
  onError: (err: Error) => void
}

const sendFeedback = ({ messageType, feedback }: FeedbackProps) => {
  return fetch(`${SLACK_API_URL}`, {
    method: 'POST',
    mode: 'no-cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      text: `${messageType} \n${feedback}`,
    }),
  })
}

export function useSendFeedback({ onSuccess, onError }: MutationProps) {
  return useMutation(sendFeedback, {
    onSuccess,
    onError,
  })
}

/* eslint-disable */
import { useQuery } from 'react-query'
import { BASE_API_URL } from './constants'
import { ServiceRequest } from '../shared/models/ServiceRequest'
import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { jsonToQueryString } from './utils'
import { IFetchServiceRequestsParams } from './interfaces'

const getServiceRequests = async ({ queryKey }: any) => {
  const [_key, { organizationId, userId, status }] = queryKey
  let url = `${BASE_API_URL}/v1/organizations/${organizationId}/service-requests`
  if (organizationId == null) {
    return []
  }
  if (status != null) {
    const queryString = jsonToQueryString({ status })
    url += `?${queryString}`
  }

  return fetch(url, {
    headers: {
      userId: userId,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`${response.status}`)
    }
    return response.json()
  })
}

export function useServiceRequests(
  organizationId: string | null,
  {
    refetchOnWindowFocus = false,
    onError,
    onSuccess,
  }: IFetchServiceRequestsParams,
  status?: string,
) {
  const { userId } = useContext(AuthContext)
  return useQuery(
    [`service-requests`, { organizationId, userId, status }],
    getServiceRequests,
    { refetchOnWindowFocus, onError, onSuccess },
  )
}

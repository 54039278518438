/* eslint-disable  */

import React, { useEffect, useState, useContext } from 'react'
import Modal from 'react-modal'
import { IonButton } from '@ionic/react'
import styles from '../NewServiceRequest/styles.module.scss'
import { getSurveyAnswersById, getSurveyById } from '../../api/useSurvey'
import FormDisplay from '../../custom-forms/components/FormDisplay'
import { FormAnswersRequestProps } from './interfaces'
import 'survey-react/survey.css'
import * as Survey from 'survey-react'
import { RouteProps } from 'react-router-dom'


export function FormAnswersModal({
  surveys,
  onCancel,
}: FormAnswersRequestProps) {
  const [currentFormTemplate, setFormTemplate] = useState()
  const [currentFormAnswers, setFormAnswers] = useState()
  const answersFormId = localStorage.getItem('answersFormId') ?? ''
  let inProgressForm

  useEffect(() => {
    const fetchAnswers = async (answersFormId: string) => {
      const surveyAnswers = await getSurveyAnswersById(answersFormId)
      const surveyTemplate = await getSurveyById(surveyAnswers?.surveyId || -1);
  
      setFormTemplate(surveyTemplate.data)
      setFormAnswers(surveyAnswers.data)  
    }

    fetchAnswers(answersFormId)
  }, [])

  const setInProgressFormHandler = (survey: any) => {
    inProgressForm = survey
  }

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      ariaHideApp={false}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={onCancel}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      <div className={styles.wrapper}>
        <div className={styles.patient}>
          <FormDisplay
            formTemplate={currentFormTemplate}
            formAnswers={currentFormAnswers}
            setForm={(survey: any) => {setInProgressFormHandler(survey)}}
            setFormAnswers={(answers: any) => {}}
          />
          <div className={styles.buttons}>
            <IonButton
              shape='round'
              className={styles.cancelButton}
              onClick={onCancel}
            >
              Close
            </IonButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}

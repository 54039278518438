/* eslint-disable */

import React, { Dispatch, SetStateAction } from 'react'
import { Patient } from '../../employee/interfaces'
import Table from '../../table/Table'
import useFilterColumns from '../useFilterColumns'
import Filters from './Filters'

interface PatientsTabProps {
  orgId: number
  setFilters: Dispatch<
    SetStateAction<{ serviceType: string; serviceName: string }>
  >
  isFetching: boolean
  patients: Patient[]
  onPatientSelect: (selected: any) => void
}

const PatientsTab = ({
  orgId,
  setFilters,
  isFetching,
  patients,
  onPatientSelect,
}: PatientsTabProps) => {
  const columns = useFilterColumns()

  return (
    <>
      <Filters setFilters={setFilters} orgId={orgId} />
      {!isFetching && (
        <Table
          data={patients}
          columns={columns}
          showFilters
          getSelectedRows={onPatientSelect}
        />
      )}
    </>
  )
}

export default PatientsTab

/* eslint-disable */

import { IonFab, IonFabButton, IonIcon } from '@ionic/react'
import { help } from 'ionicons/icons'
import React, { useState } from 'react'
import FeedbackForm from './FeedbackForm'

export default function HelpFab(): JSX.Element {
  const [open, setOpen] = useState(false)
  return (
    <>
      {open && <FeedbackForm setOpen={setOpen} />}
      <IonFab vertical='bottom' horizontal='end' slot='fixed'>
        <IonFabButton
          size='small'
          activated={open}
          onClick={() => setOpen(!open)}
        >
          <IonIcon icon={help} />
        </IonFabButton>
      </IonFab>
    </>
  )
}

/* eslint-disable */
export const jsonToQueryString = (params: Object) => {
  return Object.keys(params)
    .map((key) => key + '=' + params[key as keyof Object])
    .join('&')
}

type Extension = 'jpg' | 'jpeg' | 'png' | 'pdf'

const mimeTypes = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf',
}

export const getMimeType = (filename: string) => {
  const ext = filename.split('.').pop()
  if (ext) {
    return mimeTypes[ext as Extension]
  }
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
  return btoa(
    new Uint8Array(buffer).reduce((data, byte) => {
      return data + String.fromCharCode(byte)
    }, ''),
  )
}

export async function fetchFile(url: string, name: string) {
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  const binaryData = await response.arrayBuffer()
  const base64 = arrayBufferToBase64(binaryData)
  return `data:${getMimeType(name)};base64,${base64}`
}

/* eslint-disable  */

import React from 'react'
import Modal from 'react-modal'
import { IonCol, IonGrid, IonIcon, IonLoading, IonRow } from '@ionic/react'

import { closeSharp, personCircleOutline } from 'ionicons/icons'
import { Patient } from '../../../employee/interfaces'
import { usePatientMessages } from '../../../api/usePatientSMS'
import styles from './styles.module.scss'

interface IProps {
  patient: Patient
  onClose: () => void
}

export function PatientSMSModal({ patient, onClose }: IProps) {
  const { data: messages = [], isLoading } = usePatientMessages(patient.id, {
    enabled: !!patient.id,
  })

  const formatDate = (date: string) => {
    const tempDate = new Date(date).toString().split(' ')
    return ` ${tempDate[1]}. ${tempDate[2]},${+tempDate[3]}`
  }

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={onClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      <IonIcon
        className={styles.closeCross}
        icon={closeSharp}
        onClick={onClose}
      />
      <div className={styles.wrapper}>
        <h2 className={styles.smsTitle}>
          {patient.firstName} {patient.lastName} - Messaging Transcript
        </h2>
        <div className={styles.smsWrapper}>
          <IonLoading isOpen={isLoading} />
          {messages.map((item: any) => {
            return (
              <IonGrid key={item.id}>
                <IonRow>
                  <IonCol size='1'>
                    <IonIcon
                      className={styles.profileImage}
                      icon={personCircleOutline}
                      onClick={onClose}
                    />
                  </IonCol>
                  <IonCol size='9'>
                    {item.isReceived ? (
                      <div className={styles.smsText}>{item.smsText}</div>
                    ) : (
                      <div className={styles.smsText}>{item.smsText}</div>
                    )}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size='10'>
                    <div className={styles.smsTimeStamp}>
                      {item.sender?.firstName} {item.sender?.lastName}
                      {formatDate(item.createTimestamp)}
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

/* eslint-disable */

import { IonButton, IonIcon } from '@ionic/react'
import React from 'react'
import Step from './Step'
import StepContent from './StepContent'
import styles from '../styles.module.scss'
import { chevronForward } from 'ionicons/icons'

interface IProps {
  step: string
  title: string
  isShow?: boolean
  isStepDone?: boolean
  nextdisabled?: boolean
  onClick?: () => void
  hasStepContent?: boolean
  children: any
}

const StepContainer = ({
  step,
  title,
  isShow = true,
  isStepDone: isStepShow = false,
  nextdisabled = false,
  onClick,
  hasStepContent = true,
  children,
}: IProps): JSX.Element | null => {
  if (!isShow) {
    return null
  }

  const renderContent = () => {
    if (hasStepContent) {
      return (
        <>
          <StepContent isStepDone={isStepShow}>{children}</StepContent>
          {!isStepShow && (
            <div className={styles.stepNext}>
              <IonButton
                disabled={nextdisabled}
                className={styles.nextButton}
                onClick={onClick}
              >
                Next
                <IonIcon
                  style={{ marginLeft: 10 }}
                  icon={chevronForward}
                  color='#fff'
                />
              </IonButton>
            </div>
          )}
        </>
      )
    }
    return children
  }

  return (
    <React.Fragment>
      <Step step={step}>{title}</Step>
      {renderContent()}
    </React.Fragment>
  )
}

export default StepContainer

/* eslint-disable  */

import React, { useCallback, useContext, useState } from 'react'
import classNames from 'classnames'
import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
} from '@ionic/react'
import useUser from '../api/useUser'
import { AuthContext } from '../contexts/AuthContext'
import Page from '../page/Page'
import ProfileIcon from '../profile/ProfileIcon'

import styles from './styles.module.scss'
import useOrganizationServices from '../api/useOrganizationServices'
import useOrganization, { useFetchOrgLogo } from '../api/useOrganization'
import { addCircleOutline } from 'ionicons/icons'
import { formatDisplayDate } from '../shared/utils/formatTimestamp'
import { useCreateService } from '../api/createOrganization'
import { UserTypeFormatted } from '../shared/models/UserType'
import NewServiceModal from './components/NewServiceModal/NewServiceModal'
import { useConfirmationModal } from '../shared/ConfirmationModal'
import { useRemoveService } from '../api/useRemoveService'
import EditServiceModal from './components/EditServiceModal/EditServiceModal'
import { Service } from './interfaces'
import { useEditOrgModal } from './components/EditOrgModal'
import { User } from '../shared/models/User'
import { useEditUserModal } from '../shared/EditUserModal'

const UserInfo = [
  { name: 'ID', key: 'id' },
  { name: 'Birthdate', key: 'dateOfBirth' },
  { name: 'Zipcode', key: 'zipCode' },
  { name: 'Email', key: 'email' },
  { name: 'Phone #', key: 'phoneNumber' },
  { name: 'Address', key: 'addressLine1' },
]

const ProfileManagement = () => {
  const [isServiceModal, setIsServiceModal] = useState(false)
  const [isEditServiceModal, setIsEditServiceModal] = useState(false)
  const [isEditUserModal, setIsEditUserModal] = useState(false)

  const { userAttributes } = useContext(AuthContext)

  const {
    data: user,
    isSuccess: isUserQuerySuccess,
    isError: isUserQueryError,
  } = useUser(userAttributes.UserId)

  const {
    data: organization,
    isSuccess: isOrgQuerySuccess,
    isError: isOrgQueryError,
  } = useOrganization(userAttributes.OrgId)

  const { data: orgLogo } = useFetchOrgLogo(
    organization?.id,
    organization?.imageFilename,
    { enabled: isOrgQuerySuccess && !!organization?.imageFilename },
  )
  const {
    data: services,
    isSuccess: isServiceQuerySuccess,
    isError: isServiceQueryError,
    refetch,
  } = useOrganizationServices(organization?.id)

  const { mutate: createService } = useCreateService({
    onSuccess: () => {
      // on success
      refetch()
    },
    onError: () => {
      // on error
    },
  })

  const { mutate: removeService } = useRemoveService({
    onSuccess: () => {
      refetch()
    },
  })

  const { ConfirmationModal, show, setItemId } = useConfirmationModal({
    onConfirm: removeService,
    text: 'Are you sure you want to delete this service?',
  })

  const { EditOrgModal, showEditOrgModal } = useEditOrgModal({
    org: organization,
  })

  const closeEditUserModal = useCallback(() => {
    setIsEditUserModal(false)
  }, [setIsEditUserModal])

  const { EditUserModal } = useEditUserModal({
    userId: userAttributes?.UserId as string,
    onSuccess: closeEditUserModal,
    onCancel: closeEditUserModal,
  })

  const openUserEditModal = useCallback(() => {
    setIsEditUserModal(true)
  }, [setIsEditUserModal])

  const handleDelete = (serviceId: string | number) => {
    setItemId(serviceId)
    show()
  }

  const onAddService = () => {
    // Add service
    setIsServiceModal(true)
  }

  const renderValue = ({ key }: { key: string }) => {
    if (key === 'dateOfBirth') {
      return formatDisplayDate(user?.dateOfBirth as string)
    }
    return isUserQuerySuccess && !!user ? user[key as keyof User] : ''
  }

  return (
    <Page hideTopBar>
      <IonGrid>
        <IonRow>
          <IonText className={styles.pageTitle}>Account Management</IonText>
        </IonRow>

        <div className={styles.page}>
          <div>
            <div className={styles.section}>
              <div>
                <h5
                  className={classNames(
                    styles.sectionTitle,
                    styles.userInformationSectionTitle,
                  )}
                >
                  {isUserQuerySuccess &&
                    user &&
                    UserTypeFormatted[user.userType]}{' '}
                  Account
                </h5>
                <div className={styles.userIdentityWrapper}>
                  <ProfileIcon
                    size={'50'}
                    userName={user?.firstName + ' ' + user?.lastName}
                  />
                  <div className={styles.userIdentity}>
                    <div className={styles.userName}>
                      {user?.firstName} {user?.lastName}
                    </div>
                    <div className={styles.userType}>
                      {isUserQuerySuccess &&
                        user &&
                        UserTypeFormatted[user.userType]}
                    </div>
                  </div>
                  <div className={styles.editProfileWrapper}>
                    <IonButton
                      fill='clear'
                      className={styles.editProfileButton}
                      onClick={openUserEditModal}
                    >
                      <IonIcon
                        name='pencil'
                        className={styles.editProfileIcon}
                      />
                      Edit Profile
                    </IonButton>
                  </div>
                </div>
                <div className={styles.userDetails}>
                  {UserInfo.map((item, i) => {
                    return (
                      <div key={i} className={styles.userContactRow}>
                        <span className={styles.userContactKey}>
                          {item.name}:
                        </span>
                        <span className={styles.userContactValue}>
                          {renderValue(item)}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
              {/* <div className={styles.userAction}>
              <span
                onClick={onChangePasswordClick}
                className={styles.userTerminateButton}
              >
                Change Password
              </span>
            </div> */}
            </div>
          </div>

          <div className={classNames(styles.section, styles.section2)}>
            <div>
              <h5
                className={classNames(
                  styles.sectionTitle,
                  styles.userInformationSectionTitle,
                )}
              >
                Business Profile
              </h5>
              <div className={styles.userIdentityWrapper}>
                <ProfileIcon
                  size={'50'}
                  url={orgLogo}
                  userName={organization?.name}
                />
                <div className={styles.userIdentity}>
                  <div className={styles.userName}>{organization?.name}</div>
                </div>
              </div>
              <div className={styles.userContacts}>
                <div className={styles.sectionHeader}>
                  <p>Company Info</p>
                  <IonButton
                    fill='clear'
                    className={styles.editProfileButton}
                    onClick={showEditOrgModal}
                  >
                    <IonIcon name='pencil' className={styles.editProfileIcon} />
                    Edit Profile
                  </IonButton>
                </div>
              </div>

              <div className={styles.userContacts}>
                <div className={styles.userContactRow}>
                  <span className={styles.userContactKey}>Email:</span>
                  <span className={styles.userContactValue}>
                    {organization?.email}
                  </span>
                </div>
                <div className={styles.userContactRow}>
                  <span className={styles.userContactKey}>Phone #:</span>
                  <span className={styles.userContactValue}>
                    {organization?.phoneNumber}
                  </span>
                </div>
                <div className={styles.userContactRow}>
                  <span className={styles.userContactKey}>Address:</span>
                  <span className={styles.userContactValue}>
                    {organization?.addressLine1}
                  </span>
                </div>
                <div className={styles.userContactRow}>
                  <span className={styles.userContactKey}>Website:</span>
                  <span className={styles.userContactValue}>
                    {organization?.website}
                  </span>
                </div>
                <div className={styles.userContactRow}>
                  <span className={styles.userContactKey}>Company Bio:</span>
                  <span className={styles.userContactValue}>
                    {organization?.description}
                  </span>
                </div>
              </div>

              <div className={styles.userContacts}>
                <div className={styles.sectionHeader}>
                  <p>Company Services</p>
                  <IonButton
                    fill='clear'
                    className={styles.editProfileButton}
                    onClick={() => setIsEditServiceModal(true)}
                  >
                    <IonIcon name='pencil' className={styles.editProfileIcon} />
                    Edit Profile
                  </IonButton>
                </div>
              </div>

              <div className={styles.userContacts}>
                <div className={styles.userContactRow}>
                  <span className={styles.servicesContainer}>Services:</span>
                  <div>
                    {isServiceQuerySuccess &&
                      services?.map((service, i) => {
                        return (
                          <div key={i} className={styles.serviceListItem}>
                            <p className={styles.serviceName}>
                              Service #{i + 1} {service?.name}
                            </p>
                            <IonButton
                              className={styles.delete}
                              color='danger'
                              fill='clear'
                              size='small'
                              onClick={() => handleDelete(service.id)}
                            >
                              Delete
                            </IonButton>
                          </div>
                        )
                      })}
                    <div
                      className={styles.addServiceContainer}
                      onClick={onAddService}
                    >
                      <IonIcon
                        icon={addCircleOutline}
                        color='dark'
                        size='small'
                        className={styles.addIcon}
                      />
                      <p>Add a new service</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModal />
        <NewServiceModal
          isOpen={isServiceModal}
          organizationId={organization?.id}
          onAddService={createService}
          onClose={() => setIsServiceModal(false)}
        />
        {isEditServiceModal && isOrgQuerySuccess && isServiceQuerySuccess && (
          <EditServiceModal
            isOpen={isEditServiceModal}
            organizationId={organization?.id}
            services={services as Service[]}
            onClose={() => setIsEditServiceModal(false)}
            refetchServices={refetch}
          />
        )}

        <EditOrgModal />
        {isEditUserModal && <EditUserModal />}
      </IonGrid>
    </Page>
  )
}

export default ProfileManagement

/* eslint-disable  */

import React, { useCallback, useState, useMemo } from 'react'
import { SMSRequest } from '.'

export function useSMSRequestModal(props: {
  serviceRequest: any
  onSuccess: () => void
  onError?: () => void
  onSettled?: () => void
  onCanceled: () => void
  onClose: () => void
}) {
  const [isPopupVisible, setPopupVisible] = useState(false)

  const handleShow = useCallback(() => {
    setPopupVisible(true)
  }, [])
  const handleHide = useCallback(() => {
    setPopupVisible(false)
  }, [])

  const handleClose = useCallback(() => {
    setPopupVisible(false)
    props.onClose()
  }, [])

  const component = useMemo(() => {
    return () => (
      <>
        {isPopupVisible && (
          <SMSRequest
            onClose={handleClose}
            serviceRequest={props.serviceRequest}
          />
        )}
      </>
    )
  }, [isPopupVisible])

  return {
    isVisible: isPopupVisible,
    show: handleShow,
    hide: handleHide,
    SMSRequest: component,
  }
}

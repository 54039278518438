/* eslint-disable */
import { IonList } from '@ionic/react'
import React from 'react'

interface AccordionProps {
  children: JSX.Element | JSX.Element[]
}

export default function Accordion(props: AccordionProps): JSX.Element {
  return <IonList style={{ listSyle: 'none' }}>{props.children}</IonList>
}

/* eslint-disable */
import {
  IonBackdrop,
  IonButton,
  IonCheckbox,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonProgressBar,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
} from '@ionic/react'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import Modal from 'react-modal'
import { FormState, useForm } from 'react-hook-form'

import {
  SurveyListUploadModalFormValues,
  SurveyListUploadModalProps,
} from './interfaces'

import { AuthContext } from '../../contexts/AuthContext'
import styles from './styles.module.scss'
import { sendFormList } from '../../api/sendFormList'
import { Store } from 'react-notifications-component'
import { defaultNotificationOptions } from '../../shared/Notification'
import useOrganization from 'src/api/useOrganization'
import { closeSharp, warningSharp } from 'ionicons/icons'
import Accordion from 'src/components/accordion/Accordion'
import organization from 'src/__mocks__/organization'
import AccordionListItem from 'src/components/accordion/AccordionListItem'
import { FileUploadZone } from 'src/shared/SurveyListUploadModal/components/FileUploadZone'
import classNames from 'classnames'
import { getServiceTypeName } from 'src/shared/NewServiceRequest/utils/serviceTypes'
import useSurveysByOrganizationRequests from 'src/api/useSurvey'

export function SurveyListUploadModal(props: SurveyListUploadModalProps) {
  const [isFilesUploading, setFilesUploading] = useState(false)
  const [isFormValues, setFormValues] = useState(false)
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const { userAttributes } = useContext(AuthContext)
  const [allSurveys, setAllSurveys] = useState([])
  const [contentHidden, setContentHidden] = useState(true)
  const [uploadFileName, setUploadFileName] = useState('')
  const [uploadFileSize, setUploadFileSize] = useState(0)
  const [uploadComplete, setUploadComplete] = useState(false)
  const [uploadFailed, setUploadFailed] = useState(false)
  const [orgServices, setOrgServices] = useState<number[]>([])

  const excelFileTypes =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx, .csv'

  const handleFileUploadZoneAccept = useCallback((acceptedFiles: File[]) => {
    setFilesUploading(true)
    setFormValues(true)

    setTimeout(() => {
      setFilesUploading(false)
      setUploadComplete(true)
    }, 1200)

    setUploadFileName(acceptedFiles[0].name)
    setUploadFileSize(Math.round(acceptedFiles[0].size / 1024))

    setAcceptedFiles((prev) => [...prev, ...acceptedFiles])
  }, [])

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
    unregister,
    formState,
    control,
    setValue,
  } = useForm()

  const onSubmit = (data: any) => {
    setContentHidden((s) => !s)
  }

  const handleDocumentUpload = useCallback(
    (formValues) => {
      if (formValues?.formSelected?.length === 0) return
      if (acceptedFiles?.length === 0) return

      setFilesUploading(true)

      Promise.all(
        acceptedFiles.map((file) =>
          sendFormList({
            orgId: userAttributes.OrgId,
            surveyIds: formValues.formSelected,
            userId: userAttributes.UserId,
            file: file,
          }),
        ),
      )
        .then((responses) => {
          const isSuccess = responses.every((r) => r.status === 200)
          if (isSuccess) {
            props.onSubmit()
          } else {
            Store.addNotification({
              ...defaultNotificationOptions,
              type: 'danger',
              message:
                'Some files were not uploaded properly. Please, try again.',
            })
            setUploadFailed(true)
            setUploadComplete(false)
          }
        })
        .catch((_e) => {
          Store.addNotification({
            ...defaultNotificationOptions,
            type: 'danger',
            message:
              'Some files were not uploaded properly due to network error. Please, try again later.',
          })
          setUploadFailed(true)
        })
        .finally(() => {
          setAcceptedFiles([])
          setFilesUploading(false)
        })
    },
    [watch('serviceIds'), acceptedFiles],
  )

  const { data: surveys } = useSurveysByOrganizationRequests(
    userAttributes.UserId,
    userAttributes.OrgId,
  )

  useEffect(() => {
    setAllSurveys(surveys)
  }, [surveys])

  const isUiBlocked = isFilesUploading

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={props.onCancel}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      {isUiBlocked ? <IonBackdrop /> : null}
      <IonIcon
        className={styles.closeCross}
        icon={closeSharp}
        onClick={props.onCancel}
      />
      {!uploadComplete ? (
        <div className={styles.wrapper}>
          {contentHidden ? (
            <div>
              <h2 className={styles.title}>Upload Your Responses</h2>
              <div className={styles.info}>
                Start here to upload your responses – Fill in all fields below
                and click continue. &nbsp;
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                className={styles.form}
              >
                <div className={styles.eventContainer}>
                  <IonItem>
                    <IonLabel position='stacked'>
                      Event Title (Optional)
                    </IonLabel>
                    <IonInput
                      {...register('title')}
                      name='title'
                      placeholder='Enter event title'
                    >
                      {' '}
                    </IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position='stacked'>
                      Event Date (Optional)
                    </IonLabel>
                    <IonInput
                      {...register('eventDate')}
                      name='eventDate'
                      type='date'
                    />
                  </IonItem>
                </div>
                <IonItem
                  className={classNames(
                    { [styles.itemError]: formState.errors.formSelected },
                    styles.formSelected,
                  )}
                >
                  <IonLabel position='floating'>
                    Select Your Form{' '}
                    <span className={styles.requiredMark}>*</span>
                  </IonLabel>
                  <IonSelect
                    {...register('formSelected', {
                      required: 'Select one form',
                    })}
                    placeholder='Select a form from the dropdown list'
                  >
                    {allSurveys?.map((survey: any) => {
                      return (
                        <div className={styles.formContainer}>
                          <IonSelectOption
                            className={styles.formItem}
                            key={survey.id}
                            value={survey.id}
                          >
                            {survey.title} V.{survey.version}
                          </IonSelectOption>
                        </div>
                      )
                    })}
                  </IonSelect>
                </IonItem>
                {renderErrorMessage(formState, 'formSelected')}
                <div className={styles.separator}></div>
                {organization?.length ? (
                  <Accordion>
                    {orgServices.map((org, idx) => {
                      return (
                        <AccordionListItem
                          idx={idx}
                          header={
                            <>
                              <div className={styles.requiredServicesTitle}>
                                Select Required Services for{' '}
                                {organization.organizationName}
                              </div>
                            </>
                          }
                        >
                          <div className={styles.requiredServices}>
                            <p className={styles.requiredServicesInfo}>
                              Select All of the services needed from the list
                              provided below.
                            </p>
                            <div className={styles.requiredServicesBody}>
                              <div className={styles.requiredServicesColumn}>
                                {organization.services.map((service, idx) => (
                                  <div
                                    key={idx}
                                    className={styles.requiredService}
                                  >
                                    <div className={styles.requiredServiceType}>
                                      {getServiceTypeName(service.type)}
                                    </div>
                                    <div
                                      key={service.type + service.id + idx}
                                      className={styles.requiredServiceOption}
                                    >
                                      <IonItem
                                        lines='none'
                                        style={{ paddingBottom: '15px' }}
                                      >
                                        <Controller
                                          name='serviceIds'
                                          control={control}
                                          shouldUnregister={true}
                                          rules={{
                                            required: true,
                                            minLength: 1,
                                          }}
                                          render={({
                                            field: { onChange, value },
                                          }) => (
                                            <IonCheckbox
                                              title={String(service.id)}
                                              value={String(service.id)}
                                              checked={(value || []).includes(
                                                service.id,
                                              )}
                                              onIonChange={(e) => {
                                                const isChecked =
                                                  e?.detail.checked
                                                setValue(
                                                  'serviceIds',
                                                  isChecked
                                                    ? [...value, service.id]
                                                    : value.filter(
                                                        (i) => i !== service.id,
                                                      ),
                                                )
                                              }}
                                            />
                                          )}
                                        />
                                        <IonLabel>{service.name}</IonLabel>
                                      </IonItem>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            {formState?.errors.serviceIds && (
                              <span className='ion-padding-start'>
                                {renderErrorMessage(formState, 'serviceIds')}
                              </span>
                            )}
                          </div>
                        </AccordionListItem>
                      )
                    })}
                  </Accordion>
                ) : null}
                <div className={styles.serparator}></div>
                <div className={styles.buttons}>
                  <IonButton
                    size='large'
                    className={styles.confirmButton}
                    type='submit'
                    // onClick={props.onSubmit}
                  >
                    Confirm info and continue
                  </IonButton>
                </div>
              </form>
            </div>
          ) : (
            <div>
              {uploadFailed ? (
                <h2 className={styles.title}>
                  Upload Failed - Error State Message
                </h2>
              ) : (
                <h2 className={styles.title}>Upload Your Responses</h2>
              )}
              <div className={styles.info}>
                {uploadFailed && (
                  <div>
                    <div className={styles.eventContainer}>
                      <div className='d-flex ion-align-items-center'>
                        <IonIcon
                          style={{ color: 'red' }}
                          icon={warningSharp}
                        ></IonIcon>
                        <h2 className='mx-3'>{uploadFileName}</h2>
                        <small>{uploadFileSize} MB</small>
                      </div>
                      <p>68%</p>
                    </div>
                    <IonProgressBar
                      className={styles.dangerBar}
                      value={1}
                    ></IonProgressBar>
                    <p>
                      <IonText color='danger'>Error State Explination</IonText>
                    </p>
                    &nbsp;
                    <IonList style={{ lineHeight: '25px' }}>
                      Please review your documents data before reupload or
                      follow the steps below to reupload a new document.
                    </IonList>
                  </div>
                )}
                {/* <IonList lines='none'>
                1. Download a template here <u className={styles.csvDownload}>CSV Template Download</u>
              </IonList> */}
                <IonList lines='none'>
                  1. Add your survey answers with name and phone numbers to csv
                  file
                </IonList>
                <IonList lines='none'>
                  2. Upload it below for processing.
                </IonList>
              </div>
              <form
                onSubmit={handleSubmit(handleDocumentUpload)}
                noValidate
                className={styles.form}
              >
                <div className={classNames(styles.step, styles.stepTwo)}>
                  {isFilesUploading && (
                    <div>
                      <div className={styles.eventContainer}>
                        <div className='d-flex ion-align-items-center'>
                          <IonSpinner />
                          <h2 className='mx-3'>{uploadFileName}</h2>
                          <small>{uploadFileSize} MB</small>
                        </div>
                        <p>65%</p>
                      </div>
                      &nbsp;
                      <IonProgressBar
                        color='danger'
                        value={0.5}
                      ></IonProgressBar>
                    </div>
                  )}
                  {!isFilesUploading && (
                    <div className={styles.stepTwoContent}>
                      <div className={styles.uploadListTitle}>
                        <span className={styles.requiredMark}>*</span>Upload
                        your document:
                      </div>
                      <p className={styles.uploadListInfo}>
                        Drag and drop or click the choose file button to upload
                        your list files
                      </p>
                      <FileUploadZone
                        acceptableFileTypes={excelFileTypes}
                        onFilesAccepted={handleFileUploadZoneAccept}
                        acceptedFiles={acceptedFiles}
                      />
                    </div>
                  )}
                </div>
                <div className={styles.separator}></div>
                <div className={styles.buttons}>
                  <IonButton
                    size='large'
                    type='submit'
                    className={styles.confirmButton}
                    disabled={isFormValues ? false : true}
                  >
                    Continue
                  </IonButton>
                </div>
              </form>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.wrapper}>
          <h2 className={styles.title}>Success - Upload Complete</h2>
          <div className={styles.info}>
            Your responses were uploaded successfully - You can now view the
            results in your analytics.
          </div>
          <form
            onSubmit={handleSubmit(handleDocumentUpload)}
            noValidate
            className={styles.form}
          >
            <div className={classNames(styles.step, styles.stepTwo)}>
              <div className={styles.eventContainer}>
                <div className='d-flex ion-align-items-center'>
                  <IonCheckbox
                    checked={true}
                    mode='ios'
                    slot='end'
                    color='success'
                  />
                  <h2 className='mx-3'>{uploadFileName}</h2>
                  <small>{uploadFileSize} MB</small>
                </div>
              </div>
              &nbsp;
              <IonProgressBar color='danger' value={1}></IonProgressBar>
            </div>
            <div className={styles.separator}></div>
            <div className={styles.buttons}>
              <IonButton
                size='large'
                type='submit'
                className={styles.confirmButton}
                disabled={isFormValues ? false : true}
                // onClick={props.onSubmit}
              >
                Done
              </IonButton>
            </div>
          </form>
        </div>
      )}
    </Modal>
  )
}

function renderErrorMessage(
  formState: FormState<SurveyListUploadModalFormValues>,
  name: string,
) {
  if (name in formState?.errors) {
    return (
      <IonText color='danger'>
        <small>This is a required field</small>
      </IonText>
    )
  }

  return null
}

/* eslint-disable  */

import { IonButton } from '@ionic/react'
import React from 'react'
import Modal from 'react-modal'

import { ConfirmationModalProps } from './interfaces'
import styles from './styles.module.scss'

export function ConfirmationModal({
  text,
  onConfirm,
  onDismiss,
}: ConfirmationModalProps) {
  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={onDismiss}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      <h2 className={styles.title}>Confirm</h2>
      <div className={styles.explanatoryText}>{text}</div>
      <div className={styles.buttons}>
        <IonButton
          fill='clear'
          className={styles.closeButton}
          onClick={onDismiss}
        >
          Dismiss
        </IonButton>
        <IonButton
          fill='clear'
          className={styles.closeButton}
          onClick={onConfirm}
        >
          Proceed
        </IonButton>
      </div>
    </Modal>
  )
}

/* eslint-disable  */
import React, { useEffect } from 'react'
import styles from './Table.module.scss'
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  usePagination,
} from 'react-table'

import UpDownArrowIcon from '../public/assets/icons/UpDownArrowIcon.svg'

const PAGE_CHANGED = 'PAGE_CHANGED'
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED'
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED'
const SORT_BY_CHANGED = 'SORT_BY_CHANGED'
const FILTER_CHANGED = 'FILTER_CHANGED'

const defaultPropGetter = () => ({})
const PaginatedTable = ({ columns, data, ...props }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    getRowProps = defaultPropGetter,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy, selectedRowIds, globalFilterInput },
    } = useTable(
    {
      columns,
      data: data.content,
      initialState: {
        pageIndex: data.pageable.pageNumber,
        pageSize: data.pageable.pageSize
      },
      manualPagination: true,
      manualSortBy: true,
      pageCount: props.pageCount,
      autoResetPage: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (!props.showFilters) {
        return null
      }
      hooks.visibleColumns.push((cols) => [
        // Column for selection
        {
          id: 'select',
          // The header can use the table's getToggleAllRowsSelectedProps method
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className={styles.checkboxContainer}>
              <Checkbox {...getToggleAllRowsSelectedProps()} />
              <p>Select All</p>
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
        },
        ...cols,
      ])
    },
  )

  useEffect(() => {
    if (props.getSelectedRows) {
      props.getSelectedRows(selectedRowIds)
    }
  }, [selectedRowIds])

  useEffect(() => {
    props.dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    gotoPage(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    props.dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
  }, [pageSize, gotoPage]);

  useEffect(() => {
    if (sortBy && sortBy.length > 0) {
      const sort =  sortBy[0].desc == true ? `user.${sortBy[0].id},desc` : `user.${sortBy[0].id},asc`
      props.dispatch({ type: SORT_BY_CHANGED, payload: sort });  
    }
  }, [sortBy])

  useEffect(() => {
    if (data?.totalElements) {
        props.dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.totalElements,
      });
    }
  }, [data?.totalElements]);

  const assignClassName = (column) => {
    if (!column.isSorted) {
      return null
    }
    return column.isSortedDesc ? 'sort-desc' : 'sort-asc'
  }

  return (
    <>
      <table {...getTableProps()} className={styles.table}>
        <thead slot='fixed'>
          {headerGroups.map((headerGroup) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={assignClassName(column)}
                    >
                      <b>{column.render('Header')}</b>
                      {column.canSort && (
                        <span className={styles.icon}>
                          <img src={UpDownArrowIcon} />
                        </span>
                      )}
                    </th>
                  )
                })}
              </tr>
            )
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <React.Fragment key={row.getRowProps().key}>
                <tr
                  {...row.getRowProps({
                    onClick: props.onClick
                      ? () => {
                          props.onClick({ row })
                        }
                      : undefined,
                    className: `${props.onClick && styles.link} ${
                    getRowProps(row)?.className
                    }`,
                  })}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
        </button>{' '}
        <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
        >
            {'>>'}
        </button>{' '}
        <span>
            Page{' '}
            <strong>
            {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
        </span>
        <span>
            | Go to page:{' '}
            <input
            type="number"
            value={pageIndex + 1}
            onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
            }}
            style={{ width: '100px' }}
            />
        </span>{' '}
        <select
            value={pageSize}
            onChange={(e) => {
            setPageSize(Number(e.target.value));
            }}
        >
            {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
                Show {pageSize}
            </option>
            ))}
        </select>
      </div>
    </>
  )
}

export default PaginatedTable

/* eslint-disable */
import {
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
  IonText,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonList,
  IonBackdrop,
  IonLoading,
  IonProgressBar,
  IonSpinner,
  IonCheckbox
} from '@ionic/react'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Modal from 'react-modal'
import { FormState, useForm, useFormContext } from 'react-hook-form'
import { closeSharp, warningSharp } from 'ionicons/icons'

import {
  SurveyListUploadModalFormValues,
  SurveyListUploadModalProps,
} from './interfaces'

import { AuthContext } from '../../contexts/AuthContext'
import styles from './styles.module.scss'
import { getCurrentUser, getCognitoAttribute } from '../../authentication/auth'
import useSurveysByOrganizationRequests from "../../api/useSurvey";
import classNames from 'classnames'
import { FileUploadZone } from './components/FileUploadZone'
import { sendFormImportId } from '../../api/sendFormImportId'
import useUser from '../../api/useUser'
import { User } from '../../shared/models/User'
import { Store } from 'react-notifications-component'
import { defaultNotificationOptions } from '../../shared/Notification'

export function SurveyImportModal(props: SurveyListUploadModalProps) {
  const [isFilesUploading, setFilesUploading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const authContext = useContext(AuthContext)
  const userQuery = useUser(authContext.userId)
  const [formName, setFormName] = useState<String>('')
  const [currentUserAttributes, setCurrentUserAttributes] = useState([])
  const [uploadComplete, setUploadComplete] = useState(false)
  const [uploadFailed, setUploadFailed] = useState(false)
  const [contentHidden, setContentHidden] = useState(true);

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
    unregister,
    formState
  } = useForm()
  
  const onSubmit = (data: any) => {
    setContentHidden(s => !s)
  }

  const handleImportForm = useCallback(
    async (formValues) => {
        if (formValues?.formId === '') return

        setIsLoading(true)
        await sendFormImportId({
            formId: formValues.formId
        }).then((response) => {
            const isSuccess = response.status === 200
            if (isSuccess) {
                setIsLoading(false)
                props.onSubmit()
            }
            else
                setUploadFailed(true);
                setUploadComplete(false)
                props.onError()
        }).catch((_e) => {
            setUploadFailed(true);
            props.onError()
        }).finally(() => {
            setIsLoading(false)
        })
      },
      [watch('formId')],
    )

  useEffect(() => {
    getCurrentUser((attributes: any) => {
      setCurrentUserAttributes(attributes)
    })
  }, [getCurrentUser])

  
  const loggedInUserOrgId = useMemo(() => {
    return getCognitoAttribute(currentUserAttributes, 'custom:organizationId')
  }, [currentUserAttributes])

  const isUiBlocked =
    isLoading ||
    userQuery.isLoading

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={props.onCancel}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
        <IonIcon
            className={styles.closeCross}
            icon={closeSharp}
            onClick={props.onCancel}
        />
        <div className={styles.wrapper}>
        <h2 className={styles.title}>Import Your Survey</h2>
            <div className={styles.info}>
                Start here to import your survey form.
                &nbsp;
            </div>
                <form
                onSubmit={handleSubmit(handleImportForm)}
                noValidate
                className={styles.form}
            >
                <IonItem
                    className={classNames(
                    { [styles.itemError]: formState.errors.formSelected },
                    styles.formSelected,
                )}>
                <IonLabel position='floating'>
                    Enter Your Google Form Id <span className={styles.requiredMark}>*</span>
                </IonLabel>
                <IonInput
                    {...register('formId', { required: true })}
                    type='text'
                    name='formId'                
                    placeholder='Form Id'
                ></IonInput>
                </IonItem>
                {renderErrorMessage(formState, 'formSelected')}
                <div className={styles.separator}></div>
                <div className={styles.buttons}>
                    <IonButton
                        type='submit'
                        className={styles.confirmButton}
                        disabled={isLoading}
                    >
                        {isLoading ? <IonSpinner color='dark' /> : 'Submit'}
                    </IonButton>
                </div>
            </form>
        </div>
    </Modal>
  )
}

function renderErrorMessage(
  formState: FormState<SurveyListUploadModalFormValues>,
  name: string,
) {
  if (name in formState?.errors) {
    return (
      <IonText color='danger'>
        <small>This is a required field</small>
      </IonText>
    )
  }

  return null
}

/* eslint-disable */
import React, {
  useState,
  useCallback,
  FunctionComponent,
  useMemo,
  useContext,
} from 'react'
import { IonBackdrop } from '@ionic/react'
import { useMutation, useQueryClient } from 'react-query'
import { RouteProps } from 'react-router-dom'

import useUser from '../api/useUser'
import useUserSurveys from '../api/useUserSurveys'
import useAssignedServiceRequests, {
  updateAssignedServiceRequestInCache,
  getAssignedServiceRequestsQueryKey,
} from '../api/useAssignedServiceRequests'
import {
  getServiceRequestNotesQueryKey,
  addServiceRequestNoteInCache,
} from '../api/useServiceRequestNotes'

import Page from '../page/Page'
import { EmployeeSurveys } from './components/EmployeeSurveys'
import { EmployeeServiceRequests } from './components/EmployeeServiceRequests'
import {
  useCustomFormRequestModal,
  useNewServiceRequestModal,
  useSMSRequestModal,
} from '../shared/NewServiceRequest'
import { SuccessModal } from '../shared/SuccessModal'

import styles from './styles.module.scss'
import { UserInfo } from '../shared/UserInfo'
import { useEditUserModal } from '../shared/EditUserModal'
import { UserType, UserTypeFormatted } from '../shared/models/UserType'
import { ServiceRequest } from '../shared/models/ServiceRequest'
import { useServiceRequestReviewModal } from '../shared/ServiceRequestReviewModal'
import { Role } from './interfaces'
import { User } from '../shared/models/User'
import {
  promoteUserToAdmin,
  terminateUser,
  revokeAdmin,
  activateUser,
} from '../api/updateUserStatus'
import { AuthContext } from '../contexts/AuthContext'
import { Error } from '../shared/utils/constants'
import { ErrorModal } from '../shared/ErrorModal'
import { saveSurveyResponse } from '../api/useSurvey'

const options: Role[] = [
  { value: 'admin', label: 'Admin' },
  { value: 'employee', label: 'Employee' },
]

const EmployeeProfile: FunctionComponent<RouteProps> = (props: RouteProps) => {
  // @ts-ignore
  let userId = props.match.params.id

  const queryClient = useQueryClient()
  const { userId: currentUserId } = useContext(AuthContext)
  if (!userId) {
    userId = currentUserId
  }

  const { data: employee, refetch: refetchEmployee } = useUser(userId)
  const [role, setRole] = useState<Role>()
  const [roleError, setRoleError] = useState('')
  const [statusError, setStatusError] = useState('')
  const [error, setError] = useState('')
  const [selectedServiceRequest, setSelectedServiceRequest] = useState()
  const [onCloseSmsModal, setonCloseSmsModal] = useState(false)
  const { data: employeeSurveys } = useUserSurveys(userId)

  // Success Modal
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false)
  const handleSuccessModalClose = useCallback(() => {
    setSuccessModalVisible(false)
  }, [])

  // Employee Service Requests
  const {
    data: employeeServiceRequests,
    isLoading: isEmpServiceLoading,
    isError: isEmpServiceError,
  } = useAssignedServiceRequests(userId, currentUserId)
  if (employeeServiceRequests) {
    employeeServiceRequests.sort(
      (a: { createTimestamp: string }, b: { createTimestamp: string }) => {
        return Date.parse(b.createTimestamp) - Date.parse(a.createTimestamp)
      },
    )
  }
  const { mutate: mutatePromote } = useMutation(promoteUserToAdmin, {
    onError: () => {
      setRoleError(
        `Failed to promote ${employee?.firstName}, please try again.`,
      )
    },
  })

  const { mutate: mutateRevoke } = useMutation(revokeAdmin, {
    onError: () => {
      setRoleError(
        `Failed to update role of ${employee?.firstName}, please try again.`,
      )
    },
  })

  const { mutate: terminateEmployee } = useMutation(terminateUser, {
    onSuccess: () => {
      refetchEmployee()
    },
    onError: () => {
      setStatusError(
        `Failed to terminate ${employee?.firstName}, please try again.`,
      )
    },
  })

  const { mutate: activateEmployee } = useMutation(activateUser, {
    onSuccess: () => {
      refetchEmployee()
    },
    onError: () => {
      setStatusError(
        `Failed to activate ${employee?.firstName}, please try again.`,
      )
    },
  })

  // Service Request Preview
  const [
    serviceRequestChosen,
    setServiceRequestChosen,
  ] = useState<ServiceRequest | null>(null)
  const handleServiceRequestPreviewClose = useCallback(() => {
    setServiceRequestChosen(null)
  }, [setServiceRequestChosen])

  const handleServiceRequestViewClick = useCallback(
    (id) => {
      setServiceRequestChosen(
        (employeeServiceRequests as ServiceRequest[]).find(
          (i) => i.id === id,
        ) || null,
      )
    },
    [setServiceRequestChosen, employeeServiceRequests],
  )
  const getBindedServiceRequestNotesQueryKey = useMemo(
    () =>
      getServiceRequestNotesQueryKey.bind(
        null,
        userId,
        serviceRequestChosen?.id || NaN,
      ),
    [serviceRequestChosen?.id],
  )
  const getBindedAssignedServiceRequestsQueryKey = useMemo(
    () => getAssignedServiceRequestsQueryKey.bind(null, userId, currentUserId),
    [userId, currentUserId],
  )

  // SMS Request
  const { SMSRequest, show: showSMSRequest } = useSMSRequestModal({
    serviceRequest: selectedServiceRequest,
    onClose: () => {
      setonCloseSmsModal(true), onShowServiceRequestModal(true)
      const serviceRequest = JSON.parse(localStorage.getItem('serviceRequest'))

      setServiceRequestChosen(serviceRequest)
    },
    onSuccess: () => {},
    onCanceled: () => {},
  })

  const onSelectSMSTranscriptsHandler = (serviceRequest: any) => {
    setServiceRequestChosen(null)
    setSelectedServiceRequest(serviceRequest)
    showSMSRequest()
  }

  const {
    ServiceRequestReviewModal,
    show: onShowServiceRequestModal,
  } = useServiceRequestReviewModal({
    serviceRequest: serviceRequestChosen,
    onClose: handleServiceRequestPreviewClose,

    getQueryKeyForNotes: getBindedServiceRequestNotesQueryKey,
    addServiceRequestNoteInCache: addServiceRequestNoteInCache,

    getQueryKeyForServiceRequests: getBindedAssignedServiceRequestsQueryKey,
    updateServiceRequestInCache: updateAssignedServiceRequestInCache,
    onSelectSMSTranscripts: onSelectSMSTranscriptsHandler,
  })

  // New Service Request
  const handleNewServiceRequestSuccess = useCallback(() => {
    setSuccessModalVisible(true)
  }, [])
  const {
    NewServiceRequest,
    show: showNewServiceRequest,
    isVisible: isNewServiceRequestPopupVisible,
    isError: isSRError,
  } = useNewServiceRequestModal({
    onSuccess: (patientId) => {
      const survey = JSON.parse(localStorage.getItem('pendingSurvey'))
      if (patientId && survey) {
        survey.userId = patientId
        saveSurveyResponse(survey)
      }
    },
    onError: () => {
      setError(Error.SERVICE_REQUEST_CREATION_FAILED)
    },
  })

  // Custom Form Service Request
  const {
    CustomFormServiceRequest,
    show: showCustomFormServiceRequest,
    hide: hideCustomFormServiceRequest,
  } = useCustomFormRequestModal({
    isPendingModal: true, // if yes, it means we don't have the patient id yet
    onSuccess: () => {
      hideCustomFormServiceRequest()
      setSuccessModalVisible(true)
    },
    onPending: () => {
      hideCustomFormServiceRequest()
      showNewServiceRequest()
    },
    onCanceled: () => setSuccessModalVisible(null),
    onSkip: () => {
      hideCustomFormServiceRequest()
      showNewServiceRequest()
    },
  })

  const handleServiceRequestView = useCallback((id) => {
    alert(`clicked at Service Request ${id}`)
  }, [])

  // Employee Surveys
  const [
    isAssignNewSurveyPopupVisible,
    setAssignNewSurveyPopupVisible,
  ] = useState(false)
  const handleAssignNewSurveyClick = useCallback(() => {
    setAssignNewSurveyPopupVisible(true)
  }, [])

  const handleSurveyView = useCallback((id) => {
    alert(`clicked at Survey ${id}`)
  }, [])

  /// Employee edit
  const [isEmployeeEditing, setIsEmployeeEditing] = useState(false)
  const handleEmployeeEditClick = useCallback(() => {
    setIsEmployeeEditing(true)
  }, [setIsEmployeeEditing])
  // * Cancel
  const handleUserEditCancel = useCallback(() => {
    setIsEmployeeEditing(false)
  }, [setIsEmployeeEditing])
  // * Success
  const handleUserEditSuccess = useCallback(() => {
    setIsEmployeeEditing(false)
  }, [setIsEmployeeEditing])
  // * Error
  const handleUserEditError = useCallback(() => {
    alert('Something went wrong during updating user data')
    setIsEmployeeEditing(false)
  }, [setIsEmployeeEditing])
  const { EditUserModal, isVisible: isEditUserModalVisible } = useEditUserModal(
    {
      userId: userId,
      onSuccess: handleUserEditSuccess,
      onCancel: handleUserEditCancel,
      onError: handleUserEditError,
    },
  )

  const setUserRole = useCallback(
    (role: Role) => {
      if (role.value === 'admin') {
        mutatePromote({ user: employee as User, userId })
      } else if (role.value === 'employee') {
        mutateRevoke({ user: employee as User, userId })
      }
      setRole(role)
      setRoleError('')
    },
    [mutatePromote, mutateRevoke, setRole, setRoleError, employee, userId],
  )

  const onUpdateStatus = useCallback(() => {
    setStatusError('')
    if (employee?.active) {
      terminateEmployee({ user: employee as User, userId })
    } else {
      activateEmployee({ user: employee as User, userId })
    }
  }, [setStatusError, terminateEmployee, activateEmployee, employee, userId])

  const handleDismiss = useCallback(
    () => [setError(''), showNewServiceRequest()],
    [setError],
  )

  const areSomePopupOpen =
    isNewServiceRequestPopupVisible ||
    isAssignNewSurveyPopupVisible ||
    isSuccessModalVisible ||
    isEmployeeEditing ||
    error

  return (
    <>
      {areSomePopupOpen && <IonBackdrop className={styles.backdrop} />}
      <Page name='Employee Profile'>
        <div className={styles.page}>
          <UserInfo
            userType={UserType.EMPLOYEE}
            user={employee ? employee : null}
            role={role as Role}
            setRole={setUserRole}
            options={options}
            onEdit={handleEmployeeEditClick}
            onUpdateStatus={onUpdateStatus}
            roleError={roleError}
            statusError={statusError}
          />
          <div className={styles.tablesWrapper}>
            <EmployeeServiceRequests
              serviceRequests={employeeServiceRequests}
              isEmpServiceLoading={isEmpServiceLoading}
              isEmpServiceError={isEmpServiceError}
              onNewServiceRequestClick={showCustomFormServiceRequest}
              onServiceRequestView={handleServiceRequestViewClick}
            />
            {/* <div className={styles.employeeSurveysWrapper}>
              <EmployeeSurveys
                surveys={employeeSurveys}
                onAssignNewSurveyClick={() => {}}
                onSurveyView={handleSurveyView}
              />
            </div> */}
          </div>
        </div>
      </Page>
      <NewServiceRequest />
      <CustomFormServiceRequest />
      <SMSRequest />
      {isSRError && error && (
        <ErrorModal onDismiss={handleDismiss} text={error} />
      )}
      {isSuccessModalVisible && (
        <SuccessModal
          onClose={handleSuccessModalClose}
          text='The request was created, someone from the CBO or Nonprofit should be in
        contact with the patient soon.'
        />
      )}
      {serviceRequestChosen && <ServiceRequestReviewModal />}
      {isEmployeeEditing && <EditUserModal />}
    </>
  )
}

export default EmployeeProfile
